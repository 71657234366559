import './CaregiverBreakdown.css';
import {useSearchParams} from "react-router-dom";
import {CaregiverBreakdownDTO} from "../PatientDetails/PatientDetailsDTO";
import {useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {styled} from "@mui/system";


function CaregiverBreakdown(props: any){
    const [searchParams, setSearchParams] = useSearchParams();
    const [caregiver_breakdown, setCaregiver_breakdown] = useState<CaregiverBreakdownDTO>(new CaregiverBreakdownDTO());

    useEffect(() => {
        props.caregiver_breakdown.billing_patient_services.sort((a: any, b: any) => new Date(a.service_date).getTime() - new Date(b.service_date).getTime());
        props.caregiver_breakdown.payroll_services.sort((a: any, b: any) => new Date(a.service_date).getTime() - new Date(b.service_date).getTime());
        setCaregiver_breakdown(props.caregiver_breakdown);
    }, [props.caregiver_breakdown]);


    const openPatientDetails = async (ixd: any) => {
        setSearchParams({id: ixd});
    }

    const renderNumberCell = (params: any) => {
        const value = parseFloat(params?.value)
        if (value === 0 || Number.isNaN(value))
            return <div></div>

        let roundedNum = Math.round(value * 100) / 100;
        return <div className='right-align-number-cell'>{roundedNum.toFixed(2)}</div>
    }

    const billing_services_columns_definitions = [
        { field: 'service_date', headerName: 'Service Date', disableColumnMenu: true,  width: 180 },
        { field: 'service_code', headerName: 'Service Code', disableColumnMenu: true,  width: 180 },
        { field: 'hours', headerName: 'Hours',  width: 150 , renderCell: renderNumberCell,  headerClassName: 'header-padding', disableColumnMenu: true },
        { field: 'rate', headerName: 'Rate',  width: 150, renderCell: renderNumberCell,  headerClassName: 'header-padding', disableColumnMenu: true },
        { field: 'amount', headerName: 'Amount',  width: 150 , renderCell: renderNumberCell, disableColumnMenu: true },
        { field: 'invoice_date', headerName: 'Invoice Date',  width: 180, disableColumnMenu: true },
        { field: 'invoice_number', headerName: 'Invoice #',  width: 150, disableColumnMenu: true },
        { field: 'contract', headerName: 'Contract',  width: 150, disableColumnMenu: true },
    ];

    const payroll_services_columns_definitions = [
        { field: 'service_date', headerName: 'Service Date', disableColumnMenu: true,  width: 180 },
        { field: 'type', headerName: 'Type', disableColumnMenu: true,  width: 180 },
        { field: 'hours', headerName: 'Hours',  width: 150 , renderCell: renderNumberCell,  headerClassName: 'header-padding', disableColumnMenu: true },
        { field: 'rate', headerName: 'Rate',  width: 150, renderCell: renderNumberCell,  headerClassName: 'header-padding', disableColumnMenu: true },
        { field: 'amount', headerName: 'Amount',  width: 150 , renderCell: renderNumberCell, disableColumnMenu: true },
    ];

    const StyledDataGrid = styled(DataGrid)({
        '& .MuiDataGrid-columnHeaders': {
            display: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
            display: 'none',
        },
    });

    return (
        <div className="caregiver-breakdown">
            <div className='caregiver-breakdown-header'>
                <div className='caregiver-header-txt-1'>{caregiver_breakdown.name}</div>
                <div className='caregiver-header-txt-2'>{caregiver_breakdown.xid}</div>
            </div>
            {
                caregiver_breakdown.additional_patients.length > 0 &&
                <div className="caregiver-additional-patients">
                    <div className='caregiver-additional-patients-header'>
                        Additional Patients
                    </div>
                    <div className='caregiver-additional-patients-tbody'>
                        {
                            caregiver_breakdown.additional_patients.map((additional_patient: any, index: any) => (
                                <span className='caregiver-additional-patient'
                                      onClick={() => openPatientDetails(additional_patient.xid)}>{additional_patient.name}</span>
                            ))
                        }
                    </div>
                </div>
            }
            <div className='caregiver-billing-patient-services'>
                <div className='caregiver-billing-patient-services-header'>
                    Billing
                </div>
                <div className='caregiver-billing-patient-services-table'>
                    <DataGrid rows={caregiver_breakdown.billing_patient_services.slice(0, caregiver_breakdown.billing_patient_services.length - 1)}
                              columns={billing_services_columns_definitions}
                              getRowId={(row) => crypto.randomUUID()}
                              hideFooterSelectedRowCount={true}
                              disableRowSelectionOnClick
                              hideFooter
                    />
                </div>
                <div className="caregiver-billing-patient-services-total-table">
                    <StyledDataGrid rows={[caregiver_breakdown.billing_patient_services[caregiver_breakdown.billing_patient_services.length - 1]]}
                                    columns={billing_services_columns_definitions}
                                    getRowId={(row: any) => crypto.randomUUID()}
                                    disableRowSelectionOnClick
                                    hideFooter
                    />
                </div>
            </div>
            <div className='payroll-services'>
                <div className='payroll-services-header'>
                    Payroll
                </div>
                <div className='payroll-services-table'>
                    <DataGrid rows={caregiver_breakdown.payroll_services.slice(0, caregiver_breakdown.payroll_services.length - 1)}
                              columns={payroll_services_columns_definitions}
                              getRowId={(row) => crypto.randomUUID()}
                              hideFooterSelectedRowCount={true}
                              disableRowSelectionOnClick
                              hideFooter
                    />
                </div>
                <div className="payroll-services-total-table">
                    <StyledDataGrid
                        rows={[caregiver_breakdown.payroll_services[caregiver_breakdown.payroll_services.length - 1]]}
                        columns={payroll_services_columns_definitions}
                        getRowId={(row: any) => crypto.randomUUID()}
                        disableRowSelectionOnClick
                        hideFooter
                    />
                </div>
            </div>
        </div>
    )
}


export default CaregiverBreakdown;
