import CategoryAlerts from "./categoryAlerts";
import './alertSummary.css';
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from "react-redux";
import TotalSolvedAlerts from "./totalSolvedAlerts";
import mixpanel from "mixpanel-browser";
import { CycleMetadata } from "./cycleMetadata";
import { setOpenAuditCompleteDialog } from "../../store/PayslipSlice";
import {CANCEL_CONFIRMATION, ConfirmationDialog} from "../ConfirmationDialog/confirmationDialog";
import { useNavigate } from "react-router-dom";


const AlertSummary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const latestController = useRef(null);

    const category = useSelector((state: any) => state?.reportStateReducer?.filter?.categoryType);
    const openAuditCompleteDialog = useSelector((state: any) => state?.PayslipsViewReducer?.openAuditCompleteDialog);
    
    const isSummaryTAb = category === '' || category === 'SUMMARY';
    
    const entity = useSelector((state: any) => state?.entityReducer?.selectedEntity);
    const cycle = useSelector((state: any) => state?.entityReducer?.selectedCycle);
    
    const [payslips, setPayslips] = useState([]); 
    const [cycleMetadata, setCycleMetadata] = useState<CycleMetadata>();
    const [nonApprovedItems, setNonApprovedItems] = useState(0);
    const [body, setBody] = useState("");
    
    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController?.current) {
        //@ts-ignore
            latestController.current.abort();
        }

        // Create a new controller for the current request
        const controller = new AbortController();
        //@ts-ignore
        latestController.current = controller;

        const getPayslips = async () => {
            try {
                getCycleMetadata();
                const payslipsCall = await axios.get(`/api/payslips/${entity}/${cycle}/list/`, {signal: controller.signal});
                const newPayslips = await payslipsCall?.data;
                setPayslips(newPayslips);
                const items = newPayslips?.filter((x:any) => x?.severity === 1)?.map((x:any) => x?.findings_summery).flat();
                const nonApprovedItemsInCycle = items?.reduce((accumulator: number ,item: any) => accumulator += item?.non_approved_quantity, 0);
                setNonApprovedItems(nonApprovedItemsInCycle);
                setBody(nonApprovedItems === 0 ? `All alerts have been approved, well done! Would you like to complete the review?` 
                :
                `You have ${nonApprovedItems} unapproved alerts. \nDo you want to approve them before completing the review?`); 

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error('Fetch error:', error);
                }
            }    
        };

        getPayslips();

        return () => {
            controller.abort();
        };
    }, [entity, cycle, openAuditCompleteDialog]);

    const getCycleMetadata = async () => {
        const apiCall = await axios.get(`/api/${entity}/${cycle}`);
        const cycleMetadataRes = await apiCall?.data;
        setCycleMetadata(cycleMetadataRes);
    };

    const audit_complete_handler = () => {
        const now = new Date().toISOString();
        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const firstName = userData?.first_name;
        const lastName = userData?.last_name;
        mixpanel.track('Audit Complete btn pushed', {'cycle': cycle, 'entity': entity, 'category': category, "event_date": now, "user": `${firstName} ${lastName}`});
        axios.post(`/api/analytics/${entity}/${cycle}`, {
            "user": `${firstName} ${lastName}`,
            "entity": entity,
            "cycle": cycle,
            "event_date": now,
            "event_name": "Audit Complete btn pushed",
            "event_data": {"hasOpenAlert": nonApprovedItems, "email": userData?.email}
        }).catch((err) => {
            console.log(err);
        });
        
        dispatch(setOpenAuditCompleteDialog(true));
    }

    const closeAuditCompleteConfirmDialog = (value: string) => {
        dispatch(setOpenAuditCompleteDialog(false));

        if (value === CANCEL_CONFIRMATION){
            
            navigate(`/${entity}/${cycle}/report`);
            mixpanel.track('Back to report')
            return;
        }

        if (typeof value !== 'string' || value === 'backdropClick')
            return;

        mixpanel.track('Complete Review and Notify');
        audit_complete_call();
    };

    const audit_complete_call = async () => {
        await axios.post(`/api/${entity}/${cycle}/audit_complete`)
        navigate(`/${entity}/${cycle}/overview`);
    }        

    return (
        !payslips ? 
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop> 
        :
        isSummaryTAb && (
        <div className="alerts-panel">
            <div className="alerts-panel-header">
                <div className="alerts-panel-header-title">Review Progress</div>
                <div className="alerts-panel-header-actions">
                    { cycleMetadata?.audit_complete !== true ?
                     <div data-track='Complete review' className="audit-complete-btn" onClick={audit_complete_handler}>Complete Review</div> :
                     <div className="notification-sent-btn">Review Completed</div>}
                </div>
            </div>
            <div className="cards-panel">
                <TotalSolvedAlerts payslips={payslips} />
                <CategoryAlerts payslips={payslips} text={'Fraud Suspected'} type={'Fraud'}/>
                <CategoryAlerts payslips={payslips} text={'Policy Violation'} type={'Policy'}/>
                <CategoryAlerts payslips={payslips} text={'Anomaly Identified'} type={'Anomaly'}/>
                <CategoryAlerts payslips={payslips} text={'Employees Turnover'} type={'Turnover'}/>
            </div>
            <ConfirmationDialog
                onClose={closeAuditCompleteConfirmDialog}
                content={body}
                open={openAuditCompleteDialog}
                titleTxt="Complete Review"
                confirmTextBtn="Complete Review and Notify"
                cancelTextBtn="Back to Report"
                onBackdropClick={closeAuditCompleteConfirmDialog}
                showCheckmark={nonApprovedItems === 0 ? true : false}
                highlightApprove={nonApprovedItems === 0 ? true : false}
                iconColor="#EFBA52"
            />
        </div>
      )
    );
}

export default AlertSummary;