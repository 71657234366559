import './GrossChangeGraph.css';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatDate, formatNumber} from "../../../utils/PresentationUtils";
import ExportBtn from "../../ExportBtn/ExportBtn";

const GrossChangeGraph = (props) => (
    <div className="gross-change-graph">
        <div className='dashboard-component-header'>
            <div id="gross-change">Gross Change</div>
            <ExportBtn data={props?.data} filename={"CeleryGrossChangeExport.csv"}></ExportBtn>
        </div>
        <ResponsiveContainer height={props?.height ? props?.height : 424} className='gross-change-graph-container'>
            <LineChart data={props?.data}>
                <XAxis dataKey="cycle" tickFormatter={(value) => {return formatDate(value)}} dy={10}/>
                <YAxis yAxisId={1} width={100} 
                        tickFormatter={(value) => {return formatNumber(value)}} />
                <YAxis yAxisId={2} width={100} orientation="right"
                        tickFormatter={(value) => {return formatNumber(value)}}/>
                <Tooltip formatter={(value, name, props) => {return formatNumber(value)}}/>
                <Legend />
                <Line yAxisId={1} dataKey="gross" stroke="#02BBE3" strokeWidth={3} />
                <Line yAxisId={2} dataKey="employees" stroke="#B6FF7D" strokeWidth={3} />
                <CartesianGrid strokeDasharray="3 3" />
            </LineChart>
        </ResponsiveContainer>
    </div>
);

export default GrossChangeGraph;