import { setSelectedCycle, setSelectedEntity, setSelectedEntityName } from './../store/entitySlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export default function useUpdateUrl() {
    const navigate = useNavigate();
    const entities = useSelector((state: any) => state?.entityReducer?.entities);
    const selectedEntity = useSelector((state: any) => state?.entityReducer?.selectedEntity);
    const selectedCycle = useSelector((state: any) => state?.entityReducer?.selectedCycle);
    const selectedEntityObj = useSelector((state: any) => state?.entityReducer?.selectedEntityObj);
    
    const location = useLocation();
    const urlArrayForChecking = location?.pathname?.split('/');
    if (urlArrayForChecking[0] === "")  urlArrayForChecking?.shift();
    const entity = urlArrayForChecking[0];
    const cycle = urlArrayForChecking[1];
    
    const dispatch = useDispatch();

    const innerPageLink = location?.hash ? location?.hash : '';
    const querySearchParams = location?.search ? location?.search : '';

    const urlArray = location?.pathname?.split('/');
    if (urlArray[0] === "")  urlArray?.shift();
    let url = '';

    useEffect(() => {
        if(entity === ""){
            if(entities?.length > 0){
                urlArray[0] = entities[0]?.id;
                urlArray[1] = entities[0]?.last_cycle ? entities[0]?.last_cycle : entities[0]?.last_ppo_cycle;
                url = urlArray.join('/');
                dispatch(setSelectedEntity(entities[0]?.id));
                dispatch(setSelectedCycle(entities[0]?.last_cycle ? entities[0]?.last_cycle : entities[0]?.last_ppo_cycle));
                if(entities?.length > 0){
                    const entityName = entities[0]?.name;
                    dispatch(setSelectedEntityName(entityName));
                }
                navigate(`/${url}${innerPageLink}${querySearchParams}`);            
            }
        }else{
            dispatch(setSelectedEntity(entity));
            dispatch(setSelectedCycle(cycle));
            if(entities?.length > 0){
                let curr_entity = entities.find((x: any) => x?.id?.toString() === entity?.toString());
                let entityName = curr_entity?.name;
                if (entityName == null)
                {
                    curr_entity = entities[0];
                    entityName = curr_entity.name;
                    dispatch(setSelectedEntity(curr_entity.id));
                    dispatch(setSelectedCycle(curr_entity.cycles[0]));
                }
                else if(curr_entity.cycles.indexOf(cycle) === -1)
                {
                    dispatch(setSelectedCycle(curr_entity.cycles[0]));
                }
                dispatch(setSelectedEntityName(entityName));
            }
        }
    }, [entities]);

    useEffect(() => {
        urlArray[0] = selectedEntity;
        urlArray[1] = selectedCycle ? selectedCycle : selectedEntityObj?.last_ppo_cycle;
        url = urlArray.join('/');
        if(selectedEntity !== null && urlArray[1]){
            if((selectedEntity !== entity || urlArray[1] !== cycle)){
                navigate(`/${url}${innerPageLink}${querySearchParams}`);      
            } 
        }
    }, [selectedEntity, selectedCycle]);
}