import {useEffect, useState} from 'react';
import './Payslips.css';
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import CommentsSection from "../CommentsSection/CommentsSection";
import ActivityLog from "../ActivityLog/ActivityLog";
import CommentDialog from "../CommentDialog/CommentDialog";
import PayslipSheet from "../PayslipSheet/PayslipSheet";
import mixpanel from "mixpanel-browser";
import { useDispatch, useSelector } from 'react-redux';
import { setPayslipsIndex, setPayslipsSeen} from "../../store/PayslipSlice";
import PayslipSummary from "./PayslipSummary/PayslipSummary";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LinearProgress from '@mui/material/LinearProgress';
import AlertSummary from "../AlertsSummary/alertsSummary";
import useCustomParams from '../../hooks/useCustomParams';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from "@uidotdev/usehooks";
import CommentEnforcer from "../CommentEnforcer/CommentEnforcer";
import { setCommentEnforcerState, setEnforcerEnabledState } from "../../store/CommentEnforcerSlice";
import EmployeeNotes from "../EmployeeNotes/EmployeeNotes";


function Payslips() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSplit, setSplit] = useState(false);
    const [payslipData, setPayslipData] = useState(null);
    const [highlightedID, setHighlightedID] = useState("-1");
    const [clickedID, setClickedID] = useState("-1");
    const [findingsData, setFindingsData] = useState(null);
    const [findingsRawData, setFindingsRawData] = useState(null);
    const [warningsRawData, setWarningsRawData] = useState(null);
    const [lastFindingsRawData, setLastFindingsRawData] = useState(null);
    const [fieldsData, setFieldsData] = useState(null);
    const [commentsData, setCommentsData] = useState(null);
    const [activityLogData, setActivityLogData] = useState(null);
    const [commentDialogData, setCommentDialogData] = useState({show: false, finding_id: -1, approved: false});
    const [nextGrayOut, setNextGrayOut] = useState(false);
    const [prevGrayOut, setPrevGrayOut] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    
    const [payslipTab, setPayslipTab] = useState(0);
    const { entity, cycle } = useCustomParams();
    const previousEntity = usePrevious(entity);
    const previousCycle = usePrevious(cycle);
    
    const isComplianceRequired = useSelector((state) => state?.CommentEnforcerReducer?.enforcerEnabled);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const viewTable = useSelector((state) => state?.PayslipsViewReducer?.payslips);
    const payslipsSeen = useSelector((state) => state?.PayslipsViewReducer?.payslipsSeen);
    const payslipsIndex = useSelector((state) => state?.PayslipsViewReducer?.payslipsIndex);
    const selectedEntityObj = useSelector((state) => state?.entityReducer?.selectedEntityObj);
    const userRole = localStorage.getItem('user_role');
    const enableApproveBtn = !(selectedEntityObj?.disable_hr_approve_permission && userRole === 'HR');

    const CommentEnforcerDispatcher = (config) => dispatch(setCommentEnforcerState(config));

    const handleStep = (step) => () => {        
        const direction = payslipsIndex > step ? 'Prev' : 'Next'
        mixpanel.track(direction);
        dispatch(setPayslipsIndex(step));
        dispatch(setPayslipsSeen(payslipsSeen + 1));
        setSearchParams({id: viewTable[step]?.id});
    };

    function openDialog(finding_id, approved) {
        setCommentDialogData({show: true, finding_id: finding_id, approved: approved})
    }

    function refresh() {
        getPayslipData(payslipId, setPayslipData, setFindingsData, setCommentsData,
            setFieldsData, setFindingsRawData, setLastFindingsRawData, setWarningsRawData, setActivityLogData);
    }

    function closeDialog(changed) {
        setCommentDialogData({show: false, finding_id: -1, approved: false})
        if (changed){
            refresh();
        }
    }

    let payslipId = searchParams.get('id')
    payslipId = payslipId ? payslipId : '-1'

    useEffect(() => {
        getPayslipData(payslipId, setPayslipData, setFindingsData, setCommentsData,
            setFieldsData, setFindingsRawData, setLastFindingsRawData, setWarningsRawData, setActivityLogData);
    }, [payslipId]);

    useEffect(() => {
        for (let index = 0; index < viewTable.length; ++index) {
            const localPayslip = viewTable[index];
            const localPayslipId = localPayslip?.id?.toString();
            if (localPayslipId && localPayslipId === payslipId) {
                dispatch(setPayslipsIndex(+index));
                checkNextPrevGrayOut(index);
                break
            }
        }

        const fetchConfigData = async () => {
            try {
                const res = await axios.get(`/api/system/config/${entity}/dynamic_config/`);
                if (res.data) {
                    for (let conf of res.data) {
                        if (conf?.key === 'RequireCommentOnApprove') {
                            if(conf?.value === 'true' || conf?.value === true) dispatch(setEnforcerEnabledState(true));
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };

        fetchConfigData();
    }, []);

    useEffect(() => {
        if (previousEntity !== null && previousEntity !== entity){
            navigate(`/${entity}/${cycle}/report`);
        } 
    }, [previousEntity]);

    useEffect(() => {
        if (previousCycle !== null && previousCycle !== cycle){
            navigate(`/${entity}/${cycle}/report`);
        } 
    }, [previousCycle]);

    const checkNextPrevGrayOut = (index) => {
        if ( index === 0 ){
            setPrevGrayOut(true)
        }
        else if ( index+1 >= viewTable.length ){
            setNextGrayOut(true)
        }
        else {
            if ( nextGrayOut === true){
                setNextGrayOut(false)
            }
            if ( prevGrayOut === true){
                setPrevGrayOut(false)
            }
        }
    }

    function toggleSplit() {
        mixpanel.track('open / close Payslip compare', {'payslip_id': payslipId})
        setSplit(!isSplit);
    }

    const btnShowLastPayslip = <div data-track='Compare' className="transition-btn" style={{visibility:`${isSplit ? "hidden" : "visible"}`}} onClick={toggleSplit} title="Show last payslip">
                    <img src='/simcard-2.svg' alt='Show last payslip'/>Compare
                </div>;

    const btnHideLastPayslip = <div className="transition-btn" onClick={toggleSplit} title="Hide last payslip">
                    <img src='/close-prev-payslip.svg' alt='Hide last payslip'/>Close
                </div>;

    const tabsDisabled = isSplit ? true : false;
    const subPayslips = payslipData?.sub_data;

    const concatenateFindings = (items) => items && items?.length > 0 ? [...items] : [];
    const  concatenatedFindings = [...concatenateFindings(findingsRawData), ...concatenateFindings(warningsRawData)];

    if (payslipId === '-1') {
        return (<div>Please select a payslip</div>)
    } else if (payslipData === null || findingsData === null || commentsData === null || activityLogData === null || isLoading === true) {
        return (        
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    } else {
        return (
            <div className="payslips-page">
                <CommentDialog payslip_id={payslipId} finding_id={commentDialogData.finding_id}
                               key={`dlg_${commentDialogData.finding_id}`} open={commentDialogData.show}
                               closeDialog={closeDialog} approved={commentDialogData.approved > 0 && enableApproveBtn}>
                </CommentDialog>
                <div className='payslip-header'>
                    <div className='payslip-header-data-section'>
                        <LinearProgress sx={{
                                            height: 7,
                                            borderRadius: 8,
                                            '&.MuiLinearProgress-colorPrimary': {
                                                backgroundColor: '#DEEAEC',
                                            },
                                            '& .MuiLinearProgress-barColorPrimary': {
                                                backgroundColor: '#02BBE3',
                                            },
                                        }} 
                                        variant="determinate" 
                                        value={viewTable?.length > 0 ? (payslipsIndex + 1) / (viewTable?.length / 100) : 0}
                        />
                        <div className='payslip-header-number-tracker'>{payslipsIndex + 1} / {viewTable?.length}</div>
                        <div className='payslip-navigation-header'>
                            <button className='prev-next-btn' onClick={handleStep(payslipsIndex - 1)} disabled={payslipsIndex === 0}> <span className='blue-arrow-prev-next'>&lt;</span> Prev </button>
                            <div className='payslip-header-txt-container'>
                                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                                    <div className='payslip-header-txt-1'>{payslipData?.name}</div>
                                    <EmployeeNotes employeeXid={payslipData?.employee_xid}/>
                                </div>
                                <div className='payslip-header-txt-2'>Department {payslipData?.branch}</div>
                            </div>
                            {
                                enableApproveBtn && payslipsIndex === viewTable?.length - 1 ?
                                <div style={{width:"160px"}}>
                                    <AlertSummary />
                                </div>
                                :
                                <button className='prev-next-btn' onClick={handleStep(payslipsIndex + 1)} disabled={viewTable?.length === 0 || payslipsIndex === viewTable?.length - 1} > Next <span className='blue-arrow-prev-next'>&gt;</span></button>
                            }
                        </div>
                    </div>
                </div>
                <div className="data-area">
                    {isSplit === false ?
                        <div className="payslip-metadata">
                            <div className="payslip-summary">
                                <PayslipSummary setClicked={setClickedID} fieldsData={fieldsData}
                                                currentFindings={concatenatedFindings} setHighlight={setHighlightedID}
                                                highlightID={highlightedID} title='Alerts Summary' payslip_department={payslipData?.branch}
                                                btn={
                                                    <div style={{width:"100%", backgroundColor: "white", display: "flex", "alignItems":"center"}}>
                                                        <div></div>
                                                        {enableApproveBtn &&         
                                                            <div className={`payslips-approve-all-btn ${approveAllDisabled(concatenatedFindings) ? "" : "disabled-payslip-element"}`} onClick={e => approveAll(e, payslipId, refresh, viewTable, payslipsIndex, handleStep(payslipsIndex + 1), setIsLoading, isComplianceRequired, CommentEnforcerDispatcher)}>
                                                                <img className='approve-all-icon' src={`${approveAllDisabled(concatenatedFindings) ? '/tick-circle2.svg' : '/tick-circle2-grey.svg'}`} alt='approve'/>
                                                                Approve All
                                                            </div>
                                                        }
                                                    </div>
                                                }/>
                            </div>
                            <div className="payslip-comments">
                                <CommentsSection payslipId={payslipId} data={commentsData} refresh={refresh}/>
                            </div>
                            <div className="payslip-ActivityLog">
                                <ActivityLog data={activityLogData}/>
                            </div>
                        </div>
                        :
                        <div className='open-summary-btn' onClick={toggleSplit}>
                            <img alt='open summary' src='/open-summary.svg' style={{height: "30px", color: "red"}}/>
                        </div>
                    }
                    <div className="payslip">
                        <div className="doc-wrapper">
                            <div className='payslip-tab-container'>
                                <Tabs value={payslipTab} onChange={(event, newValue) => {
                                    mixpanel.track('multiple Payslips tab changed', {'new tab number': newValue});
                                    setPayslipTab(newValue);
                                    }} className='payslips-tab'>
                                <Tab label={`${subPayslips?.length > 0 ? "Consolidated Payslip" : "Payslip"}`} id={0}/>
                                    {subPayslips && subPayslips?.length > 0 ? subPayslips?.map((subPayslip, index) => {
                                        return <Tab label={`Payslip ${index + 1}`} id={index+1} key={index} disabled={tabsDisabled} style={{backgroundColor: `${tabsDisabled ? "#DEEAEC": ""}`}}/>
                                    }) : <></>}
                                </Tabs>
                                <div style={{height:"48px", backgroundColor: "#F3F5F8", flexGrow:"1"}}></div>
                            </div>
                                {payslipTab === 0 && <PayslipSheet name={payslipData?.name} employee_info={payslipData?.employee_info} refresh={refresh} showApproveBtn={!isSplit ? enableApproveBtn : false} setClicked={setClickedID} clickedId={clickedID} compareState={isSplit} btn={btnShowLastPayslip} title={"Payslip Information"} data={payslipData?.data} findings={findingsRawData} warnings={warningsRawData} fields={fieldsData} setHighlight={setHighlightedID} highlightID={highlightedID} openDialog={openDialog}></PayslipSheet>}
                                {subPayslips && subPayslips?.length > 0 ? subPayslips?.map((subPayslip, index) => {
                                    return index + 1 === payslipTab && <PayslipSheet name={payslipData?.name} employee_info={payslipData?.employee_info} refresh={refresh} showApproveBtn={false} setClicked={setClickedID} clickedId={clickedID} compareState={isSplit} title={"Payslip Information"} data={subPayslip} findings={null} fields={fieldsData} setHighlight={setHighlightedID} highlightID={highlightedID} openDialog={openDialog}></PayslipSheet>
                                }) : <></>}    
                        </div>   
                    </div>
                    {isSplit &&
                        <div className="payslip">
                            <div style={{height:"48px"}}></div>
                            <div className="doc-wrapper">
                                <PayslipSheet refresh={refresh} employee_info={payslipData?.employee_info} showApproveBtn={false} setClicked={setClickedID} clickedId={clickedID} compareState={isSplit} btn={btnHideLastPayslip} title={"Last Cycle"} data={payslipData?.last_data} findings={lastFindingsRawData} warnings={warningsRawData} fields={fieldsData} setHighlight={setHighlightedID} highlightID={highlightedID} openDialog={openDialog}></PayslipSheet>
                            </div>
                        </div>    
                    }
                </div>
                <CommentEnforcer onClose={refresh} />
            </div>)
    }

    function getPayslipData(payslipId, setPayslipData, setFindingsData, setCommentsData, 
                            setFieldsData, setFindingsRawData, setLastFindingsRawData, setWarningsRawData, setActivityLogData) {
        axios.get(`/api/payslips/${payslipId}/`).then((payslip_res) => {
            setPayslipData(payslip_res.data)
            const last_cycle_payslip_id = payslip_res?.data?.last_payslip_id
            axios.get(`/api/payslips/${last_cycle_payslip_id}/findings`).then((last_finding_res) => {
                setLastFindingsRawData(last_finding_res.data);
            }).catch((err) => {
                setLastFindingsRawData([]);
            })
            getFindingData(payslipId, payslip_res.data, setFindingsData, setFieldsData, setFindingsRawData);
            getFindingData(payslipId, payslip_res.data, null, setFieldsData, setWarningsRawData, true);

        }).catch((err) => {
            console.log(err);
        })

        axios.get(`/api/payslips/${payslipId}/comments`).then((res) => {
            setCommentsData(res?.data ? res.data : []);
            setActivityLogData(res?.data ? res.data : []);
        }).catch((err) => {
            console.log(err);
        })
    }

    function getFindingData(payslipId, payslipDataRes, setFindingsDataFunc,
                            setFieldsData, setFindingsRawDataFunc, ignoreAlert = false) {
        const queryString = ignoreAlert ? 'ignore_high=true' : 'only_high=true';
        axios.get(`/api/payslips/${payslipId}/findings?${queryString}`).then((finding_res) => {
            setFindingsRawDataFunc(finding_res.data);
            axios.get(`/api/payslips/${entity}/fields`).then((fields_res) => {
                setFieldsData(fields_res.data)
                if (setFindingsDataFunc != null)
                {
                    setFindingsDataFunc(processFindings(finding_res.data, fields_res.data, payslipDataRes))
                }
            }).catch((err) => {
                console.log(err);
            });
        }).catch((err) => {
            console.log(err);
        })
    }
};

function processFindings(findings_data, fields_data, payslip_data) {
    const fields_dict = {}
    const raw_fields_dict = {}

    for (let field_data of fields_data){
        fields_dict[field_data['field_system_id']] = field_data
        raw_fields_dict[field_data['field_source_id']] = field_data
    }

    const base_findings = {}

    let filtered_keys = Object.keys(payslip_data.data).filter(x => {return x in raw_fields_dict && payslip_data.data[x]})
    filtered_keys = filtered_keys.concat(findings_data.map(x => fields_dict[x?.field_name]?.field_source_id))
    filtered_keys.sort((a, b) => {return raw_fields_dict[a].field_display_order - raw_fields_dict[b].field_display_order});

    for (let payslip_field of filtered_keys) {
        if (payslip_field in raw_fields_dict){
            let field_data = raw_fields_dict[payslip_field]
            if (!(field_data.field_section in base_findings)){
                base_findings[field_data.field_section] = {}
            }

            if (!(field_data.field_display_name in base_findings[field_data.field_section])){
                base_findings[field_data.field_section][field_data.field_display_name] = {}
            }
        }
    }

    for (let finding of findings_data) {
        let field_data = raw_fields_dict[finding['field_name']]
        if (!field_data) {
            field_data = fields_dict[finding['field_name']]
        }

        if (!(field_data.field_display_name in base_findings[field_data.field_section])){
            base_findings[field_data.field_section][field_data.field_display_name] = {}
        }
        let field_type = field_data.field_type
        if (field_type === 'Data' || field_type === 'SectionTotal') {
            field_type = 'Total'
        }

        base_findings[field_data.field_section][field_data.field_display_name][field_type] = finding
    }

    return base_findings
}

function approveAll(event, payslip_id, refresh_func, viewTable, payslipsIndex, handleStep, setIsLoading, isComplianceRequired, dispatcher) {
    event?.stopPropagation();
    if (isComplianceRequired){
        dispatcher({open: true, payslipID: payslip_id});
    } else{
        setIsLoading(true);
        setTimeout(() => { setIsLoading(false); } , 1000);
        mixpanel.track('Approve All', {'payslip_id': payslip_id});
        axios.post(`/api/payslips/${payslip_id}/approve`).then((res) => {
            const toMoveNext = (viewTable?.length === 0 || payslipsIndex === viewTable?.length - 1) === false;
            if(toMoveNext){
                handleStep();
            }
            else{
                refresh_func()
            }
        })
        .catch((err) => { 
            console.log(err);
            setIsLoading(false);
        });
    }
}

const approveAllDisabled = (findingsRawData) => {
    if(!findingsRawData) return; 
    const relevantFindings = findingsRawData?.filter(finding => finding?.approved === 0 && finding?.severity < 3);
    const isEnabled = relevantFindings?.length > 0;
    
    return isEnabled;
}

export default Payslips;