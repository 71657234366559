import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { GrDocumentTime } from "react-icons/gr";
import axios from 'axios';
import useCustomParams from '../../../hooks/useCustomParams';
import { useRef } from 'react';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: "10px",
});

export default function TimeAndAttendanceUpload(props: {oldCyclesLength: number, callback: any}) {
    const { entity } = useCustomParams();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
  
    const postTimeCards = (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const formData = new FormData();

        if (event?.target?.files) {
          for (let i = 0; i < event.target.files.length; i++) {
            formData.append("files", event.target.files[i]);
          }
        }
        
        axios.post(`/api/time_and_attendance/${entity}/upload/`, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        });

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        pollFileStatus(props?.oldCyclesLength, props?.callback);

      } catch (error) {
          console.error('error uploading to time_and_attendance: ', error);
      }    
  };

  const pollFileStatus = (oldCyclesLength: number, callback: (data: Array<string>) => void) => {
    const pollingInterval = 3000;
    const timeoutDuration = 60000;

    if(!oldCyclesLength) oldCyclesLength = 0;

    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`/api/time_and_attendance/${entity}/cycles/`);
        const result = response?.data?.length;

        if (result > oldCyclesLength) {
          clearInterval(intervalId);
          callback(response?.data);
          alert('File uploaded successfully');
        }
      } catch (error) {
        clearInterval(intervalId);
        console.error('error polling for file status: ', error);
      }
    }, pollingInterval);

    setTimeout(() => { clearInterval(intervalId); }, timeoutDuration);
  };

  return (
    <Button
      className='TimeAndAttendanceUploadButton'
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<GrDocumentTime />}
      sx={{ textTransform: 'none' }}
    >
      Upload Files T&A
      <VisuallyHiddenInput
        ref={fileInputRef}
        type="file"
        onChange={(event) => postTimeCards(event)}
        multiple
      />
    </Button>
  );
}