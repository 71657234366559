import {useEffect, useState} from 'react';
import './Home.css';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import GrossChangeGraph from "../DashboardComponents/GrossChangeGraph/GrossChangeGraph";
import useCustomParams from '../../hooks/useCustomParams';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CycleStatus from './HomeInterfaces';
import ValueSummaryItem from './ValueSummaryItem';
import NewAlertSummary from '../ManagerApprovalHomepage/NewAlertsSummary/newAlertsSummary';
import { formatNumber } from '../../utils/PresentationUtils';

function Home() {
    const [cycleStatus, setCycleStatus] = useState<null | CycleStatus>(null);
    const [grossData, setGrossData] = useState([]);
    const { entity, cycle } = useCustomParams();
    const navigate = useNavigate();

    function getCycleStatus(setData: any) {
        axios.get(`/api/reports/${entity}/${cycle}/cycle_status/`).then((res) => {
            if (res.data){
                setData(res.data)
            } else {
                setData({})
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    function getGrossData(setGrossData: any) {
        axios.get(`/api/reports/${entity}/${cycle}/historic_data/`).then((res) => {
            if (res.data){
                setGrossData(res.data['gross_over_time'])
            } else {
                setGrossData([])
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getCycleStatus(setCycleStatus);
        getGrossData(setGrossData);
    }, [entity, cycle])

    return (
            cycleStatus === null ?            
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop> 
            : 
            <div className="home">
                <div className="home-header">
                    <img src='/home.svg' alt='home'/>
                    <span className='home-header-txt'>Overview</span>
                </div>
                <div className="home-row">
                    <div className='sub-header-container'>
                        <div className='home-sub-header'>Total Payroll: <span className='home-sub-header-value'>${formatNumber(cycleStatus?.total_gross)}</span></div>
                        <div className='home-sub-header'>Employees: <span className='home-sub-header-value'>{formatNumber(cycleStatus?.total_employees)}</span></div>
                    </div>
                    <div className='sub-header-btn' onClick={(e) => { navigate(`/${entity}/${cycle}/report`) }}>Open Report</div>
                </div>
                <div className="home-container">
                    <div className="home-row value-container">
                        <ValueSummaryItem numberOfEmployees={cycleStatus?.employees_high} totalChange={cycleStatus?.gross_high / (cycleStatus?.total_gross / 100)} severity={1} sum={cycleStatus?.gross_high} />
                        <ValueSummaryItem numberOfEmployees={cycleStatus?.employees_medium} totalChange={cycleStatus?.gross_medium / (cycleStatus?.total_gross / 100)} severity={2} sum={cycleStatus?.gross_medium} />
                        <ValueSummaryItem numberOfEmployees={cycleStatus?.employees_low} totalChange={cycleStatus?.gross_low / (cycleStatus?.total_gross / 100)} severity={3} sum={cycleStatus?.gross_low} />
                    </div>
                    <div className="home-progress">
                        <div className='dashboard-component-header'>Review Progress</div>
                        { 
                            //@ts-ignore 
                            <NewAlertSummary />
                        }                      
                    </div>
                    <div className="home-graph" onClick={(e) => {
                        navigate(`/${entity}/${cycle}/dashboard`)
                    }}>
                        <GrossChangeGraph data={grossData} height={window?.innerWidth && window?.innerWidth < 1400 ?  200 : 308}/>
                    </div>
                </div>
            </div>
    );
}

export default Home;