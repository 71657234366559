import './Checkbox.css';

const Checkbox = ({ defaultChecked, onChange, title }) => {
    return (
        <label className="custom-checkbox-label" title={title}>
            <input 
                type="checkbox" 
                className="custom-checkbox-input"
                defaultChecked={defaultChecked}
                onChange={onChange}
            />
            <span className="custom-checkbox-box"></span>
        </label>
    );
};

export default Checkbox;