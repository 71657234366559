import './HomePOCCards.css';
import {GoAlertFill, GoClockFill} from "react-icons/go";
import {AiFillDollarCircle} from "react-icons/ai";
import { Bar, BarChart, LabelList, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {formatNumber} from "../../utils/PresentationUtils";
import axios from "axios";
import {useEffect, useState} from "react";
import { PocModel } from './PocObjects';
import DeepLink from '../DeepLink/DeepLink';
import { BaseLink } from '../DeepLink/ILink';
import InsightCard from '../InsightCard/InsightCard';
import useCustomParams from '../../hooks/useCustomParams';

function HomePOCCards(){
    const { entity, cycle } = useCustomParams();
    const [configData, setConfigData] = useState<PocModel>({} as PocModel);
    const [avrRate, setAvrRate] = useState<number>(0);
    const [avrHourlyBillingRate, setAvrHourlyBillingRate] = useState<number>(0);

    useEffect(() => {
        getConfigData(setConfigData);
    }, [entity])

    function buildInsightItem(insightItem: any){
        const linkConfig = insightItem?.link ? insightItem?.link : {} as BaseLink;

        return (
            <div className="HomePOCcardInsightItem" key={'ii_' + insightItem?.topBlack}>
                <div className="HomePOCcardInsightItemIcon">{getIcon(insightItem?.icon)}</div>
                <div className="HomePOCcardInsightItemText">
                    <div className="HomePOCcardInsightItemTextTop">
                        <span style={{color: "#EF525B"}}>{insightItem?.topRed}</span>
                        {insightItem?.topBlack}
                        { insightItem?.topAsterisk && <sup style={{fontSize: 12}}>*</sup>}
                    </div>
                    <div className="HomePOCcardInsightItemTextBottom">
                        {insightItem?.bot}
                    </div>
                    {
                        insightItem?.botSmall &&
                        <div className="HomePOCcardInsightItemTextBottomSmall">
                            {insightItem?.botSmall}
                        </div>
                    }
                </div>
                <DeepLink {...linkConfig}/>
            </div>
        )
    }

    return (
          <div>
              <div className="home-header">
                  <img src='/home.svg' alt='home'/>
                  <span className='home-header-txt'>Overview</span>
              </div>
              <div className='CardComponents'>
                <div className='CardComponentsInput'>
                    <span className='cardCurrencyinput' style={{paddingLeft: `${configData?.contextInsights?.showDollarSign ? "8px" : "2px"}`}}>{configData?.contextInsights?.showDollarSign ? "$" : ""}<input className="cardPocInput" type="number" name="currency" value={avrHourlyBillingRate} onChange={e => setAvrHourlyBillingRate(parseFloat(e.target.value))}/></span>
                    <span className='passionInputText'>{configData ? configData?.contextInsights?.dynamicValueTitle : ""}</span>
                </div>
                <div className='passionCardArea'>
                    <InsightCard cardItem={{...configData?.contextInsights?.cards?.card1, benchmark: configData?.benchmark ? 29 : undefined, benchmarkReverseFormula: false}} configData={configData} avrHourlyBillingRate={avrHourlyBillingRate}/>
                    <InsightCard cardItem={{...configData?.contextInsights?.cards?.card2, benchmark: configData?.benchmark ? 9.7 : undefined, benchmarkReverseFormula: false}} configData={configData} avrHourlyBillingRate={avrHourlyBillingRate}/>
                    <InsightCard cardItem={{...configData?.contextInsights?.cards?.card3}} configData={configData} avrHourlyBillingRate={avrHourlyBillingRate}/>
                    <InsightCard cardItem={{...configData?.contextInsights?.cards?.card4, benchmark: configData?.benchmark ? 18.1 : undefined, benchmarkReverseFormula: true}} configData={configData} avrHourlyBillingRate={avrHourlyBillingRate}/>
                </div>
                <div className='CardComponentsComment'>
                    <span className='CardComponentsCommentRow'><img src='/info-circle.svg' alt='information'/><span>Information reflects the last cycle. <span className='CardLightCommentText'>Daily updates require integration during onboarding</span></span></span>
                </div>
              </div>
              <div className="HomePOCcardWrapper">
                  <div className="HomePOCcardInsightArea">
                      <div className="HomePOCcardAreaHeader">
                          <div className="HomePOCcardInsightAreaHeaderText">
                              Payroll Insights
                              { configData?.alert && <sup className="HomePOCcardInsightAreaHeaderIcon"><GoAlertFill /> Insufficient Data </sup> }
                          </div>
                      </div>
                      <div>
                          {
                              configData?.Insight?.map((insightItem: any) => {
                                  return buildInsightItem(insightItem);
                              })
                          }
                      </div>
                  </div>
                  <div className="HomePOCcardChart">
                      <div className="HomePOCcardAreaHeader">
                          <div className="HomePOCcardInsightAreaHeaderText">
                              Payroll Overview
                          </div>
                      </div>
                      <div className="HomePOCcardChartLineWrapper">
                          <div className="HomePOCcardChartLineLabel"><p style={{marginLeft: '10px', marginBottom:'2px', marginTop:'10px'}}>{configData?.chartConfig?.avgRateName}</p></div>
                          <ResponsiveContainer height={200} className='HomePOCcardChartLineGraph'>
                              <LineChart data={configData?.chartData} margin={{left:30, right:30, top:10, bottom:30}}>
                                  <XAxis dataKey="date" hide={true}/>
                                  <YAxis yAxisId={1} width={150}
                                         tickFormatter={(value) => {return formatMoney(value, false)}} hide={true} domain={getGraphDomain(configData?.chartData, 'avg_rate', 1.1, 0.90)}/>
                                  <Tooltip formatter={(value:any, name, props) => {return formatMoney(value)}}/>
                                  <Line yAxisId={1} dataKey="avg_rate" stroke="#02BBE3" strokeWidth={3} name={configData?.chartConfig?.avgRateName}>
                                      <LabelList dataKey="avg_rate" fontSize={16} position={'top'} offset={10} fill={'black'} formatter={(value:any) => {return formatMoney(value, false)}}></LabelList>
                                  </Line>
                              </LineChart>
                          </ResponsiveContainer>
                      </div>

                      <div style={{marginLeft:'100px', width:'90%'}}>
                          <ResponsiveContainer height={400} className='gross-change-graph-container'>
                              <BarChart data={configData?.chartData}>
                                  <XAxis dataKey="date"/>
                                  <YAxis yAxisId={1} width={100}
                                         tickFormatter={(value) => {return formatMoney(value, false)}} hide={true}
                                         domain={getGraphDomain(configData?.chartData, 'payroll', 1.2, 0)}/>
                                  <YAxis yAxisId={2} width={100} orientation="right"
                                         tickFormatter={(value) => {return formatNumber(value)}} hide={true}
                                         domain={getGraphDomain(configData?.chartData, 'hours', 1.5, 0)}/>
                                  <Legend />
                                  <Bar yAxisId={1} dataKey="payroll"  fill="#005A73" name={configData?.chartConfig?.payrollName}>
                                      <LabelList dataKey="payroll" fontSize={16} position={'top'} fill={'black'} formatter={(value:any) => {return formatMoney(value)}}></LabelList>
                                  </Bar>
                                  <Bar yAxisId={2} dataKey="hours" fill="#1ACEA3" name={configData?.chartConfig?.hoursName}>
                                      <LabelList dataKey="hours" fontSize={14} position={'top'} fill={'black'} formatter={(value:any) => {return formatMoney(value, false)}}></LabelList>
                                  </Bar>

                              </BarChart>
                          </ResponsiveContainer>
                      </div>
                  </div>
              </div>
          </div>
      );

    function formatMoney(money: number, showCurrency = true) {
        let baseString = ''

        if (showCurrency){
            baseString = '$'
        }

        if (money > 9999){
            return baseString + Math.round(money/1000).toLocaleString() +'K';
        } else {
            return baseString + money.toLocaleString();
        }

    }

    function getConfigData(setConfigData: Function) {
        axios.get(`/api/system/config/${entity}/dynamic_config/`).then((res) => {
            if (res.data){
                for (let conf of res.data) {
                    if (conf.key === 'HomePOCCards'){
                        setConfigData(conf.value);

                        const config: PocModel = conf?.value ? conf?.value : {};
                        const averageBillingRate = config?.contextInsights?.dynamicValue ? config?.contextInsights?.dynamicValue : 0;
                        const grossMarginPerHour = config?.contextInsights?.cards?.card2?.value ? config?.contextInsights?.cards?.card2?.value : 0;
                        setAvrRate(averageBillingRate);
                        setAvrHourlyBillingRate(averageBillingRate + grossMarginPerHour);
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

function getGraphDomain(data: any, key: string, top_mult: number, bottom_mult: number) {
    if (!data) {
        return [0,0]
    }
    let maxValue = undefined
    let minValue = undefined

    for (let row of data){
        let value = row[key]
        if (maxValue === undefined || value > maxValue){
            maxValue = value
        }
        if (minValue === undefined || value < minValue){
            minValue = value
        }
    }

    return [minValue*bottom_mult, maxValue *top_mult]
}

function getIcon(iconID:string) {
    switch (iconID){
        case 'Dollar':
            return <AiFillDollarCircle/>
        case 'Clock':
            return <GoClockFill/>
        default:
            return <AiFillDollarCircle/>
    }

}

export default HomePOCCards;