export const configPerTabIndex = [
    ['Bonuses$', 'One off payments', 'Total hours#', 'Total OT#', 'total PTO#', 'Severity', 'Earnings (current)', 'Earnings (previous)',
                      'Change (#)', 'Change (%)', 'Approve Payslip', 'Findings'],
    ['Bonuses$', 'One off payments', 'Total hours#', 'Total OT#', 'total PTO#', 'Alerts'],
    ['Change (#)', 'Change (%)', 'Earnings (previous)', 'Bonuses$', 'One off payments', 'Alerts'],
    ['Change (#)', 'Change (%)', 'Earnings (previous)', 'Total hours#', 'Total OT#', 'total PTO#', 'Alerts'],
    ['Bonuses$', 'One off payments', 'Total hours#', 'Total OT#', 'total PTO#', 'Alerts'],
];

export function getConfigByTabName(tabName: string){
    switch (tabName){
        case '' || 'SUMMARY':
            return configPerTabIndex[0];
        case 'All':
            return configPerTabIndex[1];
        case 'Earnings and Hours':
            return configPerTabIndex[2];
        case 'bonuses & One off payment':
            return configPerTabIndex[3];
        case 'Technical information':
            return configPerTabIndex[4];
        default:
            return configPerTabIndex[0];
    }
}