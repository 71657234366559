import './fields.css';
import { useEffect, useState } from "react";
import { FieldsGenerationConfigOptions,
         FieldsGenerationSettings,
         Converter } from "./Models/FieldsConfigOptions";
import { useDropzone } from "react-dropzone";
import { FaTable } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { useForm, FormProvider} from 'react-hook-form';
import FieldsGrid from "../FieldsGrid/fieldsGrid";
import FileRows from "../UploadModal/FileRows";
import axios from "axios";
import useCustomParams from "../../hooks/useCustomParams";


function Fields() {
    const [selectedConverterDesc, setSelectedConverterDesc] = useState('');
    const [displayMissingFilesError, setDisplayMissingFilesError] = useState(false);
    const [configurations, setConfigurations] = useState(new FieldsGenerationConfigOptions());
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([] as File[]);
    const formMethods = useForm();
    const { handleSubmit,
            register,
            setValue,
            formState: { errors }} = formMethods;

    const onDrop = (newFiles: any) => {
        setAcceptedFiles([...acceptedFiles, ...newFiles]);
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const { entity } = useCustomParams();
    
    useEffect(() => {
        loadConfigurations().then((res) => {
            initializeConfiguration(res)
        })
    }, [entity]);

    const isSuperUser = localStorage.getItem('is_superuser') === "true";

    const loadConfigurations = async () => {
        const response = await axios.get(`/api/system/fields/${entity}/configurations_options`);
        return await response.data;
    };

    const initializeConfiguration = (config: FieldsGenerationConfigOptions) => {
        setConfigurations(config)

        if(config?.current_converter_id !== undefined)
        {
            const selectedConverter: Converter | undefined = config.converters.find(converter => converter.id ===  config?.current_converter_id);
            updateCurrentConverter(selectedConverter);
        }

        if(config?.current_cycle_frequency !== undefined)
            setValue('selectedCycleFrequency', config?.current_cycle_frequency);
    }

    const handleConverterChange = (event: any) => {
        const converterID = event.target.value
        const selectedConverter: Converter | undefined = configurations.converters.find(converter => converter.id ===  converterID);

        updateCurrentConverter(selectedConverter);
    };

    const updateCurrentConverter = (selectedConverter: Converter | undefined) => {
        if(selectedConverter === undefined) {
            console.log('Invalid Converter');
            return;
        }

        if(selectedConverter.id !== undefined)
            setValue('selectedConverter', selectedConverter.id);
        if(selectedConverter.description !== undefined)
            setSelectedConverterDesc(selectedConverter.description);
        if(selectedConverter.default_config == null)
            updateConverterConfig('{}');
        else
            updateConverterConfig(selectedConverter.default_config);
    };

    const updateConverterConfig = (newValue: string) => {
        try
        {
            JSON.parse(newValue); // validate json format
            setValue('selectedConverterConfig', newValue);
        } catch (error) {
            console.log('Invalid JSON format')
        }
    };

    const generateFields = (dataForm: any) => {
        setDisplayMissingFilesError(false);

        if(acceptedFiles.length === 0){
            setDisplayMissingFilesError(true);
            return;
        }

        const updateConfigurations = async(data: any) => {
            return await axios.post(`/api/system/fields/${entity}/configurations`, data,
                                    { headers: { 'Content-Type': 'multipart/form-data' } });
        };
        const formData = new FormData();
        const data = new FieldsGenerationSettings(dataForm.selectedConverter,
                                                                         dataForm.selectedConverterConfig,
                                                                         dataForm.selectedCycleFrequency);
        const dataString = JSON.stringify(data);

        formData.append('data', dataString);
        for (let file of acceptedFiles)
            formData.append('files', file);

        updateConfigurations(formData).catch((err) => {
            console.log(err);
        });
    };

    return (
        isSuperUser &&
        <div className='field-management-container'>
            <form onSubmit={handleSubmit(generateFields)}>
                <div className='fields-configuration-container'>
                    <FormProvider {...formMethods}>
                        <div className='fields-configuration-header'>
                            <IoMdSettings/>
                            <label className='fields-configuration-header-text'>Fields Generation Settings</label>
                        </div>
                        <div className='fields-configuration-top'>
                            <div className='converter-select-container'>
                                <label htmlFor="converterSelection">Select Converter: </label>
                                <select id="converterSelection" {...register('selectedConverter', {required: 'Please select converter'})}
                                        onChange={handleConverterChange}>
                                    <option value="">Select a converter</option>
                                    {configurations?.converters?.map((converter) => (
                                        <option key={converter?.id} value={converter?.id}>
                                            {converter?.name}
                                        </option>
                                    ))}
                                </select>
                                {errors?.selectedConverter && (
                                    <span className="error-message"><b>ACTION REQUIRED:</b> {errors.selectedConverter?.message ? errors.selectedConverter?.message.toString() : ""}</span>
                                )}
                            </div>
                            <div className='cycle-frequency-select-continer'>
                                <label htmlFor="cycleFrequencySelection">Select Cycle Frequency: </label>
                                <select id="cycleFrequencySelection" {...register('selectedCycleFrequency')}>
                                    <option value="">Select an option</option>
                                    {configurations?.cycle_frequency.map((frequency) => (
                                        <option key={frequency} value={frequency}>
                                            {frequency}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='fields-configuration-middle'>
                            <div className='selected-converter-description'>
                                <div className='converter-description-header'>Converter Description:</div>
                                <div className='converter-description'>{selectedConverterDesc}</div>
                            </div>
                            <div className='selected-converter-config'>
                                <div className='converter-configuration-header'>Converter Configuration:</div>
                                <textarea
                                    rows={5}
                                    cols={50}
                                    className="selected-converter-config-value"
                                    placeholder="Value (JSON)"
                                    {...register('selectedConverterConfig')}
                                    onChange={(e) => updateConverterConfig(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='fields-configuration-bottom'>
                            <div className='files-upload-continer'>
                                <div {...getRootProps({className: 'files-upload-drop-zone'})}
                                     style={{backgroundColor: `${isDragActive ? '#DFF2F7' : "white"}`}}>
                                    <div className="files-upload-img"
                                         title="Drag and drop your files here OR Click here load files"></div>
                                    <input {...getInputProps()} id="files-upload-input"/>
                                </div>
                                <div className="upload-files-section">
                                    <FileRows files={acceptedFiles} setAcceptedFiles={setAcceptedFiles}/>
                                </div>
                                <span className="error-message" style={{visibility: `${displayMissingFilesError ? "visible": "hidden"}`}}><b>ACTION REQUIRED:</b> Please upload files(at least one) in-order to generate fields</span>
                            </div>
                        </div>
                    </FormProvider>
                    <div className='generate-fields-container'>
                        <button className="generate-fields-btn" type="submit">
                            Generate Fields
                        </button>
                    </div>
                </div>
            </form>
            <div className="line">
            </div>
            <div>
                <div className='fields-table-header'>
                    <FaTable/>
                    <label className='fields-table-header-text'>Fields Table</label>
                </div>
                <FieldsGrid/>
            </div>
        </div>
);
}

export default Fields;