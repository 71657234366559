import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CategoryType } from '../components/Report/filtersTabs/categoryType';
import { configPerTabIndex } from '../components/Report/filtersTabs/columnConfig';

export interface ReportFilterState {
    ItemsToOmit: string[];
    categoryType: CategoryType;
    alertTypesFilter: string[];
};

export interface ReportSortState {
    sortId: string;
    sortDirection?: 'asc' | 'desc' | '';
};

export interface ReportState {
    filter: ReportFilterState;
    sort: ReportSortState;
};

const initialState: ReportState = {
    filter: {
        ItemsToOmit: ['Bonuses$', 'One off payments', 'Total hours#', 'Total OT#', 'total PTO#', 'Severity', 'Earnings (current)', 'Earnings (previous)',
                      'Change (#)', 'Change (%)', 'Approve Payslip', 'Findings'],
        categoryType: CategoryType.SUMMARY,
        alertTypesFilter: []
    },
    sort: {
        sortId: 'Severity',
        sortDirection: 'desc',
    }
};

export const reportStateSlice = createSlice({
  name: 'reportState',
  initialState,
  reducers: {
    setReportSortState: (state, action: PayloadAction<ReportSortState>) => {
      state.sort = { ...action.payload };
    },
    setItemsToOmit: (state, action: PayloadAction<string[]>) => {
      state.filter.ItemsToOmit = (action.payload);
    },
    setCategoryType: (state, action: PayloadAction<CategoryType>) => {
      state.sort = { sortId: "Severity", sortDirection: 'desc'};
      state.filter.categoryType = (action.payload);
    },
    setAlertTypesFilter: (state, action: PayloadAction<string[]>) => {
        state.filter.alertTypesFilter = (action.payload);
    },
    setReportFilterState: (state, action: PayloadAction<{alertTypes: string[], type: CategoryType, categoryTypeId: number}>) => {
      state.filter.alertTypesFilter = (action.payload.alertTypes);
      state.filter.categoryType = (action.payload.type);
      state.filter.ItemsToOmit = configPerTabIndex[action.payload.categoryTypeId];
    }
  }
});

export const { setReportSortState, setItemsToOmit,
                setCategoryType, setAlertTypesFilter,
                setReportFilterState } = reportStateSlice.actions;

export default reportStateSlice.reducer;