import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import { createRoot } from 'react-dom/client';


if (window.location.host.indexOf('localhost') < 0){
    Sentry.init({
        dsn: "https://e1e4d4b8cdf34f8999671d110ce24a36@o4504667454242816.ingest.sentry.io/4504677816270848",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
} else {
    console.log('Dev Mode')
}


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
              <BrowserRouter>
              <Provider store={store}>
                <App />
              </Provider>
              </BrowserRouter>
            </React.StrictMode>
     );
     
reportWebVitals();