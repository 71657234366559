import CategoryAlerts from "./newCategoryAlerts";
import './newAlertSummary.css';
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from "react-redux";
import TotalSolvedAlerts from "./newTotalSolvedAlerts";


const NewAlertSummary = () => {
    const latestController = useRef(null);

    const category = useSelector((state: any) => state?.reportStateReducer?.filter?.categoryType);
    const openAuditCompleteDialog = useSelector((state: any) => state?.PayslipsViewReducer?.openAuditCompleteDialog);
    
    const isSummaryTAb = category === '' || category === 'SUMMARY';
    
    const entity = useSelector((state: any) => state?.entityReducer?.selectedEntity);
    const cycle = useSelector((state: any) => state?.entityReducer?.selectedCycle);
    
    const [payslips, setPayslips] = useState([]); 
    
    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController?.current) {
        //@ts-ignore
            latestController.current.abort();
        }

        // Create a new controller for the current request
        const controller = new AbortController();
        //@ts-ignore
        latestController.current = controller;

        const getPayslips = async () => {
            try {
                const payslipsCall = await axios.get(`/api/payslips/${entity}/${cycle}/list/`, {signal: controller.signal});
                const newPayslips = await payslipsCall?.data;
                setPayslips(newPayslips);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error('Fetch error:', error);
                }
            }    
        };

        getPayslips();

        return () => {
            controller.abort();
        };
    }, [entity, cycle, openAuditCompleteDialog]);

    return (
        !payslips ? 
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop> 
        :
        <div className="new-alerts-panel">
            <div className="new-cards-panel">
                <TotalSolvedAlerts payslips={payslips}/>
                <CategoryAlerts payslips={payslips} text={'Fraud Suspected'} type={'Fraud'}/>
                <CategoryAlerts payslips={payslips} text={'Policy Violation'} type={'Policy'}/>
                <CategoryAlerts payslips={payslips} text={'Anomaly Identified'} type={'Anomaly'}/>
                <CategoryAlerts payslips={payslips} text={'Employees Turnover'} type={'Turnover'}/>
            </div>
        </div>
    );
}

export default NewAlertSummary;