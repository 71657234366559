import { useForm, FormProvider} from 'react-hook-form';
import axios from 'axios';


const AddUserPage = () => {
    const formMethods = useForm(); 
    const { handleSubmit, register, reset, formState: { errors } } = formMethods;

    async function onSubmit(data: any) {
        const formatedData: any = {};
        Object.keys(data).map(key => formatedData[key] = data[key]);
        formatedData.is_active = true;
        formatedData.is_superuser = false;
        formatedData.is_verified = false;
        formatedData.allowed_entities = formatedData?.allowed_entities?.split(" ")?.map((numberString: string) => Number(numberString));
        await axios.post(`/api/auth/register`, formatedData)
          .then(res => res.status === 201 ? alert(`User created successfully`) : alert(`Error in creating user, please contact admin`))
          .catch(err => console.log(err));
        
        reset();
    }

    const isSuperUser = localStorage.getItem('is_superuser') === "true";

    return (
      isSuperUser && (
        <div style={{margin:"12px"}}>
          <h3>create user</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <FormProvider {...formMethods}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div>
                    <label>First Name</label><br />
                    <input {...register("first_name", { required: true })} />
                  </div><br />
                  <div>
                    <label>Last Name</label><br />
                    <input {...register("last_name", { required: true })} />
                  </div><br />
                  <div>
                    <label>Role</label><br />
                    <input {...register("role", { required: false })} />
                  </div><br />
                  <div>
                    <label>Email</label><br />
                    <input type='email' {...register("email", { required: true })} />
                  </div><br />
                  <div>
                    <label>Password</label><br />
                    <input
                      type='password'
                      placeholder="min 6 numbers"
                      {...register("password", { required: true, minLength: {
                        value: 6,
                        message: "min 6 chars"
                      }})}
                      />
                      {<span style={{color:"red"}}>{errors.password?.message ? errors.password?.message.toString() : ""}</span>}
                  </div><br />
                  <div>
                    <label>Allowed Entities</label><br />
                    <input
                      placeholder="for example: 5 4 6 with spaces"
                      {...register("allowed_entities", { required: true })}
                    />
                  </div>
                </div>
              </FormProvider>
            </div>
            <br />
            <button className="create-user-page-form-button" type="submit">
              Submit
            </button>
          </form>
        </div>
      )
    );
}

export default AddUserPage;