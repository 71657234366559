import './PPOPage.css';
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {DataGrid, GridSortItem} from "@mui/x-data-grid";
import {formatNumber} from "../../../utils/PresentationUtils";
import {
    ContractProfitabilitySummary,
    PatientProfitabilityOverview,
    PatientProfitabilityOverviewData
} from "./PPOOverview";
import {useDispatch, useSelector} from "react-redux";
import {Backdrop, CircularProgress} from "@mui/material";
import {setFilesUploadState} from "../../../store/entitySlice";
import {useNavigate} from "react-router-dom";
import {
    setCycle,
    setEntityID,
    setOverview,
    setContractsProfitability,
    setPatientKPIFilterKey
} from "../../../store/PatientSlice";
import useCustomParams from "../../../hooks/useCustomParams";


function PPOPage (props: any){
    const latestController = useRef<AbortController | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cachedCycle: string = useSelector((state: any) => state?.PatientsViewReducer?.cycle);
    const cachedEntityID: number = useSelector((state: any) => state?.PatientsViewReducer?.entityID);
    const cachedOverviewData: PatientProfitabilityOverviewData = useSelector((state: any) => state?.PatientsViewReducer?.overview);
    const cachedContractsProfitability: ContractProfitabilitySummary[] = useSelector((state: any) => state?.PatientsViewReducer?.contractsProfitability);
    const selectedEntityObj = useSelector((state: any) => state?.entityReducer?.selectedEntityObj);
    const filesUploadState = useSelector((state: any) => state?.entityReducer?.filesUploadState);

    const { entity, cycle } = useCustomParams();

    const [filterModel, setFilterModelGrid] = useState({ items: [] });
    const [sortModel, setSortModelGrid] = useState<GridSortItem[]>([
        { field: 'name', sort: 'asc' },
        { field: 'profit', sort: 'asc' }
    ]);

    const [contractPatientsData, setContractPatientsData] = useState<ContractProfitabilitySummary[]>([]);
    const [pageStatusData, setPageStatusData] = useState<string>("");

    const [weeklyOverviewData, setWeeklyOverviewData] = useState(new PatientProfitabilityOverviewData());

    const [profitablePatientsOverview, setProfitablePatientsOverview] = useState(new PatientProfitabilityOverview());
    const [unprofitablePatientsOverview, setUnprofitablePatientsOverview] = useState(new PatientProfitabilityOverview());
    const [losePatientsOverview, setLosePatientsOverview] = useState(new PatientProfitabilityOverview());

    const isSuperUser = localStorage.getItem('is_superuser') === "true";
    const PROFITABLE_KPI_FILTER: string = "Profitable";
    const LOW_PROFITABILITY_KPI_FILTER: string = "LowProfitability";
    const LOSS_KPI_FILTER: string = "Loss";

    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController.current) {
            latestController.current.abort();
        }

        // Create a new controller for the current request
        const controller = new AbortController();
        latestController.current = controller;

        initializePageStatus();

        return () => {
            // Clean up the controller on component unmount or date change
            controller.abort();
        };
    }, [entity, cycle]);

    useEffect(() => {
        if (filesUploadState === 'Done'){
            setPageStatusData('PendingProcessing');
            setContractPatientsData([]);
            setOverviewData();
            dispatch(setFilesUploadState(''));
        }
    }, [filesUploadState]);

    const initializePageStatus = () => {
        setPageStatusData('LoadPage');
        if(!selectedEntityObj?.ppo_files_uploded) {
            setPageStatusData('FirstUse');
            setOverviewData();
        }
        else if(!selectedEntityObj?.latest_cycle_ready_ppo && !isSuperUser &&
                cycle === selectedEntityObj.last_ppo_cycle) {
            setPageStatusData('PendingProcessing');
            setOverviewData();
        }
        else
        {
            loadPatientsData();
        }
    };

    const loadPatientsData = () => {
        if (cachedContractsProfitability.length === 0 ||
            cycle !== cachedCycle ||
            entity !== cachedEntityID.toString())
        {
            loadOverviewData().then((res) => {
                setOverviewData(res);
                dispatch(setOverview(res));
                dispatch(setCycle(cycle));
                dispatch(setEntityID(parseInt(entity)));
            }).catch((e) => {
                setOverviewData();
            });

            loadPPO().then((res: any) => {
                setPageStatusData('Ready');
                setContractPatientsData(res);
                dispatch(setContractsProfitability(res));
            }).catch((e) => {
                setPageStatusData( 'PendingProcessing');
            });
        }
        else {
            loadCachedData();
        }
    };

    const loadCachedData = () => {
        setPageStatusData('Ready');
        setOverviewData(cachedOverviewData);
        setContractPatientsData(cachedContractsProfitability);
    };

    const loadPPO = async () => {
        const response = await axios.get(`/api/ppo/patients/${entity}/${cycle}/contracts_breakdown`);
        return await response?.data;
    };

    const loadOverviewData = async () => {
        const response = await axios.get(`/api/ppo/${entity}/${cycle}/overview`);
        return await response?.data;
    };

    const setOverviewData = (data: any = null) => {
        if (data == null)
        {
            setWeeklyOverviewData(new PatientProfitabilityOverviewData());
            return;
        }

        setWeeklyOverviewData(data);

        loadProfitablePatientsOverview(data);
        loadUnprofitablePatientsOverview(data);
        loadLosePatientsOverview(data);
    };

    const loadProfitablePatientsOverview = (data: any) => {
        const profitableOverview = new PatientProfitabilityOverview()

        profitableOverview.profit = data.profitability;
        profitableOverview.total_patients = data.patients_profitability_count;
        profitableOverview.profit_per_patient = data.patients_profitability_avg;

        setProfitablePatientsOverview(profitableOverview)
    };

    const loadUnprofitablePatientsOverview = (data: any) => {
        const unprofitableOverview = new PatientProfitabilityOverview()

        unprofitableOverview.profit = data.low_profitability;
        unprofitableOverview.total_patients = data.patients_low_profitability_count;
        unprofitableOverview.profit_per_patient = data.patients_low_profitability_avg;

        setUnprofitablePatientsOverview(unprofitableOverview)
    };

    const loadLosePatientsOverview = (data: any) => {
        const loseOverview = new PatientProfitabilityOverview()

        loseOverview.profit = data.loss;
        loseOverview.total_patients = data.patients_loss_count;
        loseOverview.profit_per_patient = data.patients_loss_avg;

        setLosePatientsOverview(loseOverview)
    };

    function renderHoursCell(value: any, withOutWrapper: boolean = false) {
        let s_value = Math.round(value * 100) / 100;
        let fixedNumber = parseFloat(s_value.toFixed(2));

        if (withOutWrapper)
            return fixedNumber.toLocaleString()
        return <div>{fixedNumber.toLocaleString()}</div>
    }

    function renderAmount(value: any) {
        let s_value = Math.round(value * 100) / 100;
        let fixedNumber = parseFloat(s_value.toFixed(2));

        if (s_value < 0)
        {
            fixedNumber = Math.abs(fixedNumber);
            return fixedNumber.toLocaleString();
        }
        return fixedNumber.toLocaleString();
    }

    const columns_definitions = [
        { field: 'name', headerName: 'Contract',  width: 160, hideable: false },
        { field: 'service_code', headerName: 'Service Code',  width: 150, hideable: false },
        { field: 'total_patients', headerName: 'Patients',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.total_patients), hideable: false },
        { field: 'billing_hours', headerName: 'Billing hours',  width: 110, renderCell: (params: any) => renderHoursCell(params?.row.billing_hours), hideable: false },
        { field: 'payroll_hours', headerName: 'Payroll hours',  width: 110, renderCell: (params: any) => renderHoursCell(params?.row.payroll_hours), hideable: false },
        { field: 'billing_amount', headerName: 'Billing ($)',  width: 110, renderCell: (params: any) => renderHoursCell(params?.row.billing_amount), hideable: false },
        { field: 'payroll_amount', headerName: 'Payroll ($)',  width: 110, renderCell: (params: any) => renderHoursCell(params?.row.payroll_amount), hideable: false },
        { field: 'profit', headerName: 'Profit ($)',  width: 110 , renderCell: (params: any) => renderHoursCell(params?.row.profit), hideable: false },
        { field: 'avg_billing_rate', headerName: 'Avg. Billing rate ($)',  width: 150 , renderCell: (params: any) => renderHoursCell(params?.row.avg_billing_rate), hideable: false },
        { field: 'avg_profit_rate', headerName: 'Avg. Profit per hour ($)',  width: 150, renderCell: (params: any) => renderHoursCell(params?.row.avg_profit_rate), hideable: false }
    ];

    const navigateToPatientsPage = (filterKey: string) => {
        dispatch(setPatientKPIFilterKey(filterKey));
        navigate(`/${entity}/${cycle}/patients`);
    }

    const handleSortModelChange = (newSortModel: GridSortItem[]) => {
        setSortModelGrid(newSortModel);
    };

    const handleFilterModelChange = (newFilterModel: any) => {
        setFilterModelGrid(newFilterModel);
    };

    switch (pageStatusData) {
        case 'LoadPage':
            return (
                <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        case 'FirstUse':
            return (
                <div className="PPOPageMessage">
                    <h2>To start using the system please upload the following reports:</h2>
                    <h3>Payroll report</h3>
                    <h3>Billing Report</h3>
                </div>
            )
        case 'PendingProcessing':
            return (
                <div className="PPOPageMessage">
                    <h2>Sit tight, we are crunching the numbers</h2>
                    <h3>
                        We will notify you as soon as the results are ready for your review.
                        It will take up to 48 hours
                    </h3>
                </div>
            )
        case 'Ready':
            return (
                <div className="PPOPageWrapper">
                    <div className="PPOPageTitle">
                        <img className="PPOPageTitleIcon" src="/home.svg" alt="home"/>
                        <span className="PPOPageTitleTxt">Overview</span>
                    </div>
                    <div className="PPOPageHeaders">
                        <div className="PPOPageWeeklyHeaderWrapper">
                            <div className="PPOPageHeaderTitle">
                                Weekly Summary
                            </div>
                            <div className="PPOPageWeeklyContents">
                                <div className="TotalsOverview PPOPageWeeklyOverview">
                                    <div className="PPOPageWeeklyOverviewTitle">Totals</div>
                                    <div className="PPOPageWeeklyOverviewContent">
                                        <div>Billing ${renderAmount(weeklyOverviewData.billing_amount)}</div>
                                        <div>Payroll ${renderAmount(weeklyOverviewData.payroll_amount)}</div>
                                        <div>Profit ${renderAmount(weeklyOverviewData.revenue)}</div>
                                    </div>
                                </div>
                                <div className="PPOPageWeeklyOverviewBorder"></div>
                                <div className="HoursOverview PPOPageWeeklyOverview">
                                    <div className="PPOPageWeeklyOverviewTitle">Hours</div>
                                    <div className="PPOPageWeeklyOverviewContent">
                                        <div>Billing {renderHoursCell(weeklyOverviewData.billing_hours, true)}</div>
                                        <div>Payroll {renderHoursCell(weeklyOverviewData.non_billable_payroll_hours + weeklyOverviewData.billable_payroll_hours, true)}</div>
                                    </div>
                                </div>
                                <div className="PPOPageWeeklyOverviewBorder"></div>
                                <div className="CensusOverview PPOPageWeeklyOverview">
                                    <div className="PPOPageWeeklyOverviewTitle">Census</div>
                                    <div className="PPOPageWeeklyOverviewContent">
                                        <div>{renderAmount(weeklyOverviewData.total_caregivers)} Caregivers</div>
                                        <div>{renderAmount(weeklyOverviewData.total_patients)} Patients</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="PPOPageHeaderWrapper">
                            <div className="PPOPageHeaderTitle">
                                Profit Breakdown
                            </div>
                            <div className="PPOPageHeaderCards">
                                <div className="PPOPageHeaderCard"
                                     onClick={() => navigateToPatientsPage(LOSS_KPI_FILTER)}>
                                    <div className="PPOPageHeaderCardTitle">Losing Patients</div>
                                    <div
                                        className="PPOPageHeaderCardBody PPOPageTableCellRed">${formatNumber(losePatientsOverview?.profit, false, true, false)}</div>
                                    <div className="PPOPageHeaderCardFooter">
                                        <div
                                            className="PPOPageHeaderCardFooterTotalPatients">{losePatientsOverview?.total_patients} Patients
                                        </div>
                                        <div className="PPOPageHeaderCardFooterAvg">
                                            {
                                                losePatientsOverview?.total_patients > 0 && (
                                                    "$" + formatNumber(losePatientsOverview?.profit_per_patient, false, true, false) + " per Patient"
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="PPOPageHeaderCard"
                                     onClick={() => navigateToPatientsPage(LOW_PROFITABILITY_KPI_FILTER)}>
                                    <div className="PPOPageHeaderCardTitle">Low Profitability Patients</div>
                                    <div
                                        className="PPOPageHeaderCardBody PPOPageTableCellOrange">${formatNumber(unprofitablePatientsOverview?.profit, false, true, false)}</div>
                                    <div className="PPOPageHeaderCardFooter">
                                        <div
                                            className="PPOPageHeaderCardFooterTotalPatients">{unprofitablePatientsOverview?.total_patients} Patients
                                        </div>
                                        <div
                                            className="PPOPageHeaderCardFooterAvg">${formatNumber(unprofitablePatientsOverview?.profit_per_patient, false, true, false)} per
                                            Patient
                                        </div>
                                    </div>
                                </div>
                                <div className="PPOPageHeaderCard"
                                     onClick={() => navigateToPatientsPage(PROFITABLE_KPI_FILTER)}>
                                    <div className="PPOPageHeaderCardTitle">Profitable Patients</div>
                                    <div
                                        className="PPOPageHeaderCardBody PPOPageTableCellGreen">${formatNumber(profitablePatientsOverview?.profit, false, true, false)}</div>
                                    <div className="PPOPageHeaderCardFooter">
                                        <div
                                            className="PPOPageHeaderCardFooterTotalPatients">{profitablePatientsOverview?.total_patients} Patients
                                        </div>
                                        <div
                                            className="PPOPageHeaderCardFooterAvg">${formatNumber(profitablePatientsOverview?.profit_per_patient, false, true, false)} per
                                            Patient
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="PPOPageTableArea">
                        <div className="PPOPageTableTitle">Contracts Breakdown</div>
                        <div className="PPOPageTableBody">
                            <DataGrid rows={contractPatientsData}
                                      columns={columns_definitions}
                                      getRowId={(row: any) => `${row.name}_${row.service_code}`}
                                      hideFooterSelectedRowCount={true}
                                      onSortModelChange={handleSortModelChange}
                                      sortModel={sortModel}
                                      onFilterModelChange={handleFilterModelChange}
                                      filterModel={filterModel}
                                      initialState={{
                                          pagination: {
                                              paginationModel: {
                                                  pageSize: 10,
                                              },
                                          },
                                      }}
                            />
                        </div>
                    </div>
                </div>
            )
    }
}


export default PPOPage;
