import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AverageRateDashboardState {
    selectedField: string;
    selectedFieldValue: string;
    selectedEmployee: string;
};

const initialState: AverageRateDashboardState = {
    selectedField: "",
    selectedFieldValue: "",
    selectedEmployee: ""
};

export const AverageRateDashboardStateSlice = createSlice({
  name: "reportState",
  initialState,
  reducers: {
    setSelectedField: (state, action: PayloadAction<string>) => {
        state.selectedField = action.payload;
    },
    setSelectedFieldValue: (state, action: PayloadAction<string>) => {
        state.selectedFieldValue = action.payload;
    },
    setSelectedEmployee: (state, action: PayloadAction<string>) => {
        state.selectedEmployee = action.payload;
    },
  }
});

export const { setSelectedField, setSelectedFieldValue, setSelectedEmployee } = AverageRateDashboardStateSlice.actions;

export default AverageRateDashboardStateSlice.reducer;