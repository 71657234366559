import {useEffect, useState} from 'react';
import './CommentDialog.css';
import ReactModal from 'react-modal';
import axios from "axios";
import mixpanel from "mixpanel-browser";
import {Autocomplete, Chip, TextField} from "@mui/material";
import {AiOutlineQuestionCircle} from "react-icons/ai";

function CommentDialog(props){
    const [comment, setComment] = useState('');
    const [recipientsError, setRecipientsError] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [knownEmails, setKnownEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        getKnownEmails(setKnownEmails);

        return () => {
            if(props?.setReplyTo) props?.setReplyTo(null);
        }
    }, []);

    
    if (props?.selectedEmails && selectedEmails?.length === 0){
        setSelectedEmails([props?.selectedEmails]);
    }

    useEffect(() => {
        setComment('');
        setSelectedEmails([]);
        setInputValue('');
    }, [props.open]);

    function saveComment(payslip, finding, comment, selectedEmails) {
        mixpanel.track('Add Comment', {'payslip_id': payslip, 'finding_id': finding})
        axios.post(`/api/payslips/${payslip}/comment`,
            {finding: finding, content: comment, approved: true, recipients: selectedEmails},
            {'Content-Type': 'application/json'}).then((res) => {
            props.closeDialog(true);
        }).catch((err) => {
            console.log(err)
            props.closeDialog(true);
        });
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const handleChange = (event, value, reason) => {
        if (reason === 'selectOption') {
            let isValid = value?.filter(isValidEmail);
            if (isValid) {
                setSelectedEmails([
                    ...value
                ]);
                setRecipientsError(false);
            } else {
                setRecipientsError(true);
            }
        } else if (reason === 'input') {
            setInputValue(value);
            const hasSpace = value?.includes(' ');
            const valueWithoutSpace = value?.replace(' ', '');
            if (isValidEmail(valueWithoutSpace) && hasSpace) {
                if (selectedEmails.includes(valueWithoutSpace)) {
                    setInputValue('');
                    return;
                }
                setSelectedEmails(prevEmails => [...prevEmails, valueWithoutSpace]);
                setRecipientsError(false);
                setInputValue('');
                setRecipientsError(false);
            }else{
                setRecipientsError(true);
                if (hasSpace) {
                    setInputValue('');
                    return;
                }
            }
        }else if (reason === 'createOption') {
            const enterValue = value[value?.length - 1];
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            const isValidEmail = emailRegex.test(enterValue);

            if (isValidEmail === false) {
                setInputValue('');
                return;
            }
            
            setSelectedEmails(prevEmails => [...prevEmails, enterValue]);
            setRecipientsError(false);
            setInputValue('');
            setRecipientsError(false);
            
        } else if (reason === 'clear') {
            setSelectedEmails([]);
            setInputValue('');
            props?.setReplyTo(null);
        }
    };

    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "664px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        overlay: {
          backgroundColor: "#102E442E",
          backdropFilter: "blur(4px) brightness(100%)",
        }
    };

    return (
        <ReactModal
            isOpen={props.open}
            className="comment-modal"
            ariaHideApp={false}
            style={customStyles}
            onRequestClose={() => props.closeDialog(false)}
        >
            <div className="comment-dialog">
                <div className='comment-dialog-header'>
                    <img src='/comment-close.svg' alt='close' className="btn-cancel" onClick={() => props.closeDialog(false)} />
                    <label className='comment-label'>{props?.selectedEmails ? `Reply to a Comment` : 'Add Comment'}</label>
                </div>
                <div className='wrap-comment-textarea'>
                    <div className="pull-tab"></div>
                    <textarea className='comment-text-area' value={comment} placeholder='Write your comment here...' onChange={(event) => setComment(event.target.value)}></textarea>
                </div>

                <div>
                    <label className='comment-email-label' >Send email to  </label>
                    <AiOutlineQuestionCircle title="Emails will be sent to the recipients,
                    they will be able to reply to the email and it will be added as a comment on the payslip."/>
                </div>
                {renderAutoComplete()}
                <span hidden={!recipientsError} className="CommentDialogErrorLabel">Please enter valid emails with space after each email</span>
                <span className="btn-wrap">
                    <div className="btn-add-comment" onClick={() => saveComment(Number.parseInt(props?.payslip_id), null, comment, selectedEmails)}>Add comment</div>
                </span>
            </div>
        </ReactModal>
    );

    function renderAutoComplete() {
        return (
            <Autocomplete
                className='comments-autocomplete'
                multiple
                freeSolo
                value={selectedEmails}
                inputValue={inputValue}
                onChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
                onInputChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
                options={knownEmails}
                renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                        <Chip
                            key={'c_'+option}
                            label={option}
                            variant="outlined"
                            onDelete={() =>{
                                    setSelectedEmails(selectedEmails.filter((e) => e !== option));
                                    props?.setReplyTo(null);
                                }
                            }
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} label="" placeholder="Press space or enter to add multiple emails"
                               className='comments-textfield'/>
                )}
            />
        )
    }
}

export default CommentDialog;

function getKnownEmails(setKnownEmailsFunc) {
    axios.get('/api/communication/emails/').then((res) => {
        if (res.data){
            return setKnownEmailsFunc(res.data);
        } else {
            return setKnownEmailsFunc([]);
        }
    }).catch((err) => {
        console.log(err);
    })
}