
import { useForm, FormProvider} from 'react-hook-form';
import TestContainer from './testContainer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './testConfigPage.css';
import useCustomParams from '../../hooks/useCustomParams';
import {setSelectedEntity} from "../../store/entitySlice";


const TestConfigPage = () => {
    const [config, setConfig] = useState(null);

    const formMethods = useForm(); 
    const { handleSubmit, register } = formMethods;
    const [customFilter, setCustomFilter] = useState('');

    async function onSubmit(data) {
      const formatedData = Object.keys(data).map((key) => { return{idInDb: key, value: data[key]}});
        await axios.patch(`/api/payslips/test_configurations_update/${entity}`, formatedData);
    }

    function handleFilterChange(event){
        setCustomFilter(event?.target?.value)
    }

    useEffect(() => {
      const getApiData = async () => {
        const response = await axios.get(`/api/payslips/test_configurations/${currentEntityId}`);
        const data = await response.data;
        setConfig(data);
      }
      getApiData();
    }, []);

    const { entity } = useCustomParams();
    const currentEntityId = parseInt(entity);
    const testData = config?.testsConfig ? config?.testsConfig : [];

    const isSuperUser = localStorage.getItem('is_superuser') === "true";

    return (
      isSuperUser &&
        <div style={{margin:"12px"}}>
          <h3>{testData?.entityName} entity</h3>
            <div>
                Filter
                <select
                    onChange={handleFilterChange}>
                    <option value="">Show Non Custom</option>
                    {testData?.tests?.filter((test, i) => test?.test_name?.includes('Custom')).map((test, i) => (
                        <option key={test?.test_name?.replace(/([A-Z])/g, ' $1').trim()}
                                value={test?.test_name?.replace(/([A-Z])/g, ' $1').trim()}>
                            {test?.test_name?.replace(/([A-Z])/g, ' $1').trim()}
                        </option>
                    ))}
                </select>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className='config-page-form-scrollable-area'>
                    <FormProvider {...formMethods}>
                        {testData?.tests?.map((test, i) => (
                            <TestContainer {...test} register={register} key={i} filter={customFilter}/>
                        ))}
                    </FormProvider>
                </div>
                <button className="config-page-form-button" type="submit">Submit</button>
            </form>
        </div>
    );
}

export default TestConfigPage;