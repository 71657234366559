import '../HomeNew/HomeNew.css';
import axios from "axios";
import {Fragment, useEffect, useState} from "react";
import {ChartData, Insight, PayrollData} from '../HomeNew/HomeNewObjects';
import HomeNewChartsSegment from "../HomeNewChartsSegment/HomeNewChartsSegment";
import HomeNewInsight from "../HomeNewInsight/HomeNewInsight";
import HomeNewCard from "../HomeNewCard/HomeNewCard";
import { calcAvgGrossMarginPerHour, calcChangePercent, calcGrossMargin, calcHPPD, calcPPD, } from "../HomeNew/HomeNewHelperFunctions";
import useCustomParams from '../../hooks/useCustomParams';


function HomeNewDemo(){
    
    const { entity, cycle } = useCustomParams();
    const [chartData, setChartData] = useState<ChartData[]>([] as ChartData[]);
    const [insights, setInsights] = useState<Insight[]>([] as Insight[]);
    const [userInput, setUserInput] = useState<number>(28.5);
    const [pageStyle, setPageStyle] = useState<string>('HomeCare')
    const [payrollData, setPayrollData] = useState<PayrollData>({} as PayrollData);
    const [payrollFrequency, setPayrollFrequency] = useState<number>(7);
    

    useEffect(() => {
        getConfigData();
        getPageData();
    }, [entity, cycle])

    return (
          <div>
              <div className="home-header">
                  <img src='/home.svg' alt='home'/>
                  <span className='home-header-txt'>Overview</span>
              </div>
              <div className='NewHomeComponents'>
                {/*<div>*/}
                {/*    <span className='NewHomeUserInputSpan'><input className="NewHomeUserInput" type="number" name="currency" value={userInput} onChange={e => setUserInput(parseFloat(e.target.value))}/></span>*/}
                {/*    <span className='NewHomeUserInputText'>{pageStyle == 'HomeCare' ? 'Avg Billing Hour ($)' : 'Census'}</span>*/}
                {/*</div>*/}
                <div className='NewHomeCardArea'>
                    {
                        pageStyle == 'HomeCare' ? renderHomeCareCards() : renderNursingHomeCards()
                    }

                </div>
              </div>
              <div className="NewHomeInsightWrapper">
                  <div className="NewHomeInsightArea">
                      <div className="NewHomeAreaHeader">
                          <div className="NewHomeAreaHeaderText">
                              Remaining Stages in Payroll Review: 3
                          </div>
                      </div>
                      <div>
                          {
                              insights.map((insightItem: Insight) =>
                                  {return <HomeNewInsight insightItem={insightItem}  key={'ii_' + insightItem.topBlack}></HomeNewInsight>}
                              )
                          }
                      </div>
                  </div>
                  <div className="NewHomeChartArea">
                      <div className="NewHomeAreaHeader">
                          <div className="NewHomeAreaHeaderText">
                              Payroll Overview
                          </div>
                      </div>
                      <HomeNewChartsSegment chartData={chartData}></HomeNewChartsSegment>
                  </div>
              </div>
          </div>
      );
    // const iconsArray = ['', 'gross-margin.svg', 'gross-margin-per-hour.svg', 'total-hours.svg', 'hourly-rate.svg'];
    function renderHomeCareCards() {
        const grossMargin = calcGrossMargin(payrollData.totalPayroll, payrollData.totalHours, userInput)
        const lastGrossMargin = calcGrossMargin(payrollData.lastTotalPayroll, payrollData.lastTotalHours, userInput)
        const grossMarginChange = calcChangePercent(grossMargin, lastGrossMargin)
        const avgGrossMarginPerHour = calcAvgGrossMarginPerHour(payrollData.totalPayroll, payrollData.totalHours, userInput)
        const lastAvgGrossMarginPerHour = calcAvgGrossMarginPerHour(payrollData.lastTotalPayroll, payrollData.lastTotalHours, userInput)
        const avgGrossMarginPerHourChange = calcChangePercent(avgGrossMarginPerHour, lastAvgGrossMarginPerHour)

        return (
            <Fragment>
                <HomeNewCard header={"Gross Margin"} value={grossMargin}
                             change={grossMarginChange}
                             isPercent={true} isDollar={false} hideArrows={true} icon={"/gross-margin-per-hour.svg"}></HomeNewCard>
                <HomeNewCard header={"AVG. Gross Margin Per Hour"} value={avgGrossMarginPerHour}
                             change={avgGrossMarginPerHourChange}
                             isPercent={false} isDollar={true} hideArrows={true} icon={"/gross-margin-per-hour.svg"}></HomeNewCard>
                <HomeNewCard header={"Total Hours"} value={payrollData.totalHours}
                             change={calcChangePercent(payrollData.totalHours, payrollData.lastTotalHours)}
                             isPercent={false} isDollar={false} hideArrows={true} icon={"/total-hours.svg"}></HomeNewCard>
                <HomeNewCard header={"Total Payroll"} value={payrollData.totalPayroll}
                             change={calcChangePercent(payrollData.totalPayroll, payrollData.lastTotalPayroll)}
                             isPercent={false} isDollar={true} hideArrows={true} icon={"/hourly-rate.svg"}></HomeNewCard>
            </Fragment>
        )
    }

    function renderNursingHomeCards() {
        const PPD = calcPPD(payrollData.totalPayroll, payrollData.totalHours, userInput, payrollFrequency)
        const lastPPD = calcPPD(payrollData.lastTotalPayroll, payrollData.lastTotalHours, userInput, payrollFrequency)
        const PPDChange = calcChangePercent(PPD, lastPPD)
        const HPPD = calcHPPD(payrollData.totalPayroll, payrollData.totalHours, userInput, payrollFrequency)
        const lastHPPD = calcHPPD(payrollData.lastTotalPayroll, payrollData.lastTotalHours, userInput, payrollFrequency)
        const HPPDChange = calcChangePercent(HPPD, lastHPPD)

        return (
            <Fragment>
                <HomeNewCard header={"PPD"} value={PPD}
                             change={PPDChange}
                             isPercent={false} isDollar={true} icon={"/gross-margin-per-hour.svg"} hideArrows={true}></HomeNewCard>
                <HomeNewCard header={"HPPD"} value={HPPD}
                             change={HPPDChange}
                             isPercent={false} isDollar={false} icon={"/total-hours.svg"} hideArrows={true}></HomeNewCard>
                <HomeNewCard header={"Total Hours"} value={payrollData.totalHours}
                             change={calcChangePercent(payrollData.totalHours, payrollData.lastTotalHours)}
                             isPercent={false} isDollar={false} icon={"/total-hours.svg"} hideArrows={true}></HomeNewCard>
                <HomeNewCard header={"Total Payroll"} value={payrollData.totalPayroll}
                             change={calcChangePercent(payrollData.totalPayroll, payrollData.lastTotalPayroll)}
                             isPercent={false} isDollar={true} icon={"/hourly-rate.svg"} hideArrows={true}></HomeNewCard>
            </Fragment>
        )
    }

    function getPageData() {
        axios.get(`/api/reports/${entity}/${cycle}/new_home_page/`).then((res) => {
            if (res.data){
                setInsights(res.data.insights)
                setChartData(res.data.chartData)
                setPayrollData(res.data.payrollData)
                setPayrollFrequency(res.data.payrollFrequency)
                setUserInput(res.data.defaultInput)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    function getConfigData() {
        axios.get(`/api/system/config/${entity}/dynamic_config/`).then((res) => {
            if (res.data){
                for (let conf of res.data) {
                    if (conf.key === 'HomeNew'){
                        if (conf.value?.pageStyle) {
                            setPageStyle(conf.value?.pageStyle)
                        }
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

export default HomeNewDemo;

