import { Button, Popover } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

function TimeAndAttendanceRuleSummary(){
    const rules = ["Administrators are limited to a maximum of 80 working hours per pay cycle.", "Validation of bonus hours.", "Each employee must take a 30-minute lunch break daily.",
        "Administrators are restricted from using non-admin earning codes.", "Overtime hours for any employee must not exceed 24 hours per pay cycle.", "Review all Retro punch adjustments for accuracy."];

    return (
        <PopupState variant="popover" popupId="TimeAndAttendanceRuleSummaryPagePopup">
            {(popupState) => (
              <div>
                <Button className="TimeAndAttendanceRuleSummaryPagePopupBtn" variant="contained" {...bindTrigger(popupState)} sx={{ textTransform: 'none' }}>
                    Show Rules
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                    <div className="TimeAndAttendanceRuleSummaryPage">
                        <div><b>Time and Attendance Rules Checked in Celery:</b></div>
                        <ol className="TimeAndAttendanceRuleSummary">{rules.map(x => <li>{x}</li>)}</ol>
                    </div>
                </Popover>
              </div>
            )}
          </PopupState>
    )
}

export default TimeAndAttendanceRuleSummary;