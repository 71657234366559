import csvDownload from "json-to-csv-export";
import axios from "axios";

export function DownloadJSONAsCSV(data={}, filename, propertyMapping) {
    const transformedData = propertyMapping === null || propertyMapping === undefined ? data :  data.map(item => {
        let transformedItem = {};
        for (let [key, newHeader] of Object.entries(propertyMapping)) {
            if (item[key] !== undefined && item[key] !== null)
            {
                transformedItem[newHeader] = item[key];
            }
            else {
                transformedItem[newHeader] = '';
            }
        }
        return transformedItem;
    });
    const csvDonwloadData = {
        data: transformedData,
        filename: filename,
        delimiter: ',',
        headers: Object.keys(transformedData[0])
    }

    csvDownload(csvDonwloadData)
}

export function GetAndDownloadJSONAsCSV(url, filename) {
    axios.get(url).then((res) => {
        DownloadJSONAsCSV(res.data, filename)
    }).catch((err) => {
        console.log(err);
    })
}

export function DownloadArrayAsCSV(data={}, filename) {
    const csvDonwloadData = {
        data: data,
        filename: filename,
        delimiter: ',',
        headers: Object.keys(data[0])
    }

    csvDownload(csvDonwloadData)
}
