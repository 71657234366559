import { useFormContext } from "react-hook-form";

const TestValidation = (testValidationProps) => {
    const { register, formState: { errors } } = useFormContext();

    const inputType = isNaN(testValidationProps?.value) ? "text" : "number";
    const idInDb = `${testValidationProps?.testConfigId}`;
    const headerText = testValidationProps?.validationName?.replace(/_/g, ' ');

    return (
        <div className="config-page-validation-container">
            <label className="config-page-form-label" htmlFor={testValidationProps?.validationName}>{headerText}</label>
            <input type={inputType} id={idInDb}
             step="any"
             defaultValue={testValidationProps?.value} 
             {...register(idInDb, {
                required: true
             })}/>
             {errors[idInDb] && <div className="config-page-form-error-message">required</div>}
        </div>
    );
}

export default TestValidation;