import DataTable from "react-data-table-component";
import ExportBtn from "../../ExportBtn/ExportBtn";
import Checkbox from "../../Checkbox/Checkbox";
import axios from "axios";
import './EmployeeChanges.css';
import useCustomParams from "../../../hooks/useCustomParams";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {formatDate} from "../../../utils/PresentationUtils";

function EmployeeChanges(props: any){
    const { entity } = useCustomParams();
    const navigate = useNavigate();

    const [selectedRowId, setSelectedRowId] = useState(null);

    const approve_cell = (row: any, index: any, column: any, id: any) =>{
        return <Checkbox defaultChecked={row.terminated_approved} title='check to approve, uncheck to cancel'
                         onChange={() => {
                             row.terminated_approved = !row.terminated_approved;
                             update_checked(row.xid, row.terminated_approved);
                             if (row.id === selectedRowId)
                                setSelectedRowId(row.id + 1);
                             else
                                 setSelectedRowId(row.id);
                         }}
        ></Checkbox>
    }

    const handleLastCycleColumnClicked = (row: any) => {
        navigate(`/${entity}/${row['last_cycle']}/payslips/?id=${row['last_payslip_id']}`);
    };

    const columns = [
        {
            name: 'Name',
            selector: (row: any) => row['name'],
            sortable: true,
        },
        {
            name: 'ID',
            selector: (row: any) => row['xid'],
            sortable: true,
        },
        {
            name: 'Department',
            selector: (row: any) => row['department'],
            sortable: true,
        },
        {
            name: 'Last Cycle',
            selector: (row: any) => row['last_cycle'],
            sortable: true,
            cell: (row: any) => (
                <div onClick={() => handleLastCycleColumnClicked(row)} className="employee-last-cycle-col">
                    {formatDate(row['last_cycle'], false, false, true)}
                </div>
            )
        },
        {
            name: 'Removed from Payroll',
            selector: (row: any) => row.terminated_approved,
            sortable: true,
            cell: approve_cell
        }
    ]

    const update_checked = (employee_xid: any, approved: any) => {
        axios.post(`/api/employees/${entity}/${employee_xid}/terminated/${approved}`).then((res) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    const rowStyles = {
        fontWeight: 700,
        color: 'var(--warm-black)',
        fontSize: '14px',
        letterSpacing: '-0.35px',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        backgroundColor: 'var(--cultured)',
        padding: '8px 12px',
        borderRadius: '8px',
        height: '40px',
        marginBottom: '12px',
    };
    const terminated_approvedRowStyles = {...rowStyles};
    terminated_approvedRowStyles['backgroundColor'] = '#cfcbcb';

    const conditionalRowStyles = [
        {
            when: (row: any) => row.terminated_approved,
            style: terminated_approvedRowStyles
        },
        {
            when: (row: any) => !row.terminated_approved,
            style: rowStyles
        }
    ];

    const propertyMapping = {
        "name": "Full Name",
        "xid": "ID",
        "department": "Department",
        "last_cycle": "Last Cycle",
        "terminated_approved": "Removed from Payroll"
    };

    return <div className="employee-changes">
        <div className='dashboard-component-header'>
            <div id={props.id}>{props.text}</div>
            <ExportBtn data={props.data} filename={"CeleryEmployeeChangesExport.csv"} propertyMapping={propertyMapping}></ExportBtn>
        </div>
        <div className="employee-changes-table">
            <DataTable
                key={selectedRowId}
                conditionalRowStyles={conditionalRowStyles}
                columns={columns}
                data={props.data}
                defaultSortFieldId={0}
                theme="default"
                responsive
                dense
            />
        </div>
    </div>
}

export default EmployeeChanges;