import './FindingCell.css';
import axios from "axios";
import CustomizedTooltips from "../Tooltip/tooltip";

function FindingCell(props){
    let numberOfItems = props.finding_len;
    const isAlertType = props.isAlertType;

    function getFindingsText(payslipId, setDataCallback) {
        const queryString = props.isAlertType ? 'only_high=true' : 'ignore_high=true';
        axios.get(`/api/payslips/${payslipId}/findings?${queryString}`).then((res) => {
            const details = [];

            for (let finding of res.data)
                details.push(finding.message);


            setDataCallback(details);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <CustomizedTooltips
            loadContent={() => (
                <div className={isAlertType ? 'alert-icon' : 'warning-icon'}>
                    <img src={isAlertType ? '/close-circle.svg' : '/close-circle-gray.svg'} alt='finding'/>{numberOfItems}
                </div>
            )}
            loadTooltipData={(setDataCallback) => {
                getFindingsText(props.payslipId, setDataCallback)
            }}
        />
    )
}



export default FindingCell;