import {useEffect, useState, useRef} from 'react';
import './Report.css';
import {Link, useSearchParams} from "react-router-dom";
import axios from "axios";
import {formatDate, formatNumber, getNumberFormatCell} from "../../utils/PresentationUtils";
import FindingCell from "../FindingCell/FindingCell";
import mixpanel from "mixpanel-browser";
import ExportBtn from "../ExportBtn/ExportBtn";
import ReportTab from './filtersTabs/reportTabSection';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setBackToReport, setPayslips, resetPayslipsSeen} from "../../store/PayslipSlice";
import {setReportSortState} from "../../store/reportSlice";
import {CategoryType} from "./filtersTabs/categoryType";
import {AlertType} from "./filtersTabs/alertType";
import { toNameCase } from "../../utils/string.utils";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DataTable from 'react-data-table-component';
import AlertSummary from '../AlertsSummary/alertsSummary';
import CustomizedTooltips from "../Tooltip/tooltip";
import { useNavigate } from 'react-router-dom';
import useCustomParams from "../../hooks/useCustomParams";
import ValueSummaryItem from '../Home/ValueSummaryItem';
import { Backdrop, CircularProgress } from '@mui/material';


function Report(){
    const latestController = useRef(null);

    const [data, setData] = useState([]);
    const [viewData, setViewData] = useState(null);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [findingsCounts, setFindingsCounts] = useState([]);
    const [filter, setFilter] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [textFilter, setTextFilter] = useState('');
    const { entity, cycle } = useCustomParams();
    const [cycleStatus, setCycleStatus] = useState(null);

    const category = useSelector((state) => state?.reportStateReducer?.filter?.categoryType);
    const sortIdState = useSelector((state) => state?.reportStateReducer?.sort?.sortId);
    const sortDirectionState = useSelector((state) => state?.reportStateReducer?.sort?.sortDirection);
    const alertFilter = useSelector((state) => state?.reportStateReducer?.filter?.alertTypesFilter);

    const selectedEntityObj = useSelector((state) => state?.entityReducer?.selectedEntityObj);
    const userRole = localStorage.getItem('user_role');
    const enableApproveBtn = !(selectedEntityObj?.disable_hr_approve_permission && userRole === 'HR')

    const isSummaryTAb = category === '' || category === 'SUMMARY';

    const dispatch = useDispatch();

    function severitySort(rowA, rowB) {
        let a = rowA?.has_findings === 2 ? 4 : rowA.active_severity;
        let b = rowB?.has_findings === 2 ? 4 : rowB.active_severity;

        a = adjustForDemo(rowA?.name, a)
        b = adjustForDemo(rowB?.name, b)

        if(a > b) {
            return -1
        } else if (b > a) {
            return 1
        } else {
            return 0
        }
    };

    function adjustForDemo(name, value){
        switch (name) {
            case 'Lauren, Frost':
                value -= 0.5;
                break
            case 'Mercedes, Vang':
                value -= 0.4;
                break
            case 'Ricardo, Trevino':
                value -= 0.3;
                break
            case 'Dalton, Walter':
                value -= 0.2;
                break
            case 'Alexa, Church':
                value -= 0.1;
                break
        }

        return value
    }

    function getCycleStatus(setData) {
        axios.get(`/api/reports/${entity}/${cycle}/cycle_status/`).then((res) => {
            if (res.data){
                setData(res.data)
            } else {
                setData({})
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const getSeverityColumn = (severity) => {
        switch (severity) {
            case 1:
                return <div className='severity-column'><img className='severity-column-img' src='/urgent.svg' alt='urgent'/>Urgent</div>;
            case 2:
                return <div className='severity-column'><img className='severity-column-img' src='/high.svg' alt='high'/>High</div>;
            case 3:
                return <div className='severity-column'><img className='severity-column-img' src='/medium.svg' alt='medium'/>Medium</div>;
            case 4:
                return <div className='severity-column'><img className='severity-column-img' src='/low.svg' alt='low'/>Low</div>;
            default:
                return <div className='severity-column'><img className='severity-column-img' src='/low.svg' alt='low'/>Low</div>;
        }
    };

    const fieldsToOmit = useSelector((state) => state?.reportStateReducer?.filter?.ItemsToOmit);

    const columns = [
        {
            id: 'Severity',
            name: 'Severity',
            selector: row => row?.has_findings === 2 ? getSeverityColumn(4) : getSeverityColumn(row?.severity),
            sortKey: 'severity',
            sortable: true,
            sortFunction: severitySort,
            omit: fieldsToOmit?.includes('Severity') ? true : false,
        },
        {
            id: 'Employee Name',
            name: 'Employee Name',
            selector: row => row.name,
            sortKey: 'name',
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row?.name?.length > 20,
                    classNames: ['employee-name-ellipsis', 'regular-cell-text'],
                }
            ],
            cell: link_cell,
        },
        {
            id: 'Employee ID',
            name: 'Employee ID',
            selector: row => row.employee_xid,
            sortable: true,
            sortKey: 'employee_xid',
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Department',
            name: 'Department',
            selector: row => row.branch,
            sortable: true,
            sortKey: 'branch',
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Earnings (current)',
            name: 'Earnings (current)',
            selector: row => row.gross,
            sortable: true,
            sortKey: 'gross',
            omit: fieldsToOmit?.includes('Earnings (current)') ? true : false,
            cell: getNumberFormatCell('gross'),
                    conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Total hours#',
            name: 'Total hours#',
            selector: row => row?.total_hours,
            sortable: true,
            sortKey: 'total_hours',
            cell: getNumberFormatCell('total_hours'),
            omit: fieldsToOmit?.includes('Total hours#') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'OT Hours',
            name: 'OT Hours',
            selector: row => row?.total_OT,
            sortable: true,
            sortKey: 'total_OT',
            cell: getNumberFormatCell('total_OT'),
            omit: fieldsToOmit?.includes('Total OT#') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'PTO Hours',
            name: 'PTO Hours',
            selector: row => row?.total_PTO,
            sortable: true,
            sortKey: 'total_PTO',
            cell: getNumberFormatCell('total_PTO'),
            omit: fieldsToOmit?.includes('total PTO#') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Bonuses',
            name: 'Bonuses',
            selector: row => row?.bonuses,
            sortable: true,
            sortKey: 'bonuses',
            cell: getNumberFormatCell('bonuses'),
            omit: fieldsToOmit?.includes('Bonuses$') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'One off payments',
            name: 'One off payments',
            selector: row => row?.one_off_payments,
            sortable: true,
            sortKey: 'one_off_payments',
            cell: getNumberFormatCell('one_off_payments'),
            omit: fieldsToOmit?.includes('One off payments') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Earnings (previous)',
            name: `Earnings (previous)`,
            selector: row => row.gross_lc,
            sortable: true,
            sortKey: 'gross_lc',
            cell: getNumberFormatCell('gross_lc'),
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
            omit: fieldsToOmit?.includes('Earnings (previous)') ? true : false,
        },
        {
            id: 'Change (#)',
            name: 'Change (#)',
            selector: row => row.change,
            sortable: true,
            sortKey: 'change',
            cell: getNumberFormatCell('change'),
            omit: fieldsToOmit?.includes('Change (#)') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Change (%)',
            name: 'Change (%)',
            selector: row => row.change_p,
            cell: percent_cell,
            sortable: true,
            sortKey: 'change_p',
            omit: fieldsToOmit?.includes('Change (%)') ? true : false,
            conditionalCellStyles: [
                {
                    when: row => true,
                    classNames: ['regular-cell-text'],
                }
            ],
        },
        {
            id: 'Status',
            name: 'Status',
            selector: row => row.has_findings,
            sortable: true,
            sortKey: 'has_findings',
            cell: status_cell,
            sortFunction: statusSort,
        },
        {
            id: 'Alerts',
            name: 'Alerts',
            selector: row => row?.findings_summery,
            omit: fieldsToOmit?.includes('Alerts') ? true : false,
            cell: alert_cell,
            grow: 1.5
        },
        {
            id: 'Findings',
            name: 'Findings',
            selector: row => row.findings_summary,
            sortable: true,
            sortKey: 'findings_summary',
            cell: findingCell,
            omit: fieldsToOmit?.includes('Findings') ? true : false,
        },
        {
            id: 'Comments',
            name: 'Comments',
            selector: row => row.has_comments,
            cell: comment_cell,
            omit: fieldsToOmit?.includes('Comments') ? true : false,
        },
        {
            id: 'Approve Payslip',
            name: 'Approve Payslip',
            selector: row => row.has_findings,
            sortable: true,
            sortKey: 'has_findings',
            cell: approve_cell,
            sortFunction: statusSort,
            omit: fieldsToOmit?.includes('Approve Payslip') ? true : false,
        },
    ];

    function link_cell(row) {
        return <Tooltip title={`${row?.name?.length > 20 ? toNameCase(row['name']) : ""}`} placement="top"><Link to={`/${entity}/${cycle}/payslips/?id=`+row['id']} className="report-name-link">
            {`${row?.name?.length < 20 ? toNameCase(row['name']) : toNameCase(row['name']).substring(0, 20) + '...'}`}
        </Link></Tooltip>
    }

    function findingCell(row) {
        const payslip_id = row['id']
        const has_finding = row['has_findings']
        const total_alerts = row['total_alerts']
        const total_warnings = row['total_warnings']

        if (has_finding > 0) {
            return (
                <div className='finding-column'>
                    {
                        (total_alerts ?? 0) > 0 &&
                        <FindingCell payslipId={payslip_id} finding_len={total_alerts} isAlertType={true}></FindingCell>
                    }
                    {
                        (total_warnings ?? 0) > 0 &&
                        <FindingCell payslipId={payslip_id} finding_len={total_warnings} isAlertType={false}></FindingCell>
                    }
                </div>
            )
        }
    }

    function statusSort(rowA, rowB) {
        const a = rowA.has_findings;
        const b = rowB.has_findings;

        if (a > b) {
            return -1;
        }

        if (b > a) {
            return 1;
        }

        return 0;
    };

    function comment_cell(row) {
        const val = row['has_comments']
        if (val) {
            return <div className='report-comment' title='Payslip has comments'><img src='/comment.svg' alt='comment'/></div>
        } else {
            return
        }
    }

    function status_cell(row) {
        const val = row['has_findings']
        let cls = val === 1 ? 'cell-button-review' : 'cell-button-approved'
        let text  = 'OK'
        switch (val) {
            case 1:
                if (row['active_severity'] > 2){
                    cls = 'cell-button-approved'
                    text = 'Approved'
                    break; 
                } 
                text = 'Review';
                break;
            case 2:
                text = 'Approved';
                break;
            case 3:
                text = 'Fixed';
                break;
            default:
                break;
        }

        return <Link to={`/${entity}/${cycle}/payslips/?id=`+row['id']} className={cls}>
            {text}
        </Link>
    }

    function alert_cell(row) {
        const vals = row['findings_summery'];
        const src = (type) => {
            switch (type) {
                case AlertType.FRAUD:
                    return '/alarm.svg';
                case AlertType.POLICY:
                    return '/shield-slash.svg';
                case AlertType.TURNOVER:
                    return '/arrow-swap-horizontal.svg';
                case AlertType.ANOMALY:
                    return '/anomaly.svg';
                default:
                    return;
            }
        };

        return <Link to={`/${entity}/${cycle}/payslips/?id=`+row['id']} style={{textDecoration:'none', display:"flex"}}>  
                    {vals.map((finding_summery, i) =>
                        <CustomizedTooltips key={i} loadContent={() => loadAlertContent(src, finding_summery)}
                                            loadTooltipData={(setDataCallback) => {
                                                loadTooltipData(row['id'], finding_summery?.alert_type, setDataCallback)
                                            }}
                        />
                    )}
               </Link>
    }

    const conditionalRowStyles = [
        {
            when: row => row.seen === true,
            classNames: ['seen-payslip-row']
        },
    ];

    const loadAlertContent = (getImageSource, finding_summery) => {
        return (
            finding_summery?.non_approved_quantity > 0 &&
            <span className='alert-bubble'>
                <img alt='alert' src={getImageSource(finding_summery?.alert_type)}/>
                {finding_summery?.non_approved_quantity} {finding_summery?.alert_type}
            </span>
        )
    }

    const loadTooltipData = (payslipId, alertType, setDataCallback) => {
        return axios.get(`/api/payslips/${payslipId}/findings?alert_type=${alertType}`).then((res) => {
            const details = [];

            for (let finding of res.data)
                details.push(finding.message);

            setDataCallback(details);
        }).catch((err) => {
            console.log(err);
        })
    };

    function approve_cell(row) {
        const payslipId = row['id']
        const val = row['has_findings']

        if (val === 1 && enableApproveBtn) {
            return <div data-track='Approve all' data-track-props={'source-page:report'} className="cell-approved-all" onClick={(e) => {
                e.target.classList.add('pulse')
                approveAll(payslipId, () => {getReports(populate_data, filter, null, null)});
                reloadReportData();
                setTimeout(() => {
                    getFindingCounts(setFindingsCounts);
                }, 500);
            }
        }><img src='/tick-circle.svg' alt='approve'/>Approve</div>
        } else {
            return <div></div>
        }
    }

    function percent_cell(row) {
        let val = row['change_p']
        if (isNaN(val)) {
            return '';
        } else {
            return formatNumber(val.toFixed(1), true);
        }
    }

    function populate_data(data) {
        setIsTableLoading(true);
        setData(data);
        filterText(data, textFilter, alertFilter);
        setIsTableLoading(false);
    }

    const reloadReportData = (controller) => {
        const paramFilter = searchParams.get('f');
        let decodedFilter = filter;
        if (paramFilter) {
            decodedFilter = decodeURI(paramFilter);
            setFilter(decodedFilter);
        }

        getReports(populate_data, decodedFilter, null, controller);
        getFindingCounts(setFindingsCounts);
    };

    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController.current) {
            latestController.current.abort();
        }
    
        // Create a new controller for the current request
        const controller = new AbortController();
        latestController.current = controller;

        reloadReportData(controller);

        getCycleStatus(setCycleStatus);

        return () => {
            // Clean up the controller on component unmount or date change
            controller.abort();
        };
    }, [category, entity, cycle]);

    useEffect(() => {
        filterText(data, textFilter, alertFilter);
    }, [data, textFilter, alertFilter]);

    function filterChanged(e) {
        setFilter(e.target.value);
        if(e.target.value === '' && category !== 'All'){
            getReports(populate_data, '', textFilter, null);
            setSearchParams({'f': ''});
            return;
        }
        getReports(populate_data, e.target.value, textFilter, null);
        setSearchParams({'f': encodeURI(e.target.value)});
    }

    function filterText(newData, newFilter, newAlertFilter) {
        let filteredData = newData?.filter(x => JSON.stringify(Object.values(x)).toLowerCase().indexOf(newFilter) >= 0);

        if(isSummaryTAb){
            filteredData = newData?.filter(x => JSON.stringify(Object.values(x)).toLowerCase().indexOf(newFilter) >= 0)?.filter(x => x?.severity === 1);
        }

        if (newAlertFilter.length > 0) {
            filteredData = filterDataByAlertType(newAlertFilter, filteredData);
        }

        const ids = [];
        let filteredForUniqueItem = filteredData.filter(({ id }) => {
            const exsists = !ids.includes(id);
            ids.push(id);
            return exsists;
        });


        filteredForUniqueItem = filteredForUniqueItem.sort(severitySort).reverse();

        setViewData(filteredForUniqueItem);
        dispatch(setPayslips(filteredForUniqueItem));
        dispatch(setBackToReport(false));
        dispatch(resetPayslipsSeen());
    };

    const filterDataByAlertType = (newAlertFilter, filteredData) => {
        return filteredData.filter(({ findings_summery }) => {
            if (findings_summery.length === 0)
                return false

            return findings_summery.some(finding => {
                return newAlertFilter.indexOf(finding.alert_type) !== -1;
            })
        });
    }

    const handleSort = (column, sortDirection, sortedData) => {
        dispatch(setPayslips(sortedData));
        dispatch(setReportSortState({...{sortId: column?.id, sortDirection: sortDirection}}));
    }

    const navigate = useNavigate();
    const handleRowClicked = (row, event) => {
        navigate(`/${entity}/${cycle}/payslips/?id=${row['id']}`);
        mixpanel.track('Open employee', {'employee name': row['name']});
    };

    return (
        viewData?.length === 0 ? 
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop> 
        :
        <div className="report">
            <div className="report-header">
                <img src='/report page icon.svg' alt='report'/>
                <span className='report-header-txt'>Report</span>
            </div>
            <div>
                <div style={{marginLeft:"32px", height:"32px"}}>
                    <ReportTab filteringCallBack={filterChanged}/>
                </div>
                <div className='sub-header-container report-sub-header-container'>
                    <div className='home-sub-header'>Total Payroll: <span className='home-sub-header-value'>${formatNumber(cycleStatus?.total_gross)}</span></div>
                    <div className='home-sub-header'>Employees: <span className='home-sub-header-value'>{formatNumber(cycleStatus?.total_employees)}</span></div>
                </div>
                <div className="home-row value-container">
                    <ValueSummaryItem numberOfEmployees={cycleStatus?.employees_high} totalChange={cycleStatus?.gross_high / (cycleStatus?.total_gross / 100)} severity={1} sum={cycleStatus?.gross_high} />
                    <ValueSummaryItem numberOfEmployees={cycleStatus?.employees_medium} totalChange={cycleStatus?.gross_medium / (cycleStatus?.total_gross / 100)} severity={2} sum={cycleStatus?.gross_medium} />
                    <ValueSummaryItem numberOfEmployees={cycleStatus?.employees_low} totalChange={cycleStatus?.gross_low / (cycleStatus?.total_gross / 100)} severity={3} sum={cycleStatus?.gross_low} />
                </div>
                <AlertSummary />             
                <div className="report-grid">
                    { isSummaryTAb === false &&
                        <div className="report-filter-panel">
                            <img src='/filter.svg' alt='filter' className='option-default'/>
                            <Select displayEmpty value={filter} onChange={filterChanged} className="report-filter">
                                <MenuItem className='option-design' key="o_" value="">Filter By</MenuItem>
                                {findingsCounts.map((fc, i) => <MenuItem className='option-design' key={"o_"+ i} value={fc[1]}>{fc[1]} ({fc[0]})</MenuItem> )}
                            </Select>
                            <div>
                                {category === 'All' &&
                                    <ExportBtn entity={entity} cycle={cycle} filter={filter} url={`/api/payslips/${entity}/${cycle}/export/?filter=`+encodeURI(filter)}
                                        filename={"CeleryExport" + getCycle(viewData ?  viewData : []) + ".csv"}></ExportBtn>
                                    }  
                            </div>
                            <div className="report-text-filter">
                                <img src='/filter.svg' alt='filter'/>
                                <input className="report-text-filter-input" value={textFilter} onChange={(e) => {
                                    const newFilter = e.target.value.toLowerCase()
                                    setTextFilter(newFilter);
                                }}
                                    placeholder="Search Employee"
                                />
                            </div>
                        </div>
                    }
                    <div className="report-table-wrapper">
                    <DataTable
                        key={sortIdState}
                        columns={columns}
                        data={viewData ? viewData : []}
                        defaultSortFieldId={sortIdState}
                        defaultSortAsc={sortDirectionState === 'asc' ? true : false}
                        theme="default"
                        onSort={handleSort}
                        responsive
                        pagination
                        paginationPerPage={500}
                        paginationRowsPerPageOptions={[500, 1000]}
                        progressPending={isTableLoading}
                        onRowClicked={handleRowClicked}
                        conditionalRowStyles={conditionalRowStyles}
                    />
                    </div>
                </div>
            </div>
        </div>
    );

    function getReports(populateData, filter, textFilter = null, controller = null) {
        const splitFilter = filter.split('Category:')
        let url = ''
        if (splitFilter.length === 2){
            url = `/api/payslips/${entity}/${cycle}/list/?category=`+encodeURIComponent(splitFilter[1])
        } else {
            if(filter !== 'SUMMARY' && filter !== 'All'){
                url =`/api/payslips/${entity}/${cycle}/list/?filter=`+encodeURIComponent(filter)
            }
            else{
                url =`/api/payslips/${entity}/${cycle}/list/`
            }
        }

        if (textFilter){
            if(url) {
                url += '&freesearch='+encodeURIComponent(textFilter)
            }
        }

        const callConfig = controller ? {signal: controller?.signal} : null;

        axios.get(url, callConfig).then((res) => {
            if (res.data){
                populateData(res.data);
            } else {
                populateData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    function getFindingCounts(setFindingsCounts) {
        axios.get(`/api/reports/${entity}/${cycle}/findings/count/`).then((res) => {
            if (res?.data){
                const filtersByCategory = category === CategoryType.All ? res.data : res.data.filter(x => x[2] === category);
                setFindingsCounts(filtersByCategory);
            } else {
                setFindingsCounts([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

function getCycle(data) {
    if (data && data.length) {
        return formatDate(data[0]['cycle']);
    } else {
        return '';
    }
}

function approveAll(payslip_id, refresh_func) {
    mixpanel.track('approve payslip from Report', {'payslip_id': payslip_id})
    axios.post(`/api/payslips/${payslip_id}/approve`).then((res) => {
        refresh_func();
    }).catch((err) => {
        console.log(err)
    });
}

export default Report;