
const useCustomParams = () => {
    const currentPathArray = window?.location?.pathname?.split('/');
    if(currentPathArray.length > 2 && currentPathArray[0] === ""){
        currentPathArray.shift();
    }

    const entity = currentPathArray[0];
    const cycle = currentPathArray[1];

    return { entity, cycle };
}

export default useCustomParams;