import { Autocomplete, Chip, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";

function EmailAutoComplete(props:{open: boolean, setSelectedEmails: any, selectedEmails: Array<string>}) {
    const [knownEmails, setKnownEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');
    
    useEffect(() => {
        function getKnownEmails() {
            axios.get('/api/communication/emails/').then((res) => {
                if (res.data){
                    return setKnownEmails(res.data);
                } else {
                    return setKnownEmails([]);
                }
            }).catch((err: any) => {
                console.log(err);
            })
        }

        getKnownEmails();
    }, []);

    useEffect(() => {
        props?.setSelectedEmails([]);
        setInputValue('');
    }, [props.open]);

    const handleChange = (event: React.ChangeEvent<{}>, value: any, reason: string) => {
        if(reason === 'selectOption') {
            props?.setSelectedEmails([ ...value ]);
        }else if (reason === 'input') {
            setInputValue(value);
            const savingAttempt = value?.includes(' ');
            const valueWithoutSpace = value?.replace(' ', '');
            if (savingAttempt) {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                const isValidEmail = emailRegex.test(valueWithoutSpace);

                if (isValidEmail === false || props?.selectedEmails.includes(valueWithoutSpace)) {
                    setInputValue('');
                    return;
                }
                
                props?.setSelectedEmails([...props?.selectedEmails, valueWithoutSpace]);
                setInputValue('');
            }
        }else if (reason === 'createOption') {
            const enterValue = value[value?.length - 1];
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            const isValidEmail = emailRegex.test(enterValue);

            if (isValidEmail === false) {
                setInputValue('');
                return;
            }
            
            props?.setSelectedEmails([...props?.selectedEmails, enterValue]);
            setInputValue('');
            
        }else if (reason === 'clear') {
            props?.setSelectedEmails([]);
            setInputValue('');
        }
    };

    return (
        <Autocomplete
            className='simple-comment-autocomplete'
            multiple
            freeSolo
            value={props?.selectedEmails}
            inputValue={inputValue}
            onChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
            onInputChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
            options={knownEmails}
            renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                    <Chip
                        key={'c_'+option}
                        label={option}
                        variant="outlined"
                        onDelete={() =>{
                                props?.setSelectedEmails(props?.selectedEmails.filter((e) => e !== option));
                            }
                        }
                    />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} label="" placeholder="Press space or enter to add multiple emails"
                           className='comments-textfield'/>
            )}
        />
    )
}

export default EmailAutoComplete;