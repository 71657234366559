import ReactModal from "react-modal";
import "./CommentEnforcer.css";
import { useDispatch, useSelector } from "react-redux";
import { setCommentEnforcerState } from "../../store/CommentEnforcerSlice";
import { useEffect, useState } from "react";
import axios from "axios";
import PayslipBubble from "../Payslips/PayslipBubble";


const CommentEnforcer = (props : {onClose: any}) => {
    const dispatch = useDispatch();
    
    const open =  useSelector((state: any) => state?.CommentEnforcerReducer?.open);
    const payslipID =  useSelector((state: any) => state?.CommentEnforcerReducer?.payslipID);
    const findingID =  useSelector((state: any) => state?.CommentEnforcerReducer?.findingID);

    const [findings, setFindings] = useState<any[] | null>(null);
    const [comment, setComment] = useState<string | null>(null);

    const [customStyles, setCustomStyles] = useState<any>({
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "664px",
            height: "200px",
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
        overlay: {
            backgroundColor: "#102E442E",
            backdropFilter: "blur(4px) brightness(100%)",
        }
    });

    useEffect(() => {
        if(payslipID){
            axios.get(`/api/payslips/${payslipID}/findings`)
                .then((res) => {
                    if(findingID){
                        const theFinding = res.data.filter((finding: {id: number}) => finding.id === findingID);
                        setFindingsWrapper(theFinding);
                    } else{
                        setFindingsWrapper(res.data.filter((x: any) => x?.severity < 3 && x?.approved !== 1).sort((a: any, b: any) => {
                            const aSeverity = a?.severity;
                            const bSeverity = b?.severity;
                            if(aSeverity < bSeverity) return -1;
                            if(aSeverity > bSeverity) return 1;
                            return 0;
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        return () => {
            setFindingsWrapper(null);
            setComment(null);
        };
    }, [payslipID])

    function setFindingsWrapper(findings: any[] | null) {
        setFindings(findings);
        if (findings && findings.length > 0) {
            customStyles['content']['height'] = (findings.length * 40 + 200) + 'px'
        } else {
            customStyles['content']['height'] = '200px'
            customStyles['content']['height'] = '200px'
        }
        setCustomStyles(customStyles);
    }

    const contentWrapper = () => {
        if(!comment) return '';

        const rawUserData = localStorage.getItem("userData") ?? "{}";
        const userData = JSON.parse(rawUserData);
        const firstName = userData?.first_name;
        const lastName = userData?.last_name;
        const employeeName = `${firstName}, ${lastName}`?.toUpperCase();

        const alerts = findings?.map((finding: any) => {
            return `Alert: ${finding?.message}`;
        }
        )?.join('\n');

        const response = `${employeeName}\n${alerts}\nComments: ${comment}`;
        return response;
    };

    async function approveAlert() {
        try{
            await axios?.post(`/api/payslips/${payslipID}/comment`, {finding: findingID, content: comment, approved: 1, recipients: []});
            close();
        }
        catch(err){
            console.log(err);
            close();
        }
    }

    async function approvePayslip() {
        try{
            await axios.post(`/api/payslips/${payslipID}/approve`)
        }
        catch(err){
            console.log(err);
        }
    }

    async function sendPayslipComment() {
        try{
            await axios?.post(`/api/payslips/${payslipID}/comment`, {finding: null, approved: true, content: comment, recipients: []});
        }
        catch(err){
            console.log(err);
        }
    }

    const onSaveComment = async () => {
        if(findingID){
            approveAlert();
        }else{
            await sendPayslipComment();
            await approvePayslip();
            close();
        }
    }

    function close() {
        props.onClose()
        dispatch(setCommentEnforcerState({open:false}));
    }

    return (
        <ReactModal
        ariaHideApp={false}
        isOpen={open}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={close}
        >
            {findings === null ? <div>loading</div> : 
            <div className={"CommentEnforcerCommentModal-container"}>
                <div className="CommentEnforcerCommentModal-header-row">
                    <div className="comment-label">{findingID ? 'Approve' : 'Approve All'}</div>
                    <img src="/comment-close.svg" alt="close" className="CommentEnforcerCommentModal-btn-x" onClick={close} />
                </div>
                <div>
                    {findings !== null && findings?.map((finding: any) => 
                        <div key={finding?.id} className="CommentEnforcerCommentModal-finding">
                            <PayslipBubble severity={finding?.approved === 1 ? 3 : finding?.severity} shortTxt={true}/>
                            <div className="CommentEnforcerCommentModal-finding-content">{finding?.message}</div>
                        </div>
                    )}
                </div>
                <div>
                    <input className={"CommentEnforcer-textarea"} placeholder="Specify the reason here..." type="text" onChange={e => setComment(e?.target?.value)}/>
                </div>
                <div className="CommentEnforcerCommentModal-btn-container">
                    {
                        (!comment || comment.length < 5) &&
                        <span className={"CommentEnforcer-warning"}>Min 5 characters</span>
                    }
                    {comment && comment?.length >= 5 ?
                        <div className="CommentEnforcer-approve-btn CommentEnforcer-btn-ready" onClick={onSaveComment}><img src='/tick-circle2.svg' alt='approve'/>{findingID ? 'Approve' : 'Approve All'}
                        </div>
                        :
                        <div className="CommentEnforcer-approve-btn CommentEnforcer-btn-gray"><img src='/tick-circle2.svg' alt='approve'/> {findingID ? 'Approve' : 'Approve All'}
                        </div>
                    }
                </div>
            </div>}
        </ReactModal>
    );
}

export default CommentEnforcer;