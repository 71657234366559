import {useEffect, useState} from 'react';
import './HoursBreakdown.css';
import {formatDate, formatNumber} from "../../../utils/PresentationUtils";
import DataTable from "react-data-table-component";
import ExportBtn from "../../ExportBtn/ExportBtn";
import conditionalRowStyles from "../../Dashboard/DashboardUtils/greyTableStyle";
import {useNavigate} from "react-router-dom";
import useCustomParams from "../../../hooks/useCustomParams";
import {useSelector} from "react-redux";

function HoursBreakdown (props){
    const navigate = useNavigate();
    const { entity, cycle } = useCustomParams();
    const [csvExportData, setCsvExportData] = useState([]);
    const [lastCycle, setLastCycle] = useState('');

    const entities = useSelector(state => state?.entityReducer?.entities);
    const selectedEntity = entities?.find(x => x?.id?.toString() === entity?.toString());

    useEffect(() => {
        prepCsvData(props.data);
        loadLastCycle();
    }, [props.data])

    function prepCsvData(data) {
        if (data) {
            let csvData = []
            for (let row of data) {
                let newTopRow = {'name':'', 'dept_name':''}

                for (let key in row) {
                    newTopRow[key] = row[key]
                }

                let sub_rows = newTopRow['departments']
                delete newTopRow['departments']
                csvData.push(newTopRow)
                for (let sub_row of sub_rows){
                    sub_row['name'] = ''
                    csvData.push(sub_row)
                }
            }
            setCsvExportData(csvData)
        } else {
            setCsvExportData([])
        }
    }

    function loadLastCycle()
    {
        setLastCycle(null);
        if (selectedEntity?.cycles === undefined)
            return;

        for (let index = 0; index < selectedEntity.cycles.length; index++) {
            const entity_cycle = selectedEntity.cycles[index];
            if (entity_cycle === cycle && (index + 1) < selectedEntity.cycles.length)
            {
                setLastCycle(selectedEntity.cycles[index+1]);
                break;
            }
        }
    }

    function navigateToOvertimeReport(dept) {
        navigate(`/${entity}/${cycle}/overtime?department=${dept}`);
    }


    const extended_columns = [
        {
            name: 'Department',
            selector: row => row['dept_name'],
            sortable: true,
            cell: row => (
                <span onClick={() => navigateToOvertimeReport(row['dept_name'])} className="dept-column">
                    {row['dept_name']}
                </span>
            )
        },
        {
            name: 'Hours',
            selector: row => row['hours'],
            format: row => formatNumber(row['hours'], false, true),
            sortable: true,
        },
        {
            name: 'Total ($)',
            selector: row => row['total'],
            format: row => formatNumber(row['total'], false, true),
            sortable: true,
        },
        {
            name: 'Average Cost ($)',
            selector: row => row['avg'],
            format: row => formatNumber(row['avg'], false, true),
            sortable: true,
        },
        {
            name: 'Hours',
            selector: row => row['last_cycle_hours'],
            format: row => formatNumber(row['last_cycle_hours'], false, true),
            sortable: true,
        },
        {
            name: 'Total ($)',
            selector: row => row['last_cycle_total'],
            format: row => formatNumber(row['last_cycle_total'], false, true),
            sortable: true,
        },
        {
            name: 'Average Cost ($)',
            selector: row => row['last_cycle_avg'],
            format: row => formatNumber(row['last_cycle_avg'], false, true),
            sortable: true,
        },
    ]

    const ExpandedComponent = ({ data }) => <div className="HoursBreakdownSubTable">
        <DataTable
            columns={extended_columns}
            data={data.departments}
            defaultSortFieldId={0}
            theme="default"
            responsive
            dense
        />
    </div>;

    return (
        <div className="HoursBreakdown">
            <div className='dashboard-component-header'>
                <div id="hours-by-type">Hours By Type</div>
                <ExportBtn data={csvExportData} filename={"CeleryHoursExport.csv"}></ExportBtn>
            </div>
            <div className='cycles-separator-container'>
                <div className='cycles-separator-row-container'>
                    <span className='cycles-separator'>Current Cycle {'(' + formatDate(cycle, false, false, true) + ')'}</span>
                    <span className='cycles-separator'>Last Cycle {lastCycle != null ? '(' + formatDate(lastCycle, false, false, true) + ')' : ''}</span>
                </div>
                <DataTable
                    conditionalRowStyles={conditionalRowStyles}
                    className='hours-breakdown-table'
                    columns={columns}
                    data={props.data}
                    defaultSortFieldId={0}
                    theme="default"
                    responsive
                    dense
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
            </div>
        </div>
        )
}



const columns = [
    {
        name: 'Type',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Hours',
        selector: row => row['hours'],
        format: row => formatNumber(row['hours'], false, true),
        sortable: true,
    },
    {
        name: 'Total ($)',
        selector: row => row['total'],
        format: row => formatNumber(row['total'], false, true),
        sortable: true,
    },
    {
        name: 'Average Cost ($)',
        selector: row => row['avg'],
        format: row => formatNumber(row['avg'], false, true),
        sortable: true,
    },
    {
        name: 'Hours',
        selector: row => row['last_cycle_hours'],
        format: row => formatNumber(row['last_cycle_hours'], false, true),
        sortable: true,
    },
    {
        name: 'Total ($)',
        selector: row => row['last_cycle_total'],
        format: row => formatNumber(row['last_cycle_total'], false, true),
        sortable: true,
    },
    {
        name: 'Average Cost ($)',
        selector: row => row['last_cycle_avg'],
        format: row => formatNumber(row['last_cycle_avg'], false, true),
        sortable: true,
    },
]


export default HoursBreakdown;