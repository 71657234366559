import mixpanel from "mixpanel-browser";
import ReactModal from "react-modal";
import { useEffect, useState } from "react";
import "./UploadNeverFailMessage.css";
import { CircularProgress } from "@mui/material";

function UploadNeverFailMessage(props: {closeDialogHandler: Function})
{
    const [isOpenError, setIsOpenError] = useState(true);
    const [stopPopup, setStopPopup] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStopPopup(true);
        }, 3000);
    }
    , []);

    const dialogStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "664px",
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
        overlay: {
            backgroundColor: "#102E442E",
            backdropFilter: "blur(4px) brightness(100%)",
        }
    };

    const closeDialog = () => {
        mixpanel.track('Upload is taking longer');
        setIsOpenError(false);
        props.closeDialogHandler(false);
    };

    return (
        <div className="upload-info-msg-popup">
            <ReactModal
                ariaHideApp={false}
                isOpen={isOpenError}
                style={dialogStyles}
                shouldCloseOnOverlayClick={false}
            >
                <div className="info-modal">
                    <h1 className="info-modal-header">Thanks for your patience!</h1>
                    <img src={"/close-icon.svg"} className="x-icon"
                         onClick={() => closeDialog()} alt='close'/>
                </div>
                <CircularProgress style={{marginTop: '10px', marginLeft: '50%', display:`${stopPopup ? "none" : ''}`}} color="inherit"/>
                <p className="info-massage" style={{display:`${!stopPopup ? "none" : ''}`}}>
                    Your files are being processed and will be available soon. 
                    We'll notify you once everything is ready. Thank you for your patience!
                </p>
                <button className="info-massage-btn" onClick={() => closeDialog()}>Close</button>
            </ReactModal>
        </div>
    );

}

export default UploadNeverFailMessage;