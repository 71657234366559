import {Tabs, Tab, Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {setItemsToOmit, setCategoryType} from '../../../store/reportSlice';
import { configPerTabIndex } from './columnConfig';
import { CategoryType } from './categoryType';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useCustomParams from '../../../hooks/useCustomParams';

const ReportTab = (props: {filteringCallBack: Function}) => {
    const category = useSelector((state: any) => state?.reportStateReducer?.filter?.categoryType);

    const dispatch = useDispatch();
    
    const { entity, cycle } = useCustomParams();

    const handleTabChange = (event: any, value: string) => {
        dispatch(setItemsToOmit(configPerTabIndex[event?.target?.id as any]));

        //@ts-ignore
        dispatch(setCategoryType(value === 'All' ? 'All' as CategoryType : (value !== '' || value !== 'SUMMARY' ? value.substring(9) as CategoryType : 'SUMMARY' as CategoryType)));
        const calbackValue = {target: {value: value}};
        props.filteringCallBack(calbackValue);
    };

    const selectedTab = category === 'All' ? 'All' : (category === 'SUMMARY' || category === "" ?  'SUMMARY' : 'Category:'+category);

    const [earnings, setEarnings] = useState([]);
    const [bonuses, setBonuses] = useState([]);
    const [technical, setTechnical] = useState([]);

    useEffect
    (() => {
        function getFindingCounts() {
            axios.get(`/api/reports/${entity}/${cycle}/findings/count/`).then((res: any) => {
                if (res?.data){
                    setEarnings(res?.data?.filter((item: any) => item[2] === 'Earnings and Hours'));
                    setBonuses(res?.data?.filter((item: any) => item[2] === 'bonuses & One off payment'));
                    setTechnical(res?.data?.filter((item: any) => item[2] === 'Technical information'));
                }
            }).catch((err: any) => {
                console.log(err);
            })
        }

        getFindingCounts();
    }, []);

    return(
        <Box>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0296b6",
                  },
                }}
                sx={{
                    ".Mui-selected": {
                        color: `#0e3c48 !important`,
                        fontWeight: 700,
                        fontSize: '16px',
                        height: '32px',
                    },
                    ".MuiTab-root:not(.Mui-selected)": {
                        color: `#67748e !important`,
                        fontWeight: 500,
                        fontSize: '16px',
                        height: '32px',
                    }
                }}
            >
                <Tab label="Alerts Summary" id='0' value ='SUMMARY' data-track='Alert summary tab' />
                <Tab label="All Employees" id='1' value={'All'} data-track='All employees tab' />
                <Tab className={`${earnings?.length === 0 ? 'light-color' : ''}`} label="Earnings & Hours" id='2' value='Category:Earnings and Hours' data-track='Earnings and hours tab' />
                <Tab className={`${bonuses?.length === 0 ? 'light-color' : ''}`} label="One-Off and Bonuses" id='3' value ='Category:bonuses & One off payment' data-track='One off and bonuses tab' />
                <Tab className={`${technical?.length === 0 ? 'light-color' : ''}`} label="Technical Information " id='4' value ='Category:Technical information' data-track='Technical information tab' />
            </Tabs>
        </Box>
    )
}

export default ReportTab;