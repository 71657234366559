import {useDispatch, useSelector} from "react-redux";
import { useState } from "react";
import {setAlertTypesFilter} from "../../store/reportSlice";

const CategoryAlerts = (props: {payslips: any, type: string, text: string}) => {
    const dispatch = useDispatch();

    const items = props.payslips?.filter((x:any) => x?.severity === 1)?.map((x:any) => x?.findings_summery)?.flat().filter((x:any) => x?.alert_type === props?.type);
    const closed: number = items?.reduce((accumulator: number ,item: any) => accumulator += item?.approved_quantity, 0);
    const open: number = items?.reduce((accumulator: number ,item: any) => accumulator += item?.non_approved_quantity, 0);
    const imgSrc = `/${props?.text?.replace(' ', '-')}.svg`;
    const alertFilter: string[] = useSelector((state: any) => state?.reportStateReducer?.filter?.alertTypesFilter);
    const [isFiltered, setIsFiltered] = useState(alertFilter?.includes(props?.type));

    const addAlertTypeFilter = () => {
        if(alertFilter?.indexOf(props.type) === -1)
        {
            const newAlertFilter = [...alertFilter, props.type];
            dispatch(setAlertTypesFilter(newAlertFilter));
        }
    }

    const removeAlertTypeFilter = () => {
        const index = alertFilter.indexOf(props.type, 0);
        if(alertFilter?.indexOf(props.type) !== -1)
        {
            const newAlertFilter = [...alertFilter];

            newAlertFilter.splice(index, 1);
            dispatch(setAlertTypesFilter(newAlertFilter));
        }
    }

    const filterAlerts = () => {
        if(isFiltered)
            removeAlertTypeFilter();
        else
            addAlertTypeFilter();

        setIsFiltered(!isFiltered);
    }

    let classes = isFiltered ? "category-alerts selected-category-alert" : "category-alerts";
    if (closed + open === 0)
        classes += " category-alerts-unClickable";

    return (
        <div className={classes} onClick={filterAlerts}>
            <img alt={`${props?.text}`} src={imgSrc}/>
            <div>
                <div>
                    <span className="category-alerts-header-first-part" style={{color: `${open === 0 ? "#1ACEA3" : ""}`}}>{closed}</span>
                    
                    <span className="category-alerts-header-second-part" style={{color: `${open === 0 ? "#1ACEA3" : ""}`}}>&nbsp;/&nbsp;{closed + open}</span>
                </div>
                <div>{props?.text}</div>
            </div>
        </div>
    );
}

export default CategoryAlerts;