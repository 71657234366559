import './SidebarItem.css';
import {useMatch, useResolvedPath, Link} from "react-router-dom";
import { useSelector } from 'react-redux';


function SidebarItem (props: any){
    let resolved = useResolvedPath(props.path);
    let match = useMatch({ path: resolved?.pathname, end: true });
    const toggle = useSelector((state: any) => state.sidePanelToggle);

    let classNames = "sidebar-item"
    if (props.disabled){
        classNames += " disabled"
    }
    if (match) {
        classNames += " selected"
    }

    return (
      <div>
        <Link
          to={props.path}
          className={classNames}
          data-testid="sidebar-item"
          reloadDocument={props.path.startsWith("#")}
          data-track={(toggle?.state ? toggle?.state : props.text) + ' page'}
        >
          {props.icon && <div className="img-box">{props.icon}</div>}
          <span className='side-bar-item-text'>
            {toggle?.state ? "" : props.text}
            {props.beta && (
                <img className="sidebar-item-beta-wrapper" src="/celery-removebg-preview.png" title="New" />
            )}
          </span>
        </Link>
      </div>
    );
}

export default SidebarItem;