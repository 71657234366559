import { PayslipCellDropdownItemType } from "./PayslipCellDropdownLogic";

const PayslipCellDropdownItem = (props:{itemType: PayslipCellDropdownItemType, pastTense?: boolean}) => {
    switch (props?.itemType) {
        case PayslipCellDropdownItemType.Review:
            return (<span className="PayslipCellDropdownItem-span"><img src="/Danger-Circle.svg" alt={PayslipCellDropdownItemType.Review}/><span>{PayslipCellDropdownItemType.Review}</span></span>);
        case PayslipCellDropdownItemType.Approve:
            return (<span className="PayslipCellDropdownItem-span"><img src="/Check Circle.svg" alt={PayslipCellDropdownItemType.Approve}/><span>{props?.pastTense ? "Approved" : PayslipCellDropdownItemType.Approve}</span></span>);    
        case PayslipCellDropdownItemType.Deny:
            return (<span className="PayslipCellDropdownItem-span"><img src="/Close-Circle.svg" alt={PayslipCellDropdownItemType.Deny}/><span>{props?.pastTense ? "Denied" : PayslipCellDropdownItemType.Deny}</span></span>);
        case PayslipCellDropdownItemType.Comment:
            return (<span className="PayslipCellDropdownItem-span"><img src="/Chat-Round.svg" alt={PayslipCellDropdownItemType.Comment}/><span>{PayslipCellDropdownItemType.Comment}</span></span>);
        case PayslipCellDropdownItemType.History:
            return (<span className="PayslipCellDropdownItem-span"><img src="/Diagram-Up.svg" alt={PayslipCellDropdownItemType.History}/><span>{PayslipCellDropdownItemType.History}</span></span>);
    }
};

export default PayslipCellDropdownItem;