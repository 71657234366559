import TestValidation from "./testValidation";

const TestContainer = (testProp) => {

    const headerText = testProp?.test_name?.replace(/([A-Z])/g, ' $1').trim();

    let hidden = false;

    if (testProp.filter && headerText != testProp.filter) {
        hidden = true;
    } else if (!testProp.filter && headerText.includes('Custom')) {
        hidden = true;
    }

    return (
        <div hidden={hidden}>
            <h4>{headerText}</h4>
            {testProp?.validations?.map((validation, i) => (
                <TestValidation {...validation} key={i}/>
            ))}
        </div>
    );
}

export default TestContainer;