import './fieldsGrid.css';
import { useEffect, useState} from "react";
import { Field } from "./Models/field";
import { ConfirmationDialog, CANCEL_CONFIRMATION } from "../ConfirmationDialog/confirmationDialog";
import axios from "axios";
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridSortItem} from '@mui/x-data-grid';
import useCustomParams from '../../hooks/useCustomParams';


function FieldsGrid() {

    const [fieldColumns, setFieldColumns] = useState<GridColDef[]>([]);
    const [dataInProcess, setDataInProcess] = useState(false);
    const [rows, setRows] = useState<Field[]>([]);
    const [openPayslipsDialog, setOpenPayslipsDialog] = useState(false);
    const [openFieldsDialog, setOpenFieldsDialog] = useState(false);

    const { entity } = useCustomParams();
    
    useEffect(() => {
        loadFieldColumnsDefinition().then((result) => {
            setFieldColumns(result)
        });
        loadData().then((result) => {
            if(result !== undefined)
                setRows(result);
            else
                setRows([]);
        });
    }, [entity]);


    const loadData = async () => {
        const response = await axios.get(`/api/system/fields/${entity}`);
        return await response.data;
    };

    const loadFieldColumnsDefinition = async () => {
        const response = await axios.get(`/api/system/fields/${entity}/columns_definition`);
        for(const key in response.data)
        {
            let column_def = field_columns_definition.find(item=> item.field === key)
            if(column_def !== undefined)
                column_def.valueOptions = response.data[key];
        }

        return await field_columns_definition;
    };

    let field_columns_definition = [
        { field: 'id', display: false },
        { field: 'field_display_name', headerName: 'Display Name', editable: true, width: 150 },
        { field: 'field_source_id', headerName: 'Source Name', editable: true, width: 150 },
        { field: 'field_system_id', headerName: 'System Name', editable: true, width: 150 },
        { field: 'field_type', type: 'singleSelect', valueOptions: [], headerName: 'Type', editable: true, width: 100 },
        { field: 'field_section', type: 'singleSelect', valueOptions: [], headerName: 'Section', editable: true, width: 150 },
        { field: 'field_sub_section', type: 'singleSelect', valueOptions: [], headerName: 'Sub Section', editable: true, width: 150 },
        { field: 'ignore', type: 'boolean', headerName: 'Hidden', editable: true, width: 100 },
        { field: 'field_display_order', headerName: 'Display Order', editable: true, width: 100 }
    ];

    const defaultSortModel: GridSortItem[] = [
        { field: 'id', sort: 'asc' }
    ];

    const handleProcessRowUpdate = (updatedRow: any) => {
        const row_index = rows.findIndex((row) => row.id === updatedRow.id);
        const updatedRows = [...rows];

        updatedRows[row_index] = updatedRow;
        setRows(updatedRows);

        return updatedRow;
    };

    const clearFieldsConfirmation = async () => {
        setOpenFieldsDialog(true);
    };

    const clearFields = async () => {
        const clearFieldsData = async() => {
            return await axios.delete(`/api/system/fields/${entity}/clear_data`);
        };

        clearFieldsData().then(() => {
            setRows([])
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleCloseClearFieldsDialog = (value: string) => {
        setOpenFieldsDialog(false);

        if (value === CANCEL_CONFIRMATION)
            return;

        clearFields().then(() => {

        });
    };

    const saveFields = async () => {
        const saveData = async(data: any) => {
            return await axios.patch(`/api/system/fields/${entity}`, data);
        };

        setDataInProcess( true);
        saveData(rows).then((response)=> {
            if (response.status !== 200)
                console.log(response.statusText);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setDataInProcess( false);
        })
    };

    const clearPayslipsConfirmation = async () => {
        setOpenPayslipsDialog(true);
    };

    const clearPayslips = async () => {
        const clearPayslipsData = async() => {
            return await axios.delete(`/api/payslips/${entity}/clear_data`);
        };

        clearPayslipsData().catch((err) => {
            console.log(err);
        })
    };

    const handleCloseClearPayslipsDialog = (value: string) => {
        setOpenPayslipsDialog(false);

        if (value === CANCEL_CONFIRMATION)
            return;

        clearPayslips().then(() => {

        });
    };

    return (
        <div className='field-data-grid-container'>
            <Box
                sx={{
                    height: 500,
                    width: '80%',
                    backgroundColor: '#f3f3f3',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <DataGrid rows={rows}
                          columns={fieldColumns}
                          sortModel={defaultSortModel}
                          columnVisibilityModel={{ id: false }}
                          processRowUpdate={handleProcessRowUpdate}
                          editMode="row"
                />
            </Box>
            <div className='global-fields-bth-container'>
                <div className='save-fields-btn'
                     onClick={saveFields}
                     style={{
                         cursor: dataInProcess ? 'not-allowed' : 'pointer',
                         opacity: dataInProcess ? 0.5 : 1,
                     }} >Save</div>
                <div className='clear-fields-btn'
                     onClick={clearFieldsConfirmation}
                     style={{
                         cursor: dataInProcess ? 'not-allowed' : 'pointer',
                         opacity: dataInProcess ? 0.5 : 1,
                     }} >Clear Fields</div>
                <div className='clear-payslips-btn'
                     onClick={clearPayslipsConfirmation}
                     style={{
                         cursor: dataInProcess ? 'not-allowed' : 'pointer',
                         opacity: dataInProcess ? 0.5 : 1,
                     }} >Clear Payslips</div>
            </div>
            <ConfirmationDialog
                onClose={handleCloseClearPayslipsDialog}
                content="Are you sure you want to delete ALL payslips data?"
                open={openPayslipsDialog}
            />
            <ConfirmationDialog
                onClose={handleCloseClearFieldsDialog}
                content="Are you sure you want to delete ALL fields?"
                open={openFieldsDialog}
            />
        </div>
    );
}

export default FieldsGrid;