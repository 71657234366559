import './PayslipSheet.css';
import {formatNumber, formatDate} from "../../utils/PresentationUtils";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TechnicalDetails from './TechnicalDetails/TechnicalDetails';
import { useDispatch, useSelector } from 'react-redux';
import {togglePayslipSheetSection} from "../../store/PayslipSlice";
import mixpanel from "mixpanel-browser";
import AlertIcon from './AlertIcon/AlertIcon'; 
import { useEffect } from 'react';
import { useState } from "react";
import FindingModal from "../FindingModal/FindingModal";
import SimpleCommentModal from "../SimpleCommentModal/SimpleCommentModal";
import PayslipCellDropdown from "./PayslipCellDropdown/PayslipCellDropdown";
import axios from 'axios';


function PayslipSheet(props){
    const dispatch = useDispatch();

    const openSections = useSelector((state) => state?.PayslipsViewReducer?.payslipSheetOpenSections);

    const [findingModalOpen, setFindingModalOpen] = useState(false);
    const [modalFinding, setModalFinding] = useState(null);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [commentPayslipId, setCommentPayslipId] = useState(null);
    const [commentFindingId, setCommentFindingId] = useState(null);

    useEffect(() => {
        if(props?.clickedId === "-1") return;

        const targetElement = document?.querySelector(`.payslip-sheet-technical-row[kid="${props?.highlightID}"]`) ??
                              document?.querySelector(`.payslip-sheet-row[kid="${props?.highlightID}"]`);
        const categoryType = targetElement?.getAttribute('category');
        
        if (targetElement && categoryType) {
            const isCategoryClose = openSections?.find(x => x?.name === categoryType)?.open === false;
            if(isCategoryClose){
                const accordion = document?.querySelector(`[categoryType="${categoryType}"]`);
                accordion?.click();
            }
      
            if(isCategoryClose || isVisible(targetElement) === false){
                setTimeout(() => {
                    const targetElement = document?.querySelector(`.payslip-sheet-technical-row[kid="${props?.highlightID}"]`) ??
                                          document?.querySelector(`.payslip-sheet-row[kid="${props?.highlightID}"]`);
                    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 300);
            }  
        } else {
          console.log('Accordion or target element not found');
        }
        props?.setClicked("-1");
    }, [props?.clickedId]);

    function onMouseEnter(e) {
        let id = e.currentTarget.getAttribute('kid');
        if (id){
            const field = props?.fields?.find((x) => x?.field_display_name === id);
            const fieldRow = field?.field_display_name;
            props?.setHighlight(fieldRow);
        }
    };

    function onMouseLeave(e) {
        props?.setHighlight("-1");
    }

    function onFindingClicked(finding) {
        mixpanel.track('Clicked More Info', finding);
        setModalFinding(finding)
        setFindingModalOpen(true);
    }

    function renderCategory(data, findings, warnings, fields, category){
        let isOPen = openSections?.filter(x => x?.name === category)[0]?.open;

        if (typeof data != 'object'){
            return (<div>No Payslip</div>)
        }

        let data_keys = new Set(Object.entries(data).filter(([k, v] ,i) => v).map(([k, v] ,i) => k))
        let finding_fields = new Set(findings?.map(x => x?.field_name))
        let fields_dict = {}
        let findings_translated_id = {}
        let warnings_translated_id = {}

        let relevant_fields = fields.filter(field => field.field_section === category && (data_keys.has(field.field_source_id) || finding_fields.has(field.field_source_id)))

        let field_groups = {}

        const fieldCategoryMap = {};
        fields.map(x => fieldCategoryMap[x.field_source_id] = x.field_section); 
        fields.map(x => fieldCategoryMap[x.field_system_id] = x.field_section);

        for (let field of relevant_fields) {
            if (!(field.field_display_name in field_groups)) {
                field_groups[field.field_display_name] = []
            }
            let field_type = field.field_type !== 'Data' ? field.field_type : 'Total'

            let isInData = field.field_source_id in data_keys && data[field.field_source_id] > 0;
            
            if (field.field_type !== 'SectionTotal') {
                if (field_groups[field.field_display_name] && field_groups[field.field_display_name][field_type]){
                    if (isInData) {
                        field_groups[field.field_display_name][field_type] = field.field_source_id
                        field_groups[field.field_display_name]['field_display_order'] = field.field_display_order
                        field_groups[field.field_display_name]['field_value_type'] = field.field_value_type
                    }
                } else {
                    field_groups[field.field_display_name][field_type] = field.field_source_id
                    field_groups[field.field_display_name]['field_display_order'] = field.field_display_order
                    field_groups[field.field_display_name]['field_value_type'] = field.field_value_type
                }
            }
            
            if (field.field_source_id in fields_dict){
                if (isInData) {
                    fields_dict[field.field_source_id] = field
                    fields_dict[field.field_system_id] = field 
                }
            } else {
                fields_dict[field.field_source_id] = field
                fields_dict[field.field_system_id] = field
            }
        }

        if (findings){         
            for (let finding of findings) {
                if (fields_dict[finding?.field_name]){
                    findings_translated_id[fields_dict[finding?.field_name]?.field_source_id] = finding
                }else{
    
                }
            }
        }

        if (warnings){
            for (let warning of warnings) {
                if (fields_dict[warning?.field_name]){
                    warnings_translated_id[fields_dict[warning?.field_name]?.field_source_id] = warning
                }else{

                }
            }
        }

        let section_total = fields.find(field => field.field_type === 'SectionTotal' && field.field_section === category);
        let section_rate = fields.find(field => field.field_type === 'SectionRate' && field.field_section === category);
        let section_amount = fields.find(field => field.field_type === 'SectionAmount' && field.field_section === category);

        const numberOfOpenIssuesForCategory = new Set(findings?.filter(finding => fieldCategoryMap[finding?.field_name] === category && finding?.approved === 0).map(x => x?.field_name))?.size;

        if (Object.keys(field_groups).length === 0){
            return <div key={category + '_C'}></div>;
        }

        function childClicked(event) {
            event.stopPropagation(); // This stops the click event from propagating to the parent and so not closing the category!
        }

        return (
            <div className="payslip-sheet-container" key={category + '_C'}>
                <Accordion defaultExpanded={isOPen} categoryType={category} onClick={() => dispatch(togglePayslipSheetSection(category))} className="payslip-sheet-accordion" key={`${Math.random() * 100}`}>
                    <AccordionSummary className="payslip-sheet-accordion-summary"
                        expandIcon={<img className="sheet-summary-icon" src='/expandIcon.svg' alt='open section'/>}
                    >
                        <div className='payslip-sheet-accordion-text'>{category}{numberOfOpenIssuesForCategory > 0 && props.compareState && <AlertIcon numberOfItems={numberOfOpenIssuesForCategory}/>}</div>
                    </AccordionSummary>
                    {category === 'Technical Information' ?
                        <TechnicalDetails
                            refresh={props?.refresh}
                            fields={props.fields} 
                            highlightID={props.highlightID} 
                            field_groups={field_groups} 
                            data={data}
                            employee_info={props.employee_info}
                            findings_translated_id={findings_translated_id}
                            warnings_translated_id={warnings_translated_id}
                            setHighlight={props.setHighlight}
                            openDialog={props?.openDialog}
                            enableApproveBtn={props?.enableApproveBtn ?? true}
                            category={category}/>
                        :
                        <AccordionDetails style={{padding:0}} onClick={childClicked}>
                            <table className="payslip-sheet-table">
                                <thead>
                                <tr className="PayslipSheetHeaders">
                                    <th className="column"></th>
                                    <th className="column">Hours</th>
                                    <th className="column">Rate</th>
                                    <th className="column">Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(field_groups).sort(function(a,b){return field_groups[a]?.field_display_order -field_groups[b]?.field_display_order}).map(field_name => {
                                        let field_amount = field_groups[field_name]['Amount']
                                        let field_rate = field_groups[field_name]['Rate']
                                        let field_total = field_groups[field_name]['Total']
                                        if (field_amount || field_rate || field_total) {
                                            return renderLine(field_name, field_amount, field_rate, field_total,
                                                data, findings_translated_id, warnings_translated_id, category)
                                        }
                                    })
                                }
                                {
                                    section_total &&
                                    renderLine(section_total.field_display_name, section_amount?.field_source_id,
                                                section_rate?.field_source_id, section_total.field_source_id,
                                                data, findings_translated_id, warnings_translated_id, category)
                                }
                                </tbody>
                            </table>
                        </AccordionDetails>
                    }
                </Accordion>
            </div>
        )
    }

    function getClasses(baseClass, finding, warning, highlightID) {
        let newClasses = baseClass
        if (finding) {
            if (finding.approved === 0 && finding.severity === 1){
                newClasses += ' review';
            }

            if (finding.approved === 1 && finding.severity === 1){
                newClasses += ' approved';
            }

            if (finding.approved === 3 && finding.severity === 1){
                newClasses += ' denied';
            }

            if (finding?.id == highlightID){
                newClasses += ' MarkingHighlight'
            }
        }
        else{
            newClasses += ' disabled-cell'
        }

        return newClasses
    }

    function renderLine(display_name, amount_field, rate_field, total_field, fields,
                        findings_translated_id, warnings_translated_id, category) {
        let amount_value = amount_field ? (fields[amount_field] || '') : ''
        let rate_value = rate_field ? (fields[rate_field] || '') : ''
        let total_value = total_field ? (fields[total_field] || '') : ''
        let amount_finding = findings_translated_id[amount_field]
        let rate_finding = findings_translated_id[rate_field]
        let total_finding = findings_translated_id[total_field]
        let amount_warning = warnings_translated_id[amount_field]
        let rate_warning = warnings_translated_id[rate_field]
        let total_warning = warnings_translated_id[total_field]

        let isApprovedArray = [];

        Object.keys(findings_translated_id).forEach(function(key) {
            findings_translated_id[key]?.approved > 0 ? isApprovedArray.push(true) : isApprovedArray.push(false);
        });

        let commentText = `${amount_finding?.message ? amount_finding?.message + "\n" : ""} ${rate_finding?.message ? rate_finding?.message + "\n" : ""} ${total_finding?.message ? total_finding?.message + "\n" : ""}`;
        if(commentText?.length < 3) commentText = null;

        const isHovered = `${display_name === props.highlightID ? "hovered" : ""}`;

        return (
            <tr className={`payslip-sheet-row ${isHovered}`} key={display_name} kid={props?.findings === null ? "" : display_name} category={category}
                onMouseEnter={e => onMouseEnter(e)} onMouseLeave={e => onMouseLeave(e)}>
                <td className="row-key">{display_name}
                    {
                        display_name === 'Average Rate' &&
                        <span className="PayslipSheetInfoAstrix" title={"Average is based on working hours"}>*</span>
                    }
                </td>
                <td className={getClasses('cell', amount_finding, amount_warning)}>
                    <div className='payslip-cell-container'>
                        <span className='payslip-cell-span'>{formatNumber(amount_value, false, false, false, false, false, true, 2)}</span>
                        {props?.showApproveBtn && <PayslipCellDropdown {...{finding: amount_finding, refresh: props?.refresh, onFindingClickedPrefilled: () => onFindingClicked({'payslip_id': amount_finding?.payslip_id, 'field_name': amount_finding?.field_name, 'field_display_name': display_name}), openCommentPopup: setIsCommentModalOpen, setCommentPayslipId, setCommentFindingId}}/>}               
                    </div>
                </td>
                <td className={getClasses('cell', rate_finding, rate_warning)}>
                    <div className='payslip-cell-container'>
                        <span className='payslip-cell-span'>{formatNumber(rate_value, false, false, false, false, false, true, 2)}</span>
                        {props?.showApproveBtn && <PayslipCellDropdown {...{finding: rate_finding, refresh: props?.refresh, onFindingClickedPrefilled: () => onFindingClicked({'payslip_id': rate_finding?.payslip_id, 'field_name': rate_finding?.field_name, 'field_display_name': display_name}), openCommentPopup: setIsCommentModalOpen, setCommentPayslipId, setCommentFindingId}}/>} 
                    </div>
                </td>
                <td className={getClasses('cell', total_finding, total_warning)} kid={total_finding ? total_finding.id : -1}>
                    <div className='payslip-cell-container'>
                        <span className='payslip-cell-span'>{formatNumber(total_value, false, false, false, false, false, true, 2)}</span>
                        {props?.showApproveBtn && <PayslipCellDropdown {...{finding: total_finding, refresh: props?.refresh, onFindingClickedPrefilled: () => onFindingClicked({'payslip_id': total_finding?.payslip_id, 'field_name': total_finding?.field_name, 'field_display_name': display_name}), openCommentPopup: setIsCommentModalOpen, setCommentPayslipId, setCommentFindingId}}/>} 
                    </div>
                </td>
            </tr>
        )
    }

    const dataWithFormatedDates = (data) => {
        const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
        const dataForManipulation = {...data};
        for (const [key, value] of Object.entries(dataForManipulation)) {
            if (pattern.test(value)){
                dataForManipulation[key] = formatDate(value);
            }
        }
        return dataForManipulation;
    };
    
    const mostSevereFindings = props?.findings?.filter(x => x?.severity === 1);

    async function sendAlertComment(comment, emails) {
        try{
            mixpanel.track('add alert comment', {'payslip_id': commentPayslipId, 'action': "addComment", "finding": commentFindingId});
            await axios?.post(`/api/payslips/${commentPayslipId}/comment`, {finding: commentFindingId, content: comment, recipients: emails?? []});
            props.refresh();
        }
        catch(err){
            console.log(err);
        }
    }

    return (
        <>        
            <div className="payslip-sheet" data-testid="PayslipSheet">
                <div className="payslip-sheet-title">{props?.title}{props?.btn}</div>
                {
                    ['Technical Information', 'Earnings', 'Deductions', 'Contributions', 'Other'].map((category) =>
                    renderCategory(dataWithFormatedDates(props.data), mostSevereFindings, props.warnings, props.fields, category))
                }
                <FindingModal name={props.name} isOpen={findingModalOpen} setOpen={setFindingModalOpen} finding={modalFinding}></FindingModal>
                <SimpleCommentModal open={isCommentModalOpen} setIsModalOpen={setIsCommentModalOpen} apiCall={(comment, emails) => sendAlertComment(comment, emails)}/>
            </div>
        </>
    )
}


export default PayslipSheet;

function isVisible (ele) {
    const { top, bottom } = ele.getBoundingClientRect();
    const vHeight = (window.innerHeight || document.documentElement.clientHeight);
  
    return (
      (top > 0 || bottom > 0) &&
      top < vHeight
    );
}