import './HomeNewChartsSegment.css';
import {Bar, BarChart, LabelList, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatNumber} from "../../utils/PresentationUtils";
import { ChartData } from '../HomeNew/HomeNewObjects';
import {Fragment} from "react";

function HomeNewChartsSegment(props: {chartData: ChartData[]}){
    return (
        <Fragment>
            <div className="HomeNewChartLineWrapper">
                <div className="HomeNewChartLineLabel"><p style={{
                    marginLeft: '10px',
                    marginBottom: '2px',
                    marginTop: '10px'
                }}>Avg. Rate ($)</p></div>
                <ResponsiveContainer height={200} className='HomeNewChartLineGraph'>
                    <LineChart data={props.chartData} margin={{left: 30, right: 30, top: 10, bottom: 30}}>
                        <XAxis dataKey="date"  hide={true}/>
                        <YAxis yAxisId={1} width={150}
                               tickFormatter={(value) => {
                                   return formatMoney(value, false)
                               }} hide={true} domain={getGraphDomain(props.chartData, 'avg_rate', 1.1, 0.90)}/>
                        <Tooltip formatter={(value: any, name, props) => {
                            return formatMoney(value)
                        }}/>
                        <Line yAxisId={1} dataKey="avg_rate" stroke="#02BBE3" strokeWidth={3}
                              name={'Avg. Rate'}>
                            <LabelList dataKey="avg_rate" fontSize={16} position={'top'} offset={10} fill={'black'}
                                       formatter={(value: any) => {
                                           return formatMoney(value, false)
                                       }}></LabelList>
                        </Line>
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div style={{marginLeft: '100px', width: '90%'}}>
                <ResponsiveContainer height={400} className='HomeNewChartBarGraph'>
                    <BarChart data={props.chartData}>
                        <XAxis dataKey="date" tickFormatter={(value) => {
                            return formatDate(value)
                        }}/>
                        <YAxis yAxisId={1} width={100}
                               tickFormatter={(value) => {
                                   return formatMoney(value, false)
                               }} hide={true}
                               domain={getGraphDomain(props.chartData, 'payroll', 1.2, 0)}/>
                        <YAxis yAxisId={2} width={100} orientation="right"
                               tickFormatter={(value) => {
                                   return formatNumber(value)
                               }} hide={true}
                               domain={getGraphDomain(props.chartData, 'hours', 1.5, 0)}/>
                        <Legend/>
                        <Bar yAxisId={1} dataKey="payroll" fill="#005A73" name={"Payroll"}>
                            <LabelList dataKey="payroll" fontSize={16} position={'top'} fill={'black'}
                                       formatter={(value: any) => {
                                           return formatMoney(value)
                                       }}></LabelList>
                        </Bar>
                        <Bar yAxisId={2} dataKey="hours" fill="#1ACEA3" name={"Hours"}>
                            <LabelList dataKey="hours" fontSize={14} position={'top'} fill={'black'}
                                       formatter={(value: any) => {
                                           return formatMoney(value, false)
                                       }}></LabelList>
                        </Bar>

                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Fragment>
    )
}

function formatMoney(money: number, showCurrency = true) {
    let baseString = ''

    if (showCurrency) {
        baseString = '$'
    }

    if (money > 9999) {
        return baseString + Math.round(money / 1000).toLocaleString() + 'K';
    } else {
        return baseString + Math.round(money).toLocaleString();
    }

}


function getGraphDomain(data: any, key: string, top_mult: number, bottom_mult: number) {
    if (!data) {
        return [0, 0]
    }
    let maxValue = undefined
    let minValue = undefined

    for (let row of data) {
        let value = row[key]
        if (maxValue === undefined || value > maxValue) {
            maxValue = value
        }
        if (minValue === undefined || value < minValue) {
            minValue = value
        }
    }

    return [minValue * bottom_mult, maxValue * top_mult]
}

function formatDate(d: Date) {
    return new Date(d).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
}


export default HomeNewChartsSegment;