export function calcGrossMargin(totalPayroll: number, totalHours: number, avgBillingHours:number) {
    return ((totalHours * avgBillingHours) - totalPayroll) / (totalHours * avgBillingHours) * 100
}

export function calcAvgGrossMarginPerHour( totalPayroll: number, totalHours: number, avgBillingHours:number) {
    return((totalHours * avgBillingHours) - totalPayroll) / totalHours
}

export function calcPPD(totalPayroll: number, totalHours: number, census:number, frequency:number) {
    return (totalPayroll / totalHours) * ((totalHours / census) / frequency)
}

export function calcHPPD(totalPayroll: number, totalHours: number, census:number, frequency:number) {
    return (totalHours/census)/frequency
}

export function calcChangePercent(currValue: number, lastVale: number){
    return ((currValue - lastVale) / lastVale) * 100
}