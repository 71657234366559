import {useEffect, useState} from 'react';
import './PageHeader.css';
import {formatDate} from "../../utils/PresentationUtils";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedCycle} from '../../store/entitySlice';
import useCustomParams from '../../hooks/useCustomParams';
import Notification from './Notification';
import { PiGift } from "react-icons/pi";


function PageHeader (props: any){
    const [cycles, setCycles] = useState([]);
    const { entity, cycle } = useCustomParams();
    const dispatch = useDispatch();

    const entities = useSelector((state: any) => state?.entityReducer?.entities);

    function OnCycleChanged(event: any) {
        dispatch(setSelectedCycle(event?.target?.value));
    }
    
    useEffect(() => {
        if (entities?.length > 0) {
            const selectedEntityData = entities?.find((x: any) => x?.id?.toString() === entity?.toString());
            if (selectedEntityData?.ppo_cycles?.length > 0 && (props.isPPO || selectedEntityData.plan === 'PPO'))
            {
                const newCycle = selectedEntityData?.ppo_cycles[0];
                setCycles(selectedEntityData?.ppo_cycles);
                dispatch(setSelectedCycle(newCycle));
            }
            else
            {
                const newCycle = selectedEntityData?.cycles[0];
                setCycles(selectedEntityData?.cycles);
                dispatch(setSelectedCycle(newCycle));
            }

            if(selectedEntityData?.plan === 'PPO')
                props.setShowPPO(true)
            else if(selectedEntityData?.plan === 'Payroll'){
                props.setShowPPO(false)
            }
        }
    }, [entity, entities, props?.isPPO]);

    return (
        <div className="page-header" data-testid="PageHeader">
            <div className="left-section">
                <div className="cycle-select-area">
                    <Select displayEmpty className="header-select" onChange={OnCycleChanged} value={cycle} >
                        {cycles?.map((x, index) => <MenuItem data-track='Change cycle' data-track-props={'cycle selected: '+x} className='option-design' key={x} value={x}>{index === 0 ? formatDate(x, false, false, true) + ' (Current)' : formatDate(x, false, false, true)}</MenuItem> )}
                    </Select>
                </div>
            </div>
            <div className="rightSection">
                <Notification />
                <div className="beamerTrigger">
                    <PiGift className="main-beamer_icon"/>
                </div>
            </div>
        </div>
    );
}

export default PageHeader;