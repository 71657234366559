const conditionalRowStyles = [
    {
      when: row => true,
      style: {
        fontWeight: 700,
        color: 'var(--warm-black)',
        fontSize: '14px',
        letterSpacing: '-0.35px',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        backgroundColor: 'var(--cultured)',
        padding: '8px 12px',
        borderRadius: '8px',
        height: '40px',
        marginBottom: '12px',
    }
    },
];

export default conditionalRowStyles;