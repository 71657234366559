import mixpanel from "mixpanel-browser";
import { getConfigByTabName } from "../Report/filtersTabs/columnConfig";
import { setCategoryType, setItemsToOmit, setReportSortState } from "../../store/reportSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BaseLink, DashboardLink, LinkType, PayslipLink, ReportLink } from "./ILink";
import { CategoryType } from "../Report/filtersTabs/categoryType";
import useCustomParams from "../../hooks/useCustomParams";

function DeepLink(props: BaseLink) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { entity, cycle } = useCustomParams();
    
    function deepLinkBuilder(config: BaseLink){
        switch(config?.type){
            case LinkType.Dashboard: 
                const dashboardConfiguration = config as DashboardLink;
                mixpanel.track(dashboardConfiguration?.eventName);
                navigate(`/${entity}/${cycle}/dashboard${dashboardConfiguration?.costAnalysis ? "?costAnalysis=" + dashboardConfiguration?.costAnalysis : ""}#${dashboardConfiguration?.pageElementId}`);

                const dashboardIntervalID = setInterval(() => {
                    const requiredElement = document?.getElementById(dashboardConfiguration?.pageElementId)
                    if (requiredElement){
                        const button = document.querySelector(dashboardConfiguration?.querySelector as string);
                        if (button || !dashboardConfiguration?.querySelector){
                            requiredElement.scrollIntoView({ behavior: "smooth", block: "start"});
                            //@ts-ignore
                            if (button) button?.click();
                            clearInterval(dashboardIntervalID)
                        }
                    }
                }, 200);
                break;
            case LinkType.Report: 
                const reportConfiguration = config as ReportLink;
                mixpanel.track(reportConfiguration?.eventName)
                dispatch(setCategoryType(reportConfiguration?.tab as CategoryType));
                dispatch(setItemsToOmit(getConfigByTabName(reportConfiguration?.tab as CategoryType)));
                dispatch(setReportSortState({sortId: reportConfiguration?.column, sortDirection: reportConfiguration?.direction}));
                navigate(`/${entity}/${cycle}/report?f=${reportConfiguration?.slug ? reportConfiguration?.slug : ""}`)
                break;
            case LinkType.Payslip: 
                const payslipConfiguration = config as PayslipLink;
                mixpanel.track(payslipConfiguration?.eventName);
                navigate(`/${entity}/${cycle}/payslips?id=${payslipConfiguration?.payslipNumber}`);
                break;
            default:
                break;
        }
    };
    
    return (
        <div className="HomePOCcardInsightItemButton"
         onClick={() => deepLinkBuilder(props)}>
            {
                props.linkText &&
                <div className={`payslip-btn`}>{props.linkText}</div>
            }
            {
                !props.linkText &&
                <div className={`payslip-btn`}>See why
                    <img className='next-icon' src='/arrow-right.svg' alt='See why'/>
                </div>
            }
        </div>
    );
}

export default DeepLink;