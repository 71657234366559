import { Switch } from "@mui/material";
import { BsTrash3 } from "react-icons/bs";
import { GoPencil } from "react-icons/go";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import IAutoCommentData from "./IAutoCommentData";

function AutoCommentLine(AutoCommentData: IAutoCommentData) {
    return (
        <div className="AutoCommentEditLine" key={AutoCommentData.alertName}>
            <div className="AutoCommentEditLineIcon"><IoIosCheckmarkCircleOutline/></div>
            <div className="AutoCommentEditLineText">
                <div className="AutoCommentEditLineTitle">{AutoCommentData.alertName}</div>
                <div className="AutoCommentEditLineSubTitle">{AutoCommentData.commentBy}</div>
            </div>
            <div className="AutoCommentEditLineCheckBox"><Switch disabled defaultChecked={AutoCommentData.enabled}/></div>
            <div className="AutoCommentEditLineEditGray"><GoPencil/></div>
            <div className="AutoCommentEditLineTrashGray"><BsTrash3/></div>
        </div>
    )
}

export default AutoCommentLine;