
export class PatientProfitabilitySummary {
    xid: string | undefined;
    name: string | undefined;
    service_code: string | undefined;
    billing_hours: number = 0;
    billing_total_amount: number = 0;
    actual_payroll_hours: number = 0;
    actual_payroll_total_amount: number = 0;
    actual_profit: number = 0;
    profit_percentage: number = 0;
    estimation_percentage: number = 0;
    estimation_profit: number = 0;
    discrepancy_profit: number = 0;
    recommendations: string | undefined;
    is_loss_profitability: boolean = false;
    is_low_profitability: boolean = false;
}

class PatientInfo {
    xid: string | undefined;
    name: string | undefined;
}

export class BillingPatientServiceDTO {
    invoice_date: string | undefined;
    invoice_number: string | undefined;
    contract: string | undefined;
    service_code: string | undefined;
    service_date: string | undefined;
    hours: number | undefined;
    rate: string | undefined;
    amount: number | undefined;
}

export class PayrollCaregiverServiceDTO {
    service_date: string | undefined;
    type: string | undefined;
    hours: number | undefined;
    rate: string | undefined;
    amount: number | undefined;
    is_nonworking_hour: boolean = false;
}


export class CaregiverBreakdownDTO {
    xid: string | undefined;
    name: string | undefined;
    additional_patients: PatientInfo[] = []
    payroll_services: PayrollCaregiverServiceDTO[] = []
    billing_patient_services: BillingPatientServiceDTO[] = []
}

export class PatientProfitabilityDetails {
    xid: string | undefined;
    name: string | undefined;
    caregivers_breakdown: CaregiverBreakdownDTO[] = []
}


