import PayslipCellDropdownItem from "./PayslipCellDropdownItem";

export enum PayslipCellStatus {
    REVIEW = 0,
    APPROVE = 1,
    DENY = 3,
}

export enum PayslipCellDropdownItemType {
    Review = 'Review',
    Approve = 'Approve',
    Deny = 'Deny',
    Comment = 'Comment',
    History = 'History'
}

export default class PayslipCellDropdownLogic {
    static getDropdownOptions = (status: number) => {
        switch (status) {
            case PayslipCellStatus.APPROVE:
                return <PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Approve} pastTense={true}/>;
            case PayslipCellStatus.REVIEW:
                return <PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Review}/>;
            case PayslipCellStatus.DENY:
                return <PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Deny} pastTense={true}/>;
        }
    }
}