import {useState} from 'react';
import './ActivityLog.css';
import {formatDate} from "../../utils/PresentationUtils";
import BackgroundLetterAvatars from '../Avatar/BackgroundLetterAvatars';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import getActivityLogsSectionIconPath from './ActivityLogLogic';

function ActivityLog (props){
    const [isOpen, setIsOpen] = useState(true);

    function onOpenAccordion(e, expanded) {
        setIsOpen(expanded);
    }

    const numberOfLogs = props?.data?.filter(x => x?.content === 'Approve all' || x?.changed_status !== '')?.length;

    return (
        <Accordion defaultExpanded={isOpen} onChange={(e, expanded) => onOpenAccordion(e, expanded)}>
            <AccordionSummary expandIcon={<img className="sheet-summary-icon" src='/expandIcon.svg' alt='open section'/>} aria-controls="panel1-content" id="panel1-header" >
                <div className='ActivityLog-section-header-row'>
                    <span className="ActivityLog-section-header">Activity Log</span> 
                </div>
            </AccordionSummary>
            <AccordionDetails style={{overflowY: "scroll"}}>
                {numberOfLogs === 0 ? <div className='empty-comment-section-text'>No activity log are documented for this payslip yet</div> : ''}
                {renderComments(props?.data)}
            </AccordionDetails>
        </Accordion>
    );
}

function renderComments(comments) {
    const resArr = [];

    const filteredComments = comments?.filter(x => x?.changed_status !== '' && x?.content !== 'Approve all');
    const approveAllComments = comments?.filter(x => x?.content === 'Approve all');
    filteredComments.push(approveAllComments);

    const sortedFilteredComments = filteredComments?.sort((a, b) => {
        // Extract the timestamps from the comments
        let timestampA = a?.timestamp || (Array.isArray(a) && a.length > 0 ? a[0]?.timestamp : null);
        let timestampB = b?.timestamp || (Array.isArray(b) && b.length > 0 ? b[0]?.timestamp : null);
        
        if (!timestampA || !timestampB) {
            // Handle cases where timestamp is missing
            return 0;
        }
        
        // Split the timestamps into date and microsecond parts
        let [dateA, microA] = timestampA.split('.');
        let [dateB, microB] = timestampB.split('.');
        
        // Parse the dates into Date objects
        let dateObjA = new Date(dateA);
        let dateObjB = new Date(dateB);
        
        // Compare the date parts
        let dateComparison = dateObjA - dateObjB;
        
        if (dateComparison !== 0) {
            return dateComparison;
        }
        
        // If date parts are equal, compare microsecond parts as numbers
        return parseInt(microA, 10) - parseInt(microB, 10);
    });

    const renderedComments = sortedFilteredComments?.map(comment => Array.isArray(comment) ? renderApproveAllComment(comment) : renderComment(comment));

    resArr.push(
        <div>
            {renderedComments}
        </div>
    );

    return resArr;
}


function renderApproveAllComment(comments) {
    const data = comments?.length > 0 ? comments[0] : null;
    const header = 'All alerts marked as approved by the user';
    const items = comments?.map(x => <div className="content-item-area">
            <img className='item-green-tick-ActivityLog' alt='green tick' src='/tick-green-circle.svg'/>
            <div className="ActivityLog-body-item">{x?.finding_message}</div>
        </div>
    );

    if (!data) {
        return;
    }

    return (
    <div className="sub-ActivityLog">
        <div className='ActivityLog-name-header'>
            <BackgroundLetterAvatars name={data?.user_name}/>
            <div className="ActivityLog-user">{data?.user_name}</div>
        </div>
        <div className='ActivityLog-date'>
            <div className="date-width-ActivityLog">{formatDate(data?.timestamp, true)}</div>
        </div>
        <div className="ActivityLog-body"><b>{header}</b></div>
        <div className='content-items-area'>{items}</div>
    </div>);
}

function renderComment(comment) {
    const content = comment['content'];
    
    return (
    <div key={comment['id']} className="sub-comment">
        <div className='ActivityLog-name-header'>
            <BackgroundLetterAvatars name={comment['user_name']}/>
            <div className="ActivityLog-user">{comment['user_name']}</div>
        </div>
        <div className='ActivityLog-date'>
            <div className="date-width-ActivityLog">{formatDate(comment['timestamp'], true)}</div>
        </div>
        <div className="ActivityLog-body">{content}</div>
        <div className='content-item-area'>
            {comment?.finding_message && <img className='content-item-icon' alt='comment' src={getActivityLogsSectionIconPath(comment?.approved)}/>}
            <div className="ActivityLog-body-item">{comment?.finding_message}</div>  
        </div>
    </div>);
}

export default ActivityLog;