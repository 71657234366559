import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import mixpanel from 'mixpanel-browser';
import PayslipCellDropdownLogic, { PayslipCellDropdownItemType } from './PayslipCellDropdownLogic';
import PayslipCellDropdownItem from './PayslipCellDropdownItem';
import './PayslipCellDropdown.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCommentEnforcerState } from '../../../store/CommentEnforcerSlice';


const PayslipCellDropdown = (props: {finding: {payslip_id: number, id: number, approved: number}, refresh: () => void, onFindingClickedPrefilled: () => void, openCommentPopup: (open: boolean) => void,
                             setCommentPayslipId: (payslip_id: number) => void, setCommentFindingId: (FindingId: number) => void}) => {
    
    const dispatch = useDispatch();

    const isComplianceRequired = useSelector((state: any) => state?.CommentEnforcerReducer?.enforcerEnabled);

    if(props?.finding === undefined || props?.finding?.approved === 2) return;

    const defaultContent = <div className="PayslipCellDropdownItemDefault">{PayslipCellDropdownLogic.getDropdownOptions(props?.finding?.approved)}<img className="PayslipCellDropdownItemDefault-icon" src='/Alt Arrow Down.svg' alt='open select'/></div>;

    const showAlertHistory = () => {
        mixpanel.track('open history', {'payslip_id': props?.finding?.payslip_id, 'action': "history", finding: props?.finding?.id});
        if(props?.finding){
            props?.onFindingClickedPrefilled();
        }  
    };

    async function denyAlert() {
        try{
            mixpanel.track('deny Alert', {'payslip_id': props?.finding?.payslip_id, 'action': "deny", finding: props?.finding?.id});
            await axios?.post(`/api/payslips/${props?.finding?.payslip_id}/comment`, {finding: props?.finding?.id, content: "Deny Alert", approved: 3, recipients: []});
            props.refresh();
        }
        catch(err){
            console.log(err);
        }
    }

    async function reviewAlert() {
        try{
            mixpanel.track('move Alert back to review', {'payslip_id': props?.finding?.payslip_id, 'action': "review", finding: props?.finding?.id});
            await axios?.post(`/api/payslips/${props?.finding?.payslip_id}/comment`, {finding: props?.finding?.id, content: "Moved Alert Back To Review", approved: 0, recipients: []});
            props.refresh();
        }
        catch(err){
            console.log(err);
        }
    }

    async function approveAlert() {
        try{
            if(isComplianceRequired){
                dispatch(setCommentEnforcerState({open: true, payslipID: props?.finding?.payslip_id ,findingID: props?.finding?.id}));
            } else{
                mixpanel.track('approve Alert', {'payslip_id': props?.finding?.payslip_id, 'action': "approve", finding: props?.finding?.id});
                await axios?.post(`/api/payslips/${props?.finding?.payslip_id}/comment`, {finding: props?.finding?.id, content: "Approve Alert", approved: 1, recipients: []});
                props.refresh();
            }
        }
        catch(err){
            console.log(err);
        }
    }

    function addAlertComment() {
        props?.setCommentPayslipId(props?.finding?.payslip_id);
        props?.setCommentFindingId(props?.finding?.id);
        props?.openCommentPopup(true);
    }

    async function closePop(func: any, popState: any) {
        await func();
        popState.close();
    }

    return (
        <PopupState variant="popover">
        {(popupState) => (
            <>
                <Button className='PayslipCellDropdown-btn' {...bindTrigger(popupState)}>{defaultContent}</Button>
                <Menu className='PayslipCellDropdown-menu' {...bindMenu(popupState)}>
                    <MenuItem onClick={() => closePop(reviewAlert, popupState)}><PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Review}/></MenuItem>
                    <MenuItem onClick={() => closePop(approveAlert, popupState)}><PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Approve}/></MenuItem>
                    <MenuItem onClick={() => closePop(denyAlert, popupState)}><PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Deny}/></MenuItem>
                    <hr className='PayslipCellDropdown-hr'/>
                    <MenuItem onClick={() => closePop(addAlertComment, popupState)}><PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.Comment}/></MenuItem>
                    <MenuItem onClick={() => closePop(showAlertHistory, popupState)}><PayslipCellDropdownItem itemType={PayslipCellDropdownItemType.History}/></MenuItem>
                </Menu>
            </>
        )}
    </PopupState>
    );
};

export default PayslipCellDropdown;