import './PatientDetails.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useNavigate, useSearchParams} from "react-router-dom";
import useCustomParams from "../../../hooks/useCustomParams";
import {
    BillingPatientServiceDTO,
    PatientProfitabilityDetails,
    PatientProfitabilitySummary,
    PayrollCaregiverServiceDTO
} from "./PatientDetailsDTO";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CaregiverBreakdown from "../CaregiverBreakdown/CaregiverBreakdown";
import { styled } from '@mui/system';
import {number} from "mathjs";
import {CircularProgress} from "@mui/material";


function PatientDetails (props: any){
    const [dataLoaded, setDataLoaded] = useState(false);
    const [caregiverTab, setCaregiverTab] = useState(0);
    const [patientSummary, setPatientSummary] = useState<PatientProfitabilitySummary[]>([]);
    const [patientBreakdown, setPatientBreakdown] = useState<PatientProfitabilityDetails>(new PatientProfitabilityDetails());
    const [searchParams, setSearchParams] = useSearchParams();
    const { entity, cycle } = useCustomParams();
    const navigate = useNavigate();

    let patient_xid = searchParams.get('id')
    patient_xid = patient_xid ? patient_xid : '-1'

    useEffect(() => {
        setDataLoaded(false);
        loadPatientDetails();
        setDataLoaded(true);
    }, [patient_xid]);

    const loadPatientDetails = async () => {
        const patientSummary = await loadPatientSummary();
        const patientBreakdown = await loadPatientBreakdown();

        setPatientSummary(patientSummary);

        setCaregiverBreakdownTotal(patientBreakdown.caregivers_breakdown);
        setPatientBreakdown(patientBreakdown);
        setCaregiverTab(0);
    };

    const loadPatientSummary = async () => {
        const response = await axios.get(`/api/ppo/patients/${entity}/${cycle}/${patient_xid}/summary`);
        return await response?.data;
    };

    const loadPatientBreakdown = async () => {
        const response = await axios.get(`/api/ppo/patients/${entity}/${cycle}/${patient_xid}/breakdown`);
        return await response?.data;
    };

    const setCaregiverBreakdownTotal = (caregivers_breakdown: any) => {
        for(const key in caregivers_breakdown)
        {
            const caregiver_breakdown = caregivers_breakdown[key]
            addCaregiverBreakdownTotal(caregiver_breakdown);
        }
    }

    const totalRow = () => {
        const res = new PatientProfitabilitySummary()

        res.service_code = 'Totals: ';
        for (const key in patientSummary) {
            const patient = patientSummary[key];
            res.billing_hours += patient.billing_hours;
            res.actual_payroll_hours += patient.actual_payroll_hours;
            res.billing_total_amount += patient.billing_total_amount;
            res.actual_payroll_total_amount += patient.actual_payroll_total_amount;
            res.actual_profit += patient.actual_profit;
            res.estimation_profit += patient.estimation_profit;
            res.discrepancy_profit += patient.discrepancy_profit;
        }

        if(res.billing_total_amount > 0)
        {
            res.profit_percentage = (res.actual_profit / res.billing_total_amount) * 100
            res.estimation_percentage = (res.estimation_profit / res.billing_total_amount) * 100
        }

        return [res];
    };

    const addCaregiverBreakdownTotal = (new_caregiver_breakdown: any) => {
        const totalPayrollServicesRow = new PayrollCaregiverServiceDTO();
        const totalBillingPatientServiceRow = new BillingPatientServiceDTO();

        totalPayrollServicesRow.hours = 0;
        totalPayrollServicesRow.amount = 0;
        totalPayrollServicesRow.service_date = 'Totals:';
        totalBillingPatientServiceRow.hours = 0;
        totalBillingPatientServiceRow.amount = 0;
        totalBillingPatientServiceRow.service_date = 'Totals:';

        for (const key in new_caregiver_breakdown.payroll_services){
            const payroll_service = new_caregiver_breakdown.payroll_services[key]
            if (!payroll_service.is_nonworking_hour)
                totalPayrollServicesRow.hours += number(payroll_service?.hours ?? 0);
            totalPayrollServicesRow.amount += number(payroll_service?.amount ?? 0);
        }

        for (const key in new_caregiver_breakdown.billing_patient_services){
            const billing_patient_service = new_caregiver_breakdown.billing_patient_services[key]
            totalBillingPatientServiceRow.hours += number(billing_patient_service?.hours ?? 0);
            totalBillingPatientServiceRow.amount += number(billing_patient_service?.amount ?? 0);
        }

        new_caregiver_breakdown.payroll_services.push(totalPayrollServicesRow)
        new_caregiver_breakdown.billing_patient_services.push(totalBillingPatientServiceRow)
    }

    const backToPatientsPage = () => {
        navigate(`/${entity}/${cycle}/patients`);
    }

    function renderNumberCell(params: any) {
        return renderNumber(params);
    }

    function renderNumberPercentageCell(params: any) {
        return renderNumber(params, true);
    }

    function renderNumber(params: any, include_percentage: boolean = false) {
        const is_loss_profitability = params?.row?.is_loss_profitability
        const is_low_profitability = params?.row?.is_low_profitability
        const value = params?.value

        let colorClass = 'PPOPageTableCellGreen'

        if (is_loss_profitability) {
            colorClass = 'PPOPageTableCellRed'
        } else if (is_low_profitability) {
            colorClass = 'PPOPageTableCellOrange'
        }

        let s_value = Math.round(value * 100) / 100;
        let fixedNumber = parseFloat(s_value.toFixed(2));
        if (s_value < 0)
        {
            fixedNumber = Math.abs(fixedNumber);
            return <div className={colorClass}>({fixedNumber.toLocaleString()}{include_percentage ? '%': '' })</div>
        }
        return <div className={colorClass}> {fixedNumber.toLocaleString()}{include_percentage ? '%': '' }</div>
    }

    function renderHoursCell(value: any) {
        let s_value = Math.round(value * 100) / 100;
        let fixedNumber = parseFloat(s_value.toFixed(2));

        return <div>{fixedNumber.toLocaleString()}</div>
    }

    const columns_definitions = [
        { field: 'service_code', headerName: 'Service Code',  width: 150, disableColumnMenu: true },
        { field: 'billing_hours', headerName: 'Billing',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.billing_hours), disableColumnMenu: true },
        { field: 'actual_payroll_hours', headerName: 'Payroll',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.actual_payroll_hours), disableColumnMenu: true },
        { field: 'billing_total_amount', headerName: 'Billing ($)',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.billing_total_amount), disableColumnMenu: true },
        { field: 'actual_payroll_total_amount', headerName: 'Payroll ($)',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.actual_payroll_total_amount), disableColumnMenu: true },
        { field: 'actual_profit', headerName: 'Act. ($)',  width: 100 , renderCell: renderNumberCell, disableColumnMenu: true},
        { field: 'profit_percentage', headerName: 'Act. (%)',  width: 100 , renderCell: renderNumberPercentageCell, disableColumnMenu: true},
        { field: 'estimation_profit', headerName: 'Est. ($)',  width: 100, renderCell: renderNumberCell, disableColumnMenu: true},
        { field: 'estimation_percentage', headerName: 'Est. (%)',  width: 100 , renderCell: renderNumberPercentageCell, disableColumnMenu: true},
        { field: 'recommendations', headerName: 'Recommendations',  flex:1, disableColumnMenu: true}
    ];

    const StyledDataGrid = styled(DataGrid)({
        '& .MuiDataGrid-columnHeaders': {
            display: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
            display: 'none',
        },
    });

    if (!dataLoaded)
        return (
            <div className="page-loader">
                <CircularProgress color="inherit"/>
            </div>
        )
    else
        { // @ts-ignore
            return (
                        <div className="patient-details">
                            <div className="patient-details-header">
                                <div className="patient-details-title">
                                    <div className='patient-header-txt-1'>{patientBreakdown?.name}</div>
                                    <div className='patient-header-txt-2'>{patientBreakdown?.xid}</div>
                                </div>
                                <div className="back-overview-section">
                                    <div className="back-overview-btn" onClick={() => backToPatientsPage()}>Back to patients</div>
                                </div>
                            </div>
                            <div className="patient-summary-table">
                                <div className="patient-summary-table-title">All Service Codes</div>
                                <div className="patient-summary-table-categories">
                                <div className="info-header">
                                        Information
                                    </div>
                                    <div className="billing-header">
                                        Hours
                                    </div>
                                    <div className="payroll-header">
                                        Amount
                                    </div>
                                    <div className="profit-header">
                                        Profit
                                    </div>
                                    <div className="action-header">
                                        Actions
                                    </div>
                                </div>
                                <div className="patient-summary-tbody">
                                    <div className="patient-summary-details-table">
                                        <DataGrid rows={patientSummary}
                                                  columns={columns_definitions}
                                                  getRowId={(row) => crypto.randomUUID()}
                                                  hideFooterSelectedRowCount={true}
                                                  disableRowSelectionOnClick
                                                  hideFooter
                                        />
                                    </div>
                                    <div className="patient-summary-total-table">
                                        <StyledDataGrid rows={totalRow()}
                                                        columns={columns_definitions}
                                                        getRowId={(row: any) => crypto.randomUUID()}
                                                        disableRowSelectionOnClick
                                                        hideFooter
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="patient-breakdown">
                                <div className="patient-breakdown-title">Caregivers</div>
                                {
                                    <div className="caregivers">
                                        <div className='caregivers-tab-container'>
                                            <Tabs value={caregiverTab} onChange={(event, newValue) => {
                                                setCaregiverTab(newValue);
                                            }} className='caregivers-tab'>
                                                {
                                                    patientBreakdown.caregivers_breakdown.map((caregiver_breakdown, index) => (
                                                        <Tab label={caregiver_breakdown.name} id={(index + 1).toString()} key={index} />
                                                    ))
                                                }
                                            </Tabs>
                                            <div style={{height: "48px", backgroundColor: "#F3F5F8", flexGrow: "1"}}></div>
                                        </div>
                                        {
                                            patientBreakdown.caregivers_breakdown.map((caregiver_breakdown, index) => (
                                                index === caregiverTab &&
                                                <CaregiverBreakdown caregiver_breakdown={caregiver_breakdown} />
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    )
        }
}


export default PatientDetails;
