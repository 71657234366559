import {useState} from 'react';
import mixpanel from "mixpanel-browser";
import './CommentsSection.css';
import CommentDialog from "../CommentDialog/CommentDialog";
import {formatDate} from "../../utils/PresentationUtils";
import BackgroundLetterAvatars from '../Avatar/BackgroundLetterAvatars';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import getCommentsSectionIconPath from './CommentsSectionLogic';

function CommentsSection (props){
    const [showDialog, setShowDialog] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [replyTo, setReplyTo] = useState(null);
    const [replyToName, setReplyToName] = useState(null);

    function openDialog(e, replyTo) {;
        mixpanel.track('New Comment', {'payslip_id': props?.payslipId})
        if(isOpen === true) e?.stopPropagation();
        setShowDialog(true);
        if(replyTo) setReplyTo(replyTo);
    }

    function onOpenAccordion(e, expanded) {
        setIsOpen(expanded);
    }

    function closeDialog(changed) {
        setShowDialog(false);
        setReplyTo(null);
        if (changed){
            props.refresh()
        }
    }

    const numberOfComments = props?.data?.filter(x => x?.content !== 'Approve all' && x?.changed_status === '')?.length;

    return (
        <Accordion defaultExpanded={isOpen} onChange={(e, expanded) => onOpenAccordion(e, expanded)}>
            <AccordionSummary expandIcon={<img className="sheet-summary-icon" src='/expandIcon.svg' alt='open section'/>} aria-controls="panel1-content" id="panel1-header" >
                <div className='comment-section-header-row'>
                    <span className="comment-section-header">Comments</span> 
                    {props?.data?.length ? <div className='grey-bubble'><img alt='comments' src='/message-text.svg'/>{numberOfComments}</div> : ''}
                    <img className='new-comment-btn' src='/add-comments-no-color.svg' onClick={e => openDialog(e)} alt='add comment'/>
                </div>
            </AccordionSummary>
            <AccordionDetails style={{overflowY: "scroll"}}>
                {numberOfComments === 0 ? <div className='empty-comment-section-text'>No comments are documented for this payslip yet</div> : ''}
                {renderComments(props?.data, openDialog, setReplyToName)}
                <CommentDialog payslip_id={props.payslipId} finding_id={null} selectedEmails={replyTo}
                                open={showDialog} closeDialog={closeDialog} approved={false} setReplyTo={setReplyTo} selectedName={replyToName}>
                </CommentDialog>
            </AccordionDetails>
        </Accordion>
    );
}

function renderComments(comments, openDialog, setReplyToName) {
    const resArr = [];

    const filteredComments = comments?.filter(x => x?.content !== 'Approve all' && x?.changed_status === '');
    
    const sortedFilteredComments = filteredComments?.sort((a, b) => {
        // Split timestamps into date part and microsecond part
        let [dateA, microA] = a?.timestamp?.split('.');
        let [dateB, microB] = b?.timestamp?.split('.');
        
        // Convert date part to Date object for comparison
        let dateObjA = new Date(dateA);
        let dateObjB = new Date(dateB);
        
        // Compare date parts first
        let dateComparison = dateObjA - dateObjB;
        
        if (dateComparison !== 0) {
            return dateComparison;
        }
        
        // If date parts are equal, compare microsecond parts as numbers
        return parseInt(microA, 10) - parseInt(microB, 10);
    });

    const renderedComments = sortedFilteredComments?.map(comment => renderComment(comment, openDialog, setReplyToName));

    resArr.push(
        <div>
            {renderedComments}
        </div>
    );

    return resArr;
}

function renderComment(comment, openDialog, setReplyToName) {
    const content = comment['content'];

    const commentSubRowWithoutEmails = <>
                    {comment?.finding_message && <img className='content-item-icon' alt='comment' src={getCommentsSectionIconPath(comment?.approved)}/>}
                    <div className="comment-body-item">{comment?.finding_message}</div> 
    </>

    const commentSubRoWithEmails = <>
        <img className='content-item-icon' alt='comment' src='/email-sent.svg'/>
        <div className="comment-body-item">Notification sent to: ({comment?.recipients?.join(', ')})</div> 
    </>

    return (
    <div key={comment['id']} className="sub-comment">
        <div className='comments-name-header'>
            <BackgroundLetterAvatars name={comment['user_name']}/>
            <div className="comment-user">{comment['user_name']}</div>
        </div>
        <div className='comment-date'>
            <div className="date-width">{formatDate(comment['timestamp'], true)}</div>
        </div>
        <div className="comment-body">{content}</div>
        <div className='content-item-area'>
            {commentSubRowWithoutEmails}
        </div>
        <div className='content-item-area-emails'>
            {comment?.recipients?.length > 0 && commentSubRoWithEmails}
        </div>
        <div className='content-item-area-reply' onClick={e => {
            setReplyToName(comment?.user_name);
            openDialog(e, comment?.creator_email);
        }}> Reply </div>
    </div>);
}

export default CommentsSection;