export interface BaseLink {
    url?: string;
    type: LinkType;
    eventName: string;
    linkText :string;
}

export interface ReportLink extends BaseLink {
    tab: string;
    column: string;
    direction: "asc" | "desc";
    slug: string;
}

export interface DashboardLink extends BaseLink {
    pageElementId: string;
    querySelector?: string;
    costAnalysis?: string;
}

export interface PayslipLink extends BaseLink {
    payslipNumber: string;
}

export enum LinkType {
    Payslip = 'payslip',
    Report = 'report',
    Dashboard = 'dashboard',
}