import {useEffect, useState} from 'react';
import './TotalEarningsBreakdown.css';
import DataTable from "react-data-table-component";
import {formatNumber} from "../../../utils/PresentationUtils";
import ExportBtn from "../../ExportBtn/ExportBtn";
import conditionalRowStyles from "../../Dashboard/DashboardUtils/greyTableStyle";

function TotalEarningsBreakdown(props){
    const [csvExportData, setCsvExportData] = useState([]);

    useEffect(() => {
        prepCsvData(props.data)
    }, [props.data])

    function prepCsvData(data) {
        if (data) {
            let csvData = []
            for (let row of data) {
                let newTopRow = {'name':'', 'dept_name':''}

                for (let key in row) {
                    newTopRow[key] = row[key]
                }

                let sub_rows = newTopRow['departments']
                delete newTopRow['departments']
                csvData.push(newTopRow)
                for (let sub_row of sub_rows){
                    sub_row['name'] = ''
                    csvData.push(sub_row)
                }
            }
            setCsvExportData(csvData)
        } else {
            setCsvExportData([])
        }
    }

    return (
        <div className="total-earnings-breakdown">
            <div className='dashboard-component-header'>
                <div id="earnings-breakdown">Earnings Breakdown</div>
                <ExportBtn data={csvExportData} filename={"CeleryEarningsBreakdownExport.csv"}></ExportBtn>
            </div>
            <div className='total-earnings-breakdown-table'>
                <DataTable
                    conditionalRowStyles={conditionalRowStyles}
                    columns={columns}
                    data={props.data}
                    defaultSortFieldId={0}
                    theme="default"
                    responsive
                    dense
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
            </div>
        </div>
    );
}

const ExpandedComponent = ({ data }) => <div className="TotalEarningsBreakdownSubTable">
    <DataTable
        columns={extended_columns}
        data={data.departments}
        defaultSortFieldId={0}
        theme="default"
        responsive
        dense
    />
</div>;

const columns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Total ($)',
        selector: row => row['total'],
        format: row => formatNumber(row['total'], false, true),
        sortable: true,
    },
    {
        name: 'Last Cycle Total ($)',
        selector: row => row['last_cycle_total'],
        format: row => formatNumber(row['last_cycle_total'], false, true),
        sortable: true,
    },
]

const extended_columns = [
    {
        name: 'Name',
        selector: row => row['dept_name'],
        sortable: true,
    },
    {
        name: 'Total ($)',
        selector: row => row['total'],
        format: row => formatNumber(row['total'], false, true),
        sortable: true,
    },
    {
        name: 'Last Cycle Total ($)',
        selector: row => row['last_cycle_total'],
        format: row => formatNumber(row['last_cycle_total'], false, true),
        sortable: true,
    },
]

export default TotalEarningsBreakdown;