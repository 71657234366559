import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PatientProfitabilityOverviewData, ContractProfitabilitySummary} from "../components/PPO/PPOPage/PPOOverview";
import {PatientProfitabilitySummary} from "../components/PPO/Patients/PatientProfitabilitySummary";
import {GridSortItem} from "@mui/x-data-grid";

export interface FilteredPatientsState {
    entityID: number;
    cycle: string;
    overview: PatientProfitabilityOverviewData;
    patients: PatientProfitabilitySummary[];
    contractsProfitability: ContractProfitabilitySummary[];
    patientKPIFilterKey: string;
    sortModel: GridSortItem[];
    filterModel: any;
};

const initialState: FilteredPatientsState = {
    overview: new PatientProfitabilityOverviewData(),
    patients: [],
    contractsProfitability: [],
    patientKPIFilterKey: "",
    entityID: -1,
    cycle: "",
    sortModel: [
        { field: 'actual_profit', sort: 'asc' }
    ],
    filterModel: { items: [] }
};

export const filteredPatientsSlice = createSlice({
    name: 'filteredPatients',
    initialState,
    reducers: {
        setContractsProfitability: (state, action: PayloadAction<ContractProfitabilitySummary[]>) => {
            state.contractsProfitability = (action.payload);
        },
        setPatients: (state, action: PayloadAction<PatientProfitabilitySummary[]>) => {
            state.patients = (action.payload);
        },
        setPatientKPIFilterKey: (state, action: PayloadAction<string>) => {
            state.patientKPIFilterKey = (action.payload);
        },
        setOverview: (state, action: PayloadAction<PatientProfitabilityOverviewData>) => {
            state.overview = (action.payload);
        },
        setCycle: (state, action: PayloadAction<string>) => {
            state.cycle = (action.payload);
        },
        setEntityID: (state, action: PayloadAction<number>) => {
            state.entityID = (action.payload);
        },
        setSortModel: (state, action: PayloadAction<GridSortItem[]>) => {
            state.sortModel = (action.payload);
        },
        setFilterModel: (state, action: PayloadAction<any>) => {
            state.filterModel = (action.payload);
        }
    }
});

export const { setEntityID, setCycle,
                setOverview, setPatients,
                setPatientKPIFilterKey, setSortModel,
                setFilterModel, setContractsProfitability } = filteredPatientsSlice.actions;
export default filteredPatientsSlice.reducer;