import {useEffect, useState} from 'react';
import './OneOffPayments.css';
import DataTable from "react-data-table-component";
import {formatNumber} from "../../../utils/PresentationUtils";
import ExportBtn from "../../ExportBtn/ExportBtn";
import conditionalRowStyles from "../../Dashboard/DashboardUtils/greyTableStyle";

function OneOffPayments(props){
    const [csvExportData, setCsvExportData] = useState([]);

    useEffect(() => {
        prepCsvData(props.data)
    }, [props.data])

    function prepCsvData(data) {
        if (data) {
            let csvData = []
            for (let row of data) {
                let newTopRow = {'type':'', 'name':''}

                for (let key in row) {
                    newTopRow[key] = row[key]
                }

                let sub_rows = newTopRow['employees']
                delete newTopRow['employees']
                csvData.push(newTopRow)
                for (let sub_row of sub_rows){
                    sub_row['type'] = ''
                    csvData.push(sub_row)
                }
            }
            setCsvExportData(csvData)
        } else {
            setCsvExportData([])
        }
    }

    return (
        <div className="one-off-payments">
            <div className='dashboard-component-header'>
                <div id="one-off-payments">One Off Payments</div>
                <ExportBtn data={csvExportData} filename={"CeleryOneOffExport.csv"}></ExportBtn>
            </div>
            <DataTable
                conditionalRowStyles={conditionalRowStyles}
                columns={columns}
                data={props.data}
                defaultSortFieldId={0}
                theme="default"
                responsive
                dense
                expandableRows
                expandableRowsComponent={ExpandedComponent}
            />
        </div>
    );
}

const ExpandedComponent = ({ data }) => <div className="OneOffPaymentsExtended">
    <DataTable
        columns={extended_columns}
        data={data.employees}
        defaultSortFieldId={0}
        theme="default"
        responsive
        dense
    />
</div>;

const columns = [
    {
        name: 'Type',
        selector: row => row['type'],
        sortable: true,
    },
    {
        name: 'Total  ($)',
        selector: row => row['total'],
        format: row => formatNumber(row['total'], false, true),
        sortable: true,
    },
    {
        name: 'Last Cycle Total  ($)',
        selector: row => row['last_cycle_total'],
        format: row => formatNumber(row['last_cycle_total'], false, true),
        sortable: true,
    },
]

const extended_columns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Employee Total ($)',
        selector: row => row['total'],
        format: row => formatNumber(row['total'], false, true),
        sortable: true,
    },
    {
        name: 'Last Cycle Employee Total  ($)',
        selector: row => row['last_cycle_total'],
        format: row => formatNumber(row['last_cycle_total'], false, true),
        sortable: true,
    },
]

export default OneOffPayments;