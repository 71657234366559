import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import {json, useParams} from "react-router-dom";
import "./FindingModal.css";
import {CartesianGrid, LabelList, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatDate, formatNumber} from "../../utils/PresentationUtils";

const FindingModal = (props: any) =>
{
  const [data, setData] = useState({code:'', values:[]});
  const { entity, cycle } = useParams();

  useEffect(() => {
    getCodeStatistics();
  },[props.finding]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height: "380px",
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderRadius: "10px"
    },
    overlay: {
      backgroundColor: "#102E442E",
      backdropFilter: "blur(4px) brightness(100%)",
    }
  };

  return (
      <div>
        <ReactModal
            ariaHideApp={false}
            isOpen={props.isOpen}
            style={customStyles}
            shouldCloseOnOverlayClick={true}
        >

          <div className="FindingModalTitleArea">
            <div className="FindingModalTitle">{props.finding?.field_display_name}</div>
            <div className="FindingModalTitleClose" onClick={() => props.setOpen(false)}>X</div>
          </div>


          <ResponsiveContainer width={500} height={300} className='FindingModalGraph'>
            <LineChart data={data?.values} margin={{top: 20, right: 20, left: 35}}>
              <CartesianGrid></CartesianGrid>
              <XAxis dataKey="cycle" tickFormatter={(value) => {return formatDate(value,false, true)}} dy={10}/>
              <Tooltip formatter={(value, name, props) => {return formatNumber(value)}}/>
              <Legend />
              <Line dataKey="employee_value" name={props.name} stroke="#EF525B" strokeWidth={3}>
                <LabelList dataKey="employee_value" position="top" formatter={formatNumber} />
              </Line>
              <Line dataKey="dept_value" name={"Department Average"} stroke="#005A73" strokeWidth={3}>
                <LabelList dataKey="dept_value" position="top"  formatter={formatNumber}/>
              </Line>
            </LineChart>
          </ResponsiveContainer>
        </ReactModal>
      </div>
  );

  function getCodeStatistics() {
    axios.get(`/api/system/statistics/${entity}/${cycle}/${props.finding?.payslip_id}/?code=${encodeURIComponent(props.finding?.field_name)}`).then((res) => {
      if (res?.data){
        setData(res.data);
      } else {
        setData({code:'', values:[]});
      }
    }).catch((err) => {
      console.log(err);
    })
  }
}

export default FindingModal;