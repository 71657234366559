let PPOFileRows = (props: {files: File[], setAcceptedFiles: React.Dispatch<React.SetStateAction<File[]>>}) => {
    if (props && props?.files?.length > 0){
        return (
            <>
                {
                    props?.files?.map((file: File) =>
                        <div className="upload-popup-file">
                            <div className="upload-popup-file-text-wrapper">
                                <div className="upload-popup-file-icon"></div>
                                <div className="upload-popup-file-text">{file?.name}</div>
                            </div>
                            <div className="upload-popup-file-x" onClick={() => props?.setAcceptedFiles(acceptedFiles => [...acceptedFiles.filter((x: File) => x?.name !== file?.name)])}></div>
                        </div>
                    )
                }
            </>
        );
    }else{
        return <span></span>;
    }
};

export default PPOFileRows;