export class PatientProfitabilityOverview {
    profit: number = 0;
    total_patients: number = 0;
    profit_per_patient: number = 0;
}

export class PatientProfitabilityOverviewData
{
    loss: number = 0;
    patients_loss_count: number = 0;
    patients_loss_avg: number = 0;
    low_profitability: number = 0;
    patients_low_profitability_count: number = 0;
    patients_low_profitability_avg: number = 0;
    profitability: number = 0;
    patients_profitability_count: number = 0;
    patients_profitability_avg: number = 0;
    patient_profit_avg: number = 0;
    billing_hours: number = 0;
    billing_amount: number = 0;
    payroll_amount: number = 0;
    billable_payroll_hours: number = 0;
    non_billable_payroll_hours: number = 0;
    revenue: number = 0;
    total_patients: number = 0;
    total_caregivers: number = 0;
}


export class ContractProfitabilitySummary {
    name: string | undefined;
    service_code: string | undefined;
    total_patients: number = 0;
    billing_hours: number = 0;
    billing_amount: number = 0;
    avg_billing_rate: number = 0;
    payroll_hours: number = 0;
    payroll_amount: number = 0;
    profit: number = 0;
    avg_profit_rate: number = 0;
}
