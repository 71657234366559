import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface FilteredPayslipsState {
    payslips: [];
    payslipsIndex: number;
    payslipsSeen: number
    backToReport: boolean,
    payslipSheetOpenSections: Array<{name: string, open: boolean}>
    showConfetti: number,
    openAuditCompleteDialog: boolean
};

const initialState: FilteredPayslipsState = {
    payslips: [],
    payslipsIndex: 0,
    payslipsSeen: 1,
    backToReport: false,
    payslipSheetOpenSections: [{name: "Technical Information", open: false},
                               {name: "Earnings", open: true},
                               {name: "Deductions", open: false},
                               {name: "Other", open: false}],
    showConfetti: 0,
    openAuditCompleteDialog: false
};

export const filteredPayslipsSlice = createSlice({
    name: 'filteredPayslips',
    initialState,
    reducers: {
        setPayslips: (state, action: PayloadAction<[]>) => {
            state.payslips = (action.payload);
        },
        setPayslipsIndex: (state, action: PayloadAction<number>) => {
            state.payslipsIndex = (action.payload);
        },
        setPayslipsSeen: (state, action: PayloadAction<number>) => {
            state.payslipsSeen = (action.payload);
        },
        setBackToReport: (state, action: PayloadAction<boolean>) => {
            state.backToReport = (action.payload);
        },
        resetPayslipsSeen: (state) => {
            state.payslipsSeen = 1
        },
        togglePayslipSheetSection: (state, action: PayloadAction<string>) => {
            state.payslipSheetOpenSections.map((section) => {
                if (section.name === action.payload){
                    section.open = !section.open
                }
            })
        },
        setOpenAuditCompleteDialog: (state, action: PayloadAction<boolean>) => {
            state.openAuditCompleteDialog = (action.payload);
        }
    }
});

export const { setOpenAuditCompleteDialog, setPayslips, setPayslipsIndex, setBackToReport, resetPayslipsSeen, togglePayslipSheetSection, setPayslipsSeen} = filteredPayslipsSlice.actions;
export default filteredPayslipsSlice.reducer;