import { useEffect, useRef, useState } from 'react';
import Badge from '@mui/material/Badge';
import { RiNotification3Line } from 'react-icons/ri';
import axios from 'axios';
import INotification from './INotification';


const Notification = () => {
  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState<null | INotification[]>(null);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handBelleClick = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);
  
  useEffect(() => {
    const getNotifications = async () => {
      const apiCall = await axios.get('/api/communication/notifications/');
      const response = await apiCall?.data;
      setCount(response?.filter((notification: INotification) => !notification?.seen && notification?.entity !== undefined && notification?.cycle).length || 0);
      setNotifications(response);
    }
    getNotifications();

  }
  , [count]);

  const updateNotificationsAfterSeen = async (notificationId: number) => {
    axios.get(`/api/communication/notifications/${notificationId}/mark_read/`);
  }

  const textSplitter = (text: string) => {
      const firstRow = text?.split(':')[0];
      const secondRow = text?.split(':')[1];
      return `${firstRow}: \n${secondRow}`;
  };

  return (
    <div className='notification-container' ref={dropdownRef} >    
      <Badge className='notification-bell' badgeContent={count} color="error" onClick={handBelleClick} style={{ cursor: `${count > 0 ? 'pointer' : ''}` }}>
        <RiNotification3Line size={24} className='notification-bell-bubble'/>
      </Badge>
      <div style={{display: `${open ? '' : 'none'}`}} className='notification-list'>
        {notifications?.filter((notification: INotification) => notification?.entity !== undefined && notification?.cycle)?.map((notification: INotification) => ( 
          <div className='notification-list-item' key={notification.id} onClick={() => {
            updateNotificationsAfterSeen(notification?.id);
            setOpen(false);
            setCount(count - 1);
            window.location.href = `/${notification?.entity}/${notification?.cycle}/payslips/?id=${notification?.payslip}`;
          }}>
            {notification?.seen ? textSplitter(notification?.message) : <b>{textSplitter(notification?.message)}</b>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notification;