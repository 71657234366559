import {useEffect, useState} from 'react';
import './FieldAnalysis.css';
import axios from "axios";
import DataTable from "react-data-table-component";
import ExportBtn from "../../ExportBtn/ExportBtn";
import {formatNumber} from "../../../utils/PresentationUtils";
import conditionalRowStyles from "../../Dashboard/DashboardUtils/greyTableStyle";
import useCustomParams from "../../../hooks/useCustomParams";

function FieldAnalysis(props) {
    const [data, setData] = useState([]);
    const [fieldDisplay, setFieldDisplay] = useState('');
    const { entity, cycle } = useCustomParams();

    useEffect(() => {
        getData(props.field, setData, setFieldDisplay)
    }, [props.field]);

    const columns = [
        {
            name: fieldDisplay?.toString()?.replace('__', ''),
            selector: row => fieldDisplay === "Average Rate" ? parseFloat(row['field_value']).toFixed(2) : row['field_value'],
            sortable: true,
        },
        {
            name: 'Base Hours',
            selector: row => row['base_hours'],
            format: row => formatNumber(row['base_hours'], false, true),
            sortable: true,
        },
        {
            name: 'Base Cost  ($)',
            selector: row => row['total_base_cost'],
            format: row => formatNumber(row['total_base_cost'], false, true),
            sortable: true,
        },
        {
            name: 'Average Hourly rate  ($)',
            selector: row => row['avg_base_cost'],
            format: row => formatNumber(row['avg_base_cost'], false, true),
            sortable: true,
        },
        {
            name: 'Total Earnings',
            selector: row => row['total_earnings'],
            format: row => formatNumber(row['total_earnings'], false, true),
            sortable: true,
        }
    ]

    return (
        <div className="field-analysis">
            <ExportBtn data={data} filename={"CeleryAnalysisExport.csv"}></ExportBtn>
            <DataTable
                conditionalRowStyles={conditionalRowStyles}
                columns={columns}
                data={data}
                defaultSortFieldId={0}
                theme="default"
                responsive
                dense
                noDataComponent={<div>
                        <div className='empty-state-table'></div>
                        <div>Select field group to display data</div>
                    </div>}
            />
            <div className="fieldAnalysisNotice">* base cost includes base salary, regular hours and overtime</div>
        </div>
    );

    function getData(field, setData, setFieldDisplay){
        if (!field) {
            setData([])
            return
        }
        axios.get(`/api/reports/${entity}/${cycle}/analysis/?field=${encodeURIComponent(field)}`).then((res) => {
            if (res.data){
                setData(res.data.lines)
                setFieldDisplay(res.data.field)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

export default FieldAnalysis;