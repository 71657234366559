import ReactModal from "react-modal";
import './UploadModal.css';
import {useDropzone} from 'react-dropzone';
import { useState } from "react";
import axios from "axios";
import FileRows from "./FileRows";
import { LinearProgress } from "@mui/material";

const UploadModal = (props: {toTriggerStatus: Function ,modalIsOpen: boolean, entity: number, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, uploadProgress: {}, setUploadProgress: any}) => {
  const [progressIsOpen, setProgressIsOpen] = useState(false);

  const onDrop = (newFiles: any) => {
    setAcceptedFiles([...acceptedFiles, ...newFiles]);
  };

  const onSend = () => {
    setProgressIsOpen(true);
    const formData = new FormData();
    for (let file of acceptedFiles) {
      formData.append('files', file)
    }

    axios.post('/api/payslips/upload/' + props?.entity, formData, {
      headers: {
          'content-type': 'multipart/form-data'
      },
      onUploadProgress: data => {
        // @ts-ignore
        setProgress(Math.round((100 * data.loaded) / data.total));
        let newUploadProgress = {...props?.uploadProgress};
        //@ts-ignore
        newUploadProgress[props?.entity] = Math.round((100 * data.loaded) / data.total);
        props?.setUploadProgress(newUploadProgress);
      }
    }).then(response => {
        console.log("loaded files to server");
        props.setIsOpen(false);
        setAcceptedFiles([]);
        setProgress(0);
        setProgressIsOpen(false);
        props.toTriggerStatus(true);
    }).catch(error => {
        console.log("error in uploading files: ", error);
        props.setIsOpen(false);
        setAcceptedFiles([]);
        setProgress(0);
        setProgressIsOpen(false);
    });
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const [progress, setProgress] = useState(0);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([] as File[]);

  const fileTypes = acceptedFiles.map((file: File) => {
    const fileType = file?.type?.split('/')[1];
    return fileType;
  });

  let containUnsupportedFiles = fileTypes.some((fileType: string) => {
    return fileType !== 'csv' && fileType !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'vnd.ms-excel' &&
    fileType !== 'pdf';
  });

  if (fileTypes?.length === 0) {
    containUnsupportedFiles = true;
  }

  const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "664px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        overlay: {
          backgroundColor: "#102E442E",
          backdropFilter: "blur(4px) brightness(100%)",
        }
  };
    
  function closeHandler() {
    props.setIsOpen(false);
    setAcceptedFiles([]);
    setProgress(0);
    setProgressIsOpen(false);
  }

  return (
    <div>
      <ReactModal
        ariaHideApp={false}
        isOpen={props.modalIsOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeHandler}
      >
        <div className="upload-popup-header-section">
            <div className='upload-popup-header'>Upload Files</div>
            <div className='upload-popup-x-btn' onClick={closeHandler}></div>
        </div>
        <div>
          <div className='upload-popup-sub-header'>All CSV and XLSX and xls types are supported</div>
          <div {...getRootProps({className: 'upload-popup-drop-zone'})} style={{backgroundColor:`${isDragActive ? '#DFF2F7' : "white"}`}}>
            <div className="upload-popup-img"></div>
            <div className='upload-popup-explanation'>Drag and drop your files here</div>
            <input {...getInputProps()} id="upload-popup-input"/>
          </div>
          <div className='upload-popup-role'>OR</div>
          <div className="upload-popup-label">
            <span className="upload-popup-upload-icon"></span>
            <label htmlFor="upload-popup-input">Select Files</label>
          </div>
          {fileTypes?.length > 0 && containUnsupportedFiles && <div className="wrong-file-type-message" style={{color: "red", padding: "5px"}}>Please upload a CSV or Excel format</div>}
          <div className="upload-popup-files-section">
            <FileRows files={acceptedFiles} setAcceptedFiles={setAcceptedFiles}/>
          </div>
          <div className="upload-popup-file-loading" style={{visibility: `${progressIsOpen ? 'visible' : 'hidden'}`}}>
              <div className="upload-popup-file-loading-bar">
              <LinearProgress variant="determinate" value={progress} />
              </div>
              <div className="upload-popup-file-loading-text">Uploading...</div>
          </div>
          <div className="upload-popup-btn-panel">
            <button className='upload-popup-discard-btn' onClick={closeHandler}>Discard</button>
            <button className='upload-popup-save-btn' onClick={onSend} style={{backgroundColor: `${containUnsupportedFiles ? 'grey': ''}`}} disabled={containUnsupportedFiles}>Save</button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default UploadModal;