import { CSSProperties } from "react"

export default function AlertIcon(props: {isFindingAlerts: boolean, numberOfItems: string, colors?: [string, string]}){
    const style = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2px',
        padding: '2px 8px',
        position: 'relative',
        backgroundColor: props?.colors ? props?.colors[0] : (props.isFindingAlerts ? '#f9c1c4' : '#DEEAEC'),
        borderRadius: '90px',
        fontWeight: 500,
        colors: props?.colors ? props?.colors[1] : (props.isFindingAlerts ? '#ef525b' : '#3D8194'),
        fontSize: '13px',
        letterSpacing: '-0.35px',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        marginLeft: '12px',
    }

    return (
        <div style={style as CSSProperties}>
            <img src={props.isFindingAlerts ? '/close-circle.svg' : '/close-circle-gray.svg'} alt='finding'/>
            <span style={{color: `${props?.colors ? props?.colors[1] : (props.isFindingAlerts ? '#ef525b' : '#3D8194')}`}}>
                {props.numberOfItems}
            </span>
        </div>)
}