import './DeepInsights.css';
import axios from "axios";
import {useEffect, useState} from "react";
import useCustomParams from '../../hooks/useCustomParams';


function DeepInsights(){
    
    const { entity, cycle } = useCustomParams();
    const [data, setData] = useState<any>({});
    const [sdiv, setSdiv] = useState<any>(2);
    const [ot_percent, setOt_percent] = useState<any>(0.33);
    const [selectedCodes, setSelectedCodes] = useState<any>([]);

    function toggleCode(code: string) {
        if (selectedCodes.includes(code)){
            const newCodes = selectedCodes.filter(function(item:string) {
                return item !== code
            })
            setSelectedCodes(newCodes)
        } else {
            setSelectedCodes(selectedCodes.concat([code]))
        }
    }

    useEffect(() => {
        getData();
    }, [entity, cycle, sdiv, ot_percent])

    return (
        <div>
            <div className={"DeepInsightsInputsZone"}>
                <label>Standard Deviations: </label>
                <input className={"DeepInsightsInputs"} value={sdiv} onChange={(evt) => {
                    setSdiv(evt.target.value);
                }}/>
                <label>Overtime Percent</label>
                <input className={"DeepInsightsInputs"} value={ot_percent} onChange={(evt) => {
                    setOt_percent(evt.target.value);
                }}/>
            </div>
            <div>
                <div className={"DeepInsightsFilters"}>
                    {
                        data?.codes?.map((code: string) => {
                            return <div className={"DeepInsightsCode " + (selectedCodes.includes(code) ? "DeepInsightsCodeSelected" : "") } key={'k_' + code} onClick={() => {
                                toggleCode(code);
                            }}>{code}</div>
                        })
                    }
                </div>
            </div>
            <div>
                {
                    data?.employees_offences &&
                    Object.entries(data?.employees_offences).map((employees_offence_pair: any) => {
                        return <div key={'eo_' + employees_offence_pair[0]}>
                            {
                                shouldShowEmployee(employees_offence_pair[1]) &&
                                renderEmployee(employees_offence_pair[1])
                            }
                            <br></br>
                        </div>
                    })
                }
            </div>
        </div>
    );

    function shouldShowEmployee(employee: any) {
        if (selectedCodes.length == 0){
            return true
        } else {
            const employee_codes = Object.keys(employee.code_offences)
            if (selectedCodes.some((code:any) => {
                return employee_codes.includes(code);
            })) {
                return true
            } else {
                return false
            }
        }
    }

    function getData() {
        axios.get(`/api/reports/${entity}/${cycle}/deep_insights/?sdiv=${sdiv}&ot_alert=${ot_percent}`).then((res) => {
            if (res.data){
                setData(res.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    function renderEmployee(employees_offence:any) {
        return (
            <div className={"DeepInsightsEmployee"}>
                <div>
                    <h2>Employee {employees_offence.employee_id}</h2>
                </div>

                {
                    Object.keys(employees_offence.code_offences).length > 0 &&
                    <div>
                        <h3>Code Issues</h3>

                        <div>
                            <div>
                            </div>
                            {
                                employees_offence.code_offences && Object.entries(employees_offence.code_offences) &&
                                Object.entries(employees_offence.code_offences).map((co: any) => {

                                    return renderCodeIssue(co[1])
                                })
                            }
                        </div>
                    </div>
                }

                {
                    Object.keys(employees_offence.hours_offence).length > 0 &&
                    <div>
                        <h3>Hours Issues</h3>
                        <div>{renderHours(Object.entries(employees_offence.hours_offence))}</div>
                    </div>
                }
            </div>
        )
    }

    function renderCodeIssue(code_offence: any) {
        return (
            <div>
                <h4>{code_offence.code}</h4>
                <div>
                    {code_offence.messages && code_offence.messages.map((message:any) => {
                        return <p>{message}</p>
                    })}
                </div>
                <div>
                    <h5>{code_offence.code} Past issues:</h5>
                    <table className={"DeepInsightsTableElement"}>
                        <thead>
                            {
                                code_offence.values_array && code_offence.values_array.map((v: any) => {
                                    return <th className={"DeepInsightsTableElement"}>{v.cycle}</th>
                                })
                            }
                            <th className={"DeepInsightsTableElement"}>Dept past max</th>
                        </thead>
                        <tbody>
                            {
                                code_offence.values_array && code_offence.values_array.map((v: any) => {
                                    return <td className={"DeepInsightsTableElement"}>{v.value}</td>
                                })
                            }
                            <td className={"DeepInsightsTableElement"}>{code_offence.max.toFixed(2)}</td>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }

    function renderHours(hours_offences: any){
        return (
            <div>
                <div>
                    {
                        hours_offences.map((hs: any) => {
                            return hs[1].messages.map((msg: any) => {
                                return <div>{msg}</div>
                            })
                        })
                    }
                </div>

                <table className={"DeepInsightsTableElement"}>
                    <thead>
                    <th className={"DeepInsightsTableElement"}>Cycle</th>
                    {
                        hours_offences.map((hs: any) => {
                            return <th className={"DeepInsightsTableElement"}>{hs[1].cycle}</th>
                        })
                    }
                    </thead>
                    <tbody>
                    <tr>
                        <td className={"DeepInsightsTableElement"}>Total Hours</td>
                        {
                            hours_offences.map((hs: any) => {
                                return <td className={"DeepInsightsTableElement"}>{hs[1].all_hours}</td>
                            })
                        }
                    </tr>
                    <tr>
                        <td className={"DeepInsightsTableElement"}>OT Hours</td>
                        {
                            hours_offences.map((hs: any) => {
                                return <td className={"DeepInsightsTableElement"}>{hs[1].ot_hours}</td>
                            })
                        }
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default DeepInsights;

