import {Avatar} from '@mui/material';
import mixpanel from 'mixpanel-browser';
import {logout} from '../../providers/login/login';
import Popper from '@mui/material/Popper';
import {bindToggle, bindPopper} from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import './loginPanel.css';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopupState } from 'material-ui-popup-state/hooks';


function LoginPanel (props: {setLoggedIn: (value: boolean) => void}){
    const rawUserData = localStorage.getItem("userData") ?? "{}";
    const userData = JSON.parse(rawUserData);
    const firstName = userData?.first_name;
    const lastName = userData?.last_name;
    const email = userData?.email;
    const initials = firstName?.charAt(0) + lastName?.charAt(0);
    const popupState = usePopupState({ variant: 'popper', popupId: 'userPopover' });

    const clickAwayHandler = () => {
        if (popupState.isOpen){
            //@ts-ignore
            document.getElementsByClassName("user-panel-detail-container")[0]?.click();
        }
    }

    return (
        <ClickAwayListener onClickAway={clickAwayHandler}>
            <div>
                <div id="user-panel">
                    <div className='user-panel-detail-container' {...bindToggle(popupState)}>
                        <Avatar className='user-panel-avatar'>{initials}</Avatar>
                        <div id='user-panel-detail'>
                            <div className="overflow-ellipsis user-panel-header" title={`${firstName} ${lastName}`}>{`${firstName} ${lastName}`}</div>
                            <div className="overflow-ellipsis user-panel-email" title={email}>{email}</div>
                        </div>
                    </div>
                    <Popper {...bindPopper(popupState)} placement="right-start" transition>
                        {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className='user-popup-panel'>
                                <div className="logout-span user-popup-panel-item" onClick={(e) => { mixpanel.track('logout'); logout(); props.setLoggedIn(false); }} >
                                    <img src="/Logout2.svg" alt="logout" style={{paddingLeft: "14px"}}/>
                                    <div>Log out</div>
                                </div>
                                <a className="user-popup-panel-policy-link user-popup-panel-item" href='https://app.getterms.io/view/BWKCr/privacy/en-us' target='_blank' rel="noreferrer" onClick={() => mixpanel.track('Privacy policy')}>Privacy Policy</a>
                            </Paper>
                        </Fade>
                        )}
                    </Popper>
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default LoginPanel;