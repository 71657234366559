import './App.css';
import Hotjar from '@hotjar/browser';
import { Routes, Route, useLocation, useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import Payslips from './components/Payslips/Payslips';
import Patients from './components/PPO/Patients/Patients';
import PatientDetails from './components/PPO/PatientDetails/PatientDetails';
import React, { useEffect, useState, Fragment} from "react";
import Login from "./components/Login/Login";
import axios from "axios";
import {registerAuth} from "./providers/login/login"
import TestConfigPage from "./components/testConfigPage/testConfigPage";
import AddUserPage from "./components/addUserPage/addUserPage";
import Layout from "./components/Layout/Layout";
import Dashboard from "./components/Dashboard/Dashboard";
import AverageRateDashboard from "./components/AverageRateDashboard/AverageRateDashboard";
import HomeSelector from "./components/HomeSelector/HomeSelector";
import DynamicConfigPage from "./components/DynamicConfig/dynamicConfig";
import Fields from "./components/Fields/fields";
import Insights from "./components/Insights/Insights";
import DeepInsights from "./components/DeepInsighs/DeepInsights";
import PolicyEdit from "./components/PolicyEdit/PolicyEdit";
import Welcome from "./components/Welcome/Welcome";
import { useDispatch } from 'react-redux';
import {getEntities} from './store/entitySlice';
import { useSelector } from 'react-redux';
import MultiPayslipApprove from './components/MultiPayslipApprove/MultiPayslipApprove';
import PPOPage from "./components/PPO/PPOPage/PPOPage";
import useUpdateUrl from "./hooks/useUpdateUrl";
import AutoCommentEdit from './components/AutoCommentEdit/AutoCommentEdit';
import Report from './components/Report/Report';
import EmployeeTurnover from "./components/EmployeeTurnover/EmployeeTurnover";
import OvertimeReport from "./components/Overtime/OvertimeReport";
import Analysis from "./components/Analysis/Analysis";
import AllFacilities from "./components/AllFacilities/AllFacilities";
import TimeAndAttendance from "./components/TimeAndAttendance/TimeAndAttendance";


const siteId = 3602695;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
mixpanel.init('3060761cb5a7594cf48494d40c11d436');


function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [showPPO, setShowPPO] = useState(false);
    
    const entities = useSelector((state) => state?.entityReducer?.entities);
    
    const dispatch = useDispatch();
    
    useUpdateUrl();
    
    useEffect(() => {
        registerAuth(() => {setLoginWrapper(false)});
        testLogin((state) => setLoginWrapper(state))
        setInterval(() => testLogin(setLoginWrapper), 600000);
        if(loggedIn && entities?.length === 0)
            dispatch(getEntities());

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [loggedIn]);

    const location = useLocation(); 
    const navigate = useNavigate();

    useEffect(() => {
        const pathIncludesUndefined = location?.pathname?.includes("/undefined");
        if(pathIncludesUndefined) {
            console.error(`path includes undefined: ${window.location.href}`);
            navigate('/');
        }
    }, [location]);

    const handleClick = (event) => {
        const trackElement = event.target.closest('[data-track]');
        if (trackElement) {
            const trackData = trackElement.getAttribute('data-track')
            const trackDataProp = trackElement.getAttribute('data-track-props')

            const props = getTrackDataProp(trackElement, trackDataProp)
            mixpanel.track(`${trackData}`, props);
        }
    };

    const getTrackDataProp = (trackElement, trackDataProp) => {
        let props = {
            elementText: trackElement.innerText,
            elementId: trackElement.id,
            elementClassName: trackElement.className
        }

        if (trackDataProp)
        {
            try {
                const trackDataProps = trackDataProp.split(',')
                for (const key in trackDataProps) {
                    const propBreakDown = trackDataProps[key].split(':')
                    props[propBreakDown[0]] = propBreakDown[1]
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        return props
    };

    function setLoginWrapper(loginState) {
        setLoggedIn(loginState);
        if (loginState) {
            testHasData(setHasData)
        }
    }


    if (!loggedIn) {
        return (<Login setLoggedIn={setLoginWrapper}></Login>)
    } else {
        if (showPPO) {
            return (<Routes>
                        <Route path="/:entity?/:cycle?" element={<Layout setShowPPO={setShowPPO} setLoggedIn={setLoginWrapper} hasData={hasData} isPPO={true} />} onChange={ logPageView }>
                            <Route index element={<PPOPage />} />
                            <Route path="*" index element={<PPOPage />} />
                            <Route path="patients" element={<Patients />} />
                            <Route path="patient" element={<PatientDetails />} />
                        </Route>
                    </Routes>
            )
        } else {
            return (
                <Routes>
                    <Route path="/:entity?/:cycle?" element={<Layout setShowPPO={setShowPPO} setLoggedIn={setLoginWrapper} hasData={hasData} isPPO={false} />} onChange={ logPageView }>
                        {
                            hasData ?
                                <Fragment>
                                    <Route index element={<HomeSelector />} />
                                    <Route path="*" index element={<HomeSelector />} />
                                    <Route index path="Overview" element={<HomeSelector />} />
                                    <Route path="dashboard" element={<Dashboard />} />
                                    <Route path="employee_dashboard" element={<AverageRateDashboard />} />
                                    <Route path="all_facilities" element={<AllFacilities />} />
                                    <Route path="time_and_attendance" element={<TimeAndAttendance />} />
                                    <Route path="report" element={<Report />} />
                                    <Route path="payslips" element={<Payslips />} />
                                    <Route path="config_tests" element={<TestConfigPage />} />
                                    <Route path="create_user" element={<AddUserPage />} />
                                    <Route path="dynamic_config" element={<DynamicConfigPage />} />
                                    <Route path="fields" element={<Fields />} />
                                    <Route path="insights" element={<Insights />} />
                                    <Route path="deep_insights" element={<DeepInsights />} />
                                    <Route path="policy_edit" element={<PolicyEdit />} />
                                    <Route path="terminations" element={<EmployeeTurnover />} />
                                    <Route path="overtime" element={<OvertimeReport />} />
                                    <Route path="AutoComment_edit" element={<AutoCommentEdit />} />
                                    <Route path="welcome" element={<Welcome />} />
                                    <Route path="approve_payslips" element={<MultiPayslipApprove />} />
                                    <Route path="analysis" element={<Analysis />} />
                                </Fragment>
                                :
                                <Fragment>
                                    <Route path="*" index element={<HomeSelector />} />
                                    <Route path="config_tests" element={<TestConfigPage />} />
                                    <Route path="fields" element={<Fields />} />
                                </Fragment>
                        }
                    </Route>
                </Routes>
            );
        }
    }
}

const logPageView = (prevState, nextState, replace, ) => {
    mixpanel.track('PageView', {page: nextState.location.pathname});
}

export default App;

function testHasData(setHasData) {
    axios('/api/payslips/cycles', {
        credentials: 'include'
    }).then((res) => {
        if (res.status === 200 || res.status === 201) {
            if (res.data.length > 1) {
                setHasData(true);
                return;
            }
        }
        setHasData(false)
    }).catch((err) => {
        console.log(err);
        setHasData(false);
    });
}

function testLogin(setLoggedIn) {
    axios('/api/users/me', {
        credentials: 'include'
    }).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
            setLoggedIn(true);
            if(res?.data?.is_superuser) localStorage.setItem('is_superuser', "true");
            localStorage.setItem('user_role', res?.data?.role);
            localStorage.setItem("userData", JSON.stringify({id: res?.data?.id, superUser: res?.data?.is_superuser, first_name: res?.data?.first_name, email: res?.data?.email, last_name: res?.data?.last_name}));
        } else {
            setLoggedIn(false);
        }
    }).catch((err) => {
        console.log(err);
        setLoggedIn(false);
    });
}