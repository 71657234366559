
export class Converter {
    id: string | undefined;
    name: string | undefined;
    description: string | undefined;
    default_config: any | undefined;
}


export class FieldsGenerationConfigOptions {
    current_converter_id: string | undefined;
    current_cycle_frequency: number | undefined;
    cycle_frequency: number[] = [];
    converters: Converter[] = [];
}

export class FieldsGenerationSettings {
    converter_id: string | undefined;
    converter_config: any | undefined;
    cycle_frequency: number | undefined;

    constructor(converter_id: string,
                converter_config: any,
                cycle_frequency: number) {
        this.converter_id = converter_id;
        this.converter_config = converter_config;
        this.cycle_frequency = cycle_frequency;
    }
}