import {Outlet, useLocation} from "react-router-dom";
import './Layout.css';
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useSelector } from "react-redux";


function Layout(props: any) {
    const { width, height } = useWindowSize();
    const location = useLocation();

    const path = location?.pathname?.split('/');
    // const isConfettiPath = path.length > 2 && (path[3] === 'report' || path[3] === 'payslips') ? true : false;
    const isConfettiPath = false;
    const widthConfetti = isConfettiPath ? width : 0;
    const heightConfetti = isConfettiPath ? document.body.scrollHeight : 0;

    const showConfetti = useSelector((state: any) => state?.PayslipsViewReducer?.showConfetti);

    function drawStar(ctx: any) {
        //@ts-ignore
        const numPoints = this.numPoints || 4
        //@ts-ignore
        this.numPoints = numPoints
        //@ts-ignore
        const outerRadius = this.w
        const innerRadius = outerRadius / 2
        ctx.beginPath()
        ctx.moveTo(0, 0 - outerRadius)
        
        for(let n = 1; n < numPoints * 2; n++) {
            const radius = n % 2 === 0 ? outerRadius : innerRadius
            const x = radius * Math.sin((n * Math.PI) / numPoints)
            const y = -1 * radius * Math.cos((n * Math.PI) / numPoints)
            ctx.lineTo(x, y)
        }
        ctx.fill()
        ctx.closePath()
    }    

    const selectedCycle = useSelector((state: any) => state?.entityReducer?.selectedCycle);
    
    return (
        <div className="layout">
            <div className="wrapper">
                <div className="sidebar-area">
                    <Sidebar hasData={props.hasData} isPPO={props.isPPO} setShowPPO={props.setShowPPO} setLoggedIn={props.setLoggedIn}></Sidebar>
                </div>
                <div className="header">
                    <PageHeader isPPO={props.isPPO} setShowPPO={props.setShowPPO}></PageHeader>
                </div>
                <div className="content-area">
                    <Confetti
                        id="confetti"
                        key={showConfetti}
                        width={widthConfetti * 0.8}
                        height={heightConfetti}
                        drawShape={drawStar}
                        recycle={false}
                        run={showConfetti > 0 ? true : false}
                        style={{zIndex: 1400}}
                    />
                    <Outlet key={selectedCycle}/>
                </div>
            </div>
        </div>
    );
}

export default Layout;