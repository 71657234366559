import { useNavigate } from "react-router-dom";
import PayslipBubble from "../Payslips/PayslipBubble";
import useCustomParams from "../../hooks/useCustomParams";
import { formatNumber} from "../../utils/PresentationUtils";

interface ValueSummaryItemProps {
    sum: number;
    numberOfEmployees: number;
    severity: number;
    totalChange: number;
}

const ValueSummaryItem = (props: ValueSummaryItemProps) => {
    const navigate = useNavigate();
    const { entity, cycle } = useCustomParams();

    const severity = (severity: number) => {
        switch (severity) {
            case 1:
                return <PayslipBubble severity={1}/>;
            case 2:
                return <PayslipBubble severity={2}/>; 
            case 3:
                return <PayslipBubble severity={3}/>;  
            case 4:
                return <PayslipBubble severity={3}/>;
            default:
                return;
        }
    };

    const itemColor = () => {
        switch (props?.severity) {
            case 1:
                return '#EF525B';
            case 2:
                return '#EFBA52'; 
            case 3:
                return '#1ACEA3';  
        }
    }

    return (
      <div>
        <div className='value-summary-item' style={{color: `${itemColor()}`, borderBottom: `4px solid ${itemColor()}`}} onClick={() => navigate(`/${entity}/${cycle}/report`)}>
            <span className='value-bubble'>{severity(props?.severity)}</span>
            <div className="value-sum-section">
                <span className='value-sum'>${formatNumber(props?.sum)}</span>
                <span className='value-sum-percent'>({Number(parseFloat(props?.totalChange?.toString()).toFixed(1))}%)</span>
            </div>
            <div className='value-employee-section'>
                <span>{formatNumber(props?.numberOfEmployees)} Employees </span>
            </div>
        </div>
      </div>
    );
  };
  
  export default ValueSummaryItem;