import ReactModal from "react-modal";
import "./SimpleCommentModal.css";
import { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import EmailAutoComplete from "./EmailAutoComplete";


interface SimpleCommentModalProps {
  open: boolean;
  setIsModalOpen: (isOPen: boolean) => void;
  apiCall: (comment: string, emails: Array<string>) => void;
}

const SimpleCommentModal = (props: SimpleCommentModalProps) => {
  const [textInputValue, setTextInputValue] = useState('');
  const [inputEmailValue, setInputEmailValue] = useState([]);

  const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "664px",
          height: "400px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        overlay: {
          backgroundColor: "#102E442E",
          backdropFilter: "blur(4px) brightness(100%)",
        }
  };

  const onSaveComment = () => {
    if(inputEmailValue){
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      for (let i = 0; i < inputEmailValue?.length; i++) {
        if (!emailRegex.test(inputEmailValue[i])) {
          alert('Please enter valid email');
          return;
        }
      }
    }

    props?.apiCall(textInputValue, inputEmailValue);
    props?.setIsModalOpen(false);
  }

  useEffect(() => {
    setTextInputValue("");
  }, [props?.open]);

  return (
    <div>
      <ReactModal
          ariaHideApp={false}
          isOpen={props?.open}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => props?.setIsModalOpen(false)}
      >
        <div>
          <div className="SimpleCommentModal-header-row">
            <div className="comment-label">Add Comment</div>
            <img src="/comment-close.svg" alt="close" className="SimpleCommentModal-btn-x" onClick={() => props?.setIsModalOpen(false)} />
          </div>
          <textarea className="SimpleCommentModal-input comment-text-area" placeholder="Write your comment here..." value={textInputValue} onChange={(e) => setTextInputValue(e.target.value)}/>
          <div>
              <label className='comment-email-label SimpleCommentModal-email-label'>Send email to</label>
              <AiOutlineQuestionCircle title="Emails will be sent to the recipients, they will be able to reply to the email and it will be added as a comment on the payslip."/>
          </div>
          <EmailAutoComplete open={true} selectedEmails={inputEmailValue} setSelectedEmails={setInputEmailValue} />
          <div className="SimpleCommentModal-btn-container">
            <button className="SimpleCommentModal-btn" onClick={onSaveComment}>Add Comment</button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default SimpleCommentModal;