import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import closeIcon from "./icon.svg";
import uploadStatusIcon from "./uploaded.svg";
import { CircularProgress } from "@mui/material";
import "./UploadStatusModal.css";
import UploadNeverFailMessage from "../UploadErrorMessage/UploadNeverFailMessage";
import useCustomParams from "../../hooks/useCustomParams";
import mixpanel from "mixpanel-browser";

const UploadStatusModal = (props: {isTriggered: boolean, hasMultipleEntity: boolean,
                                    selectedEntityName: string, selectedEntityID: string,
                                    uploadProgress: any, setIsTriggered: Function,
                                    retryUpload: Function, setUploadProgress: Function}) =>
{
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [viewPayrollLoader, setViewPayrollLoader] = useState(false);
  const [uploadUpdateStackEnd, setUploadUpdateStackEnd] = useState(false);
  const [newCycle, setNewCycle] = useState("");
  const { entity, cycle } = useCustomParams();
  const [clearedEmployees, setClearedEmployees] = useState(0);
  const [nonclearedEmployees, setNonclearedEmployees] = useState(0);

  const checkUploadStatus = async () => {
    try {
      const response = await axios.get('/api/upload_update');
      if (response?.data?.status === 500) {
        setIsOpenError(true);
        mixpanel.track('Upload failed', {'source':'server error'});
      }
      if (response?.data?.status === 200) {
        const newDateApiCall = await axios.get('/api/payslips/cycles');
        const newDate = newDateApiCall?.data[0];
        setNewCycle(newDate);
        setButtonEnabled(true);
        setViewPayrollLoader(false);
        await loadUploadSuccessInfo();
        mixpanel.track('Upload success');
      }
    } catch (error) {
      console.error('Error fetching upload updates:', error);
    }
  }

  useEffect(() => {
    let intervalId: any;
    if(props.isTriggered === true){
      axios.patch("/api/upload_update");
      setIsOpenSuccess(true);
      intervalId = setInterval(checkUploadStatus, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
  },[props, props.setIsTriggered]);

  useEffect(() => {
    if(isOpenError === true){
      setIsOpenSuccess(false);
    }
  },[isOpenSuccess, isOpenError]);

  const loadUploadSuccessInfo = async () => {
    try
    {
      const data = await axios.get(`/api/upload_update/${props.selectedEntityID}/latest_upload_success_info`);

      setClearedEmployees(data?.data?.employees_number_without_finding)
      setNonclearedEmployees(data?.data?.employees_number_with_finding)
    } catch (e)
    {
      console.log(e);
    }
  };
  
  const [height, setHeight] = useState(getResponsiveHeight());

  function getResponsiveHeight() {
    const screenHeight = window.innerHeight;
    if (screenHeight < 400) {
      return '300px';
    } else if (screenHeight < 600) {
      return '450px';
    } else if (screenHeight < 800) {
      return '600px';
    } else {
      return '750px';
    }
  }

  useEffect(() => {
    function handleResize() {
      setHeight(getResponsiveHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "664px",
          height: height,
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "10px"
        },
        overlay: {
          backgroundColor: "#102E442E",
          backdropFilter: "blur(4px) brightness(100%)",
        }
  };
    
  function closeHandler(reload:boolean = true) {
    setIsOpenError(false);
    setIsOpenSuccess(false);
    axios.patch("/api/upload_update");
    props.setIsTriggered(false);
    if(reload)
    {
      window.location.reload();
    }
    else
    {
      let newUploadProgress = {...props?.uploadProgress};
      delete newUploadProgress[props?.selectedEntityID]
      props?.setUploadProgress(newUploadProgress);
    }
  }

  const uploadUpdateStackEndSession = () => {
    setViewPayrollLoader(true);
    setUploadUpdateStackEnd(true);
  };

  const element = document.querySelector('.view-payroll-btn');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }

  if(isOpenError){
    return (
        <UploadNeverFailMessage closeDialogHandler={closeHandler} ></UploadNeverFailMessage>
    );
  }

  if(isOpenSuccess){
    return (
      <div>
        <ReactModal
            ariaHideApp={false}
            isOpen={isOpenSuccess}
            style={customStyles}
            shouldCloseOnOverlayClick={buttonEnabled}
            //@ts-ignore
            onRequestClose={closeHandler}
        >
          <div className="success-modal">
            <h1 className="success-modal-header">Processing Files ...</h1>
            <img src={closeIcon as unknown as string} className="close-icons" onClick={() => closeHandler()}
                 alt='close'/>
          </div>
          <UploadUpdateStack endSessionCallBack={uploadUpdateStackEndSession} />
          <CircularProgress style={{ opacity: `${viewPayrollLoader && !buttonEnabled? '1': '0'}`, marginTop: '10px', marginLeft: '25px' }} color="inherit"/>
          <div className="upload-success-section" style={{ opacity: `${uploadUpdateStackEnd && buttonEnabled && nonclearedEmployees > 0 ? '1': '0'}` }}>
            <div>
              <span className="upload-success-percentage">{nonclearedEmployees}</span>
            </div>
            <div className="upload-success-message">
              <div>
                Great news! Celery cleared {clearedEmployees} employees
              </div>
              <div>
                Only {nonclearedEmployees} left to check.
              </div>
            </div>
          </div>
          <div>
            <a className="view-payroll-btn" href={`/${entity}/${newCycle}/home`} style={{
              textDecoration: 'none',
              pointerEvents: `${uploadUpdateStackEnd && buttonEnabled ? 'all' : 'none'}`,
              borderRadius: '6px',
              background: `${uploadUpdateStackEnd && buttonEnabled ? '#02BBE3' : '#F3F5F8'}`,
              color: `${uploadUpdateStackEnd && buttonEnabled ? '#fff' : '#B5C1CA'}`,
              float: 'right',
              padding: '8px 12px',
              marginRight: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px'
            }}>Proceed to review</a>
          </div>
        </ReactModal>
      </div>
    );
  } else {
    return (
        <>
        </>
    );
  }
}

export default UploadStatusModal;

const UploadUpdateStack = (props: { endSessionCallBack: Function }) => {
  const milliseconds = 2000;

  const [showReadyIcon, setShowReadyIcon] = useState(false);
  const [showReadyIcon2, setShowReadyIcon2] = useState(false);
  const [showReadyIcon3, setShowReadyIcon3] = useState(false);
  const [showReadyIcon4, setShowReadyIcon4] = useState(false);
  const [showReadyIcon5, setShowReadyIcon5] = useState(false);
  const [showReadyIcon6, setShowReadyIcon6] = useState(false);

  setTimeout(() => {
    setShowReadyIcon(true);
  }, 0);

  setTimeout(() => {
    setShowReadyIcon2(true);
  }, milliseconds);

  setTimeout(() => {
    setShowReadyIcon3(true);
  }, milliseconds * 2);

  setTimeout(() => {
    setShowReadyIcon4(true);
  }, milliseconds * 3);

  setTimeout(() => {
    setShowReadyIcon5(true);
  }, milliseconds * 4);

  setTimeout(() => {
    setShowReadyIcon6(true);
  }, milliseconds * 5);

  setTimeout(() =>
  {
    props.endSessionCallBack();
  }, milliseconds * 6 + 1);

  return (
    <div className="uploadUpdateStackStatus">  
      <UploadUpdateRow message={"Calculating Celery AI Tests..."} toDisplay={showReadyIcon} delay={milliseconds} />
      <UploadUpdateRow message={"Calculating Fraud Activity Tests..."} toDisplay={showReadyIcon2} delay={milliseconds}/>
      <UploadUpdateRow message={"Calculating Anomaly Detection Tests..."} toDisplay={showReadyIcon3} delay={milliseconds}/>
      <UploadUpdateRow message={"Calculating Statistical Tests..."} toDisplay={showReadyIcon4} delay={milliseconds}/>
      <UploadUpdateRow message={"Calculating Completeness Tests..."} toDisplay={showReadyIcon5} delay={milliseconds}/>
      <UploadUpdateRow message={"Calculating Compliance Tests..."} toDisplay={showReadyIcon6} delay={milliseconds}/>
    </div>
  );
}

const UploadUpdateRow = (props: {message: string, toDisplay: boolean, delay: number}) => {
  const [showReadyIcon, setShowReadyIcon] = useState(false);

  if(props.toDisplay)
  {
    setTimeout(() => {
      setShowReadyIcon(true);
    }, props.delay);
  }

  return (
    <div style={{ display: `${props.toDisplay ? 'flex': 'none'}`, width: '600px', height: '45px', margin: '14px', padding: '6px 12px', alignItems: 'center', flexShrink: 0, borderRadius: '8px', background: '#F3F5F8' }}>
      {showReadyIcon ? <img src={uploadStatusIcon as unknown as string} alt="stage done status" style={{ marginRight: `10px`, height: '30px' }} />
                      : <CircularProgress color="inherit" size={25} style={{ marginRight: `10px` }} />}
      <div>{props.message}</div>
    </div>
  );
}