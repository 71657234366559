import './Analysis.css';
import {useEffect, useRef, useState} from "react";
import useCustomParams from '../../hooks/useCustomParams';
import axios from "axios";
import { TbTable } from "react-icons/tb";
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from "@mui/material/MenuItem";
import DataTable from "react-data-table-component";
import {AnalysisFiltersAndSelector} from "./AnalysisFiltersAndSelector";
import {Dictionary} from "../../utils/string.utils";
import {LinearProgress, CircularProgress, Box, Typography, Backdrop} from '@mui/material';
import ExportBtn, { Download } from "../ExportBtn/ExportBtn";
import Tooltip from "@mui/material/Tooltip";

function Analysis (props: any){
    const latestController = useRef<AbortController | null>(null);
    const { entity, cycle } = useCustomParams();

    const [progress, setProgress] = useState(0);
    const [displayLoader, setDisplayLoader] = useState<boolean>(false);

    const [analysisData, setAnalysisData] = useState<any[]>([]);
    const [analysisColumns, setAnalysisColumns] = useState<any[]>([]);

    const [earningTotalCodesFilter, setEarningTotalCodesFilter] = useState<Dictionary<string>>({});
    const [earningAmountCodesFilter, setEarningAmountCodesFilter] = useState<Dictionary<string>>({});

    const [selectedDateFilter, setSelectedDateFilter] = useState<string>('');
    const [selectedGroupBySelector, setSelectedGroupBySelector] = useState<string>("");
    const [selectedValueTypeSelector, setSelectedValueTypeSelector] = useState<string>("");
    const [selectedEntitiesFilter, setSelectedEntitiesFilter] = useState<string[]>([]);
    const [selectedEarningCodesFilter, setSelectedEarningCodesFilter] = useState<string[]>([]);

    const [groupBySelectors, setGroupBySelectors] = useState<Dictionary<string>>({});
    const [valueTypeSelectors, setValueTypeSelectors] = useState<Dictionary<string>>({});
    const [entitiesFilter, setEntitiesFilter] = useState<Dictionary<string> | null>(null);
    const [earningCodesFilter, setEarningCodesFilter] = useState<Dictionary<string>>({});

    const [propertyMapping, setPropertyMapping] = useState<Dictionary<string>>({});

    // Get the current date
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD

    // Calculate the date 3 months ago
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split('T')[0];

    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController.current) {
            latestController.current.abort();
        }

        // Create a new controller for the current request
        const controller = new AbortController();
        latestController.current = controller;

        getAnalysisFilters(controller);

        return () => {
            // Clean up the controller on component unmount or date change
            controller.abort();
        };
    }, [entity, cycle]);


    const getAnalysisFilters = (controller: AbortController) => {
        const callConfig = controller ? {signal: controller?.signal} : undefined;
        axios.get(`/api/account/analysis/filters`, callConfig).then((res: any) => {
            setAnalysisFiltersAndSelectors(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const setAnalysisFiltersAndSelectors = (filtersAndSelectors: AnalysisFiltersAndSelector) => {
        if (filtersAndSelectors.selectors?.group_by_selectors != null)
        {
            setGroupBySelectors(filtersAndSelectors.selectors?.group_by_selectors);
            setSelectedGroupBySelector(filtersAndSelectors.selectors?.group_by_selectors["entity"]);
        }
        if (filtersAndSelectors.selectors?.value_type_selectors != null)
        {
            setValueTypeSelectors(filtersAndSelectors.selectors?.value_type_selectors);
            setSelectedValueTypeSelector(filtersAndSelectors.selectors?.value_type_selectors["total"])
        }
        if (filtersAndSelectors.filters?.entities != null)
        {
            setEntitiesFilter(filtersAndSelectors.filters?.entities);
            setSelectedEntitiesFilter([filtersAndSelectors.filters?.entities["all"]])
        }
        if (filtersAndSelectors.filters?.earning_total_codes != null)
        {
            setEarningTotalCodesFilter(filtersAndSelectors.filters.earning_total_codes);
        }
        if (filtersAndSelectors.filters?.earning_amount_codes != null)
        {
            setEarningAmountCodesFilter(filtersAndSelectors.filters.earning_amount_codes);
        }

        updateSelectedEarningCodesFilter(
            (filtersAndSelectors.selectors?.value_type_selectors["total"] ?? ""),
            (filtersAndSelectors.selectors?.value_type_selectors ?? {}),
            (filtersAndSelectors.filters?.earning_total_codes ?? {}),
            (filtersAndSelectors.filters?.earning_amount_codes ?? {})
        );
        setSelectedDateFilter(formattedThreeMonthsAgo);
    }

    const updateSelectedEarningCodesFilter = (valueTypeSelector: string ,valueTypeSelectors: Dictionary<string>,
                                              earningTotalCodes: Dictionary<string>, earningAmountCodes: Dictionary<string>) => {
        if (valueTypeSelector === valueTypeSelectors["total"])
        {
            setEarningCodesFilter(earningTotalCodes);
            setSelectedEarningCodesFilter([earningTotalCodes["all"]])
        }
        else {
            setEarningCodesFilter(earningAmountCodes);
            setSelectedEarningCodesFilter([earningAmountCodes["all"]])
        }
    }

    const handleValueTypeSelectorChange = (event: SelectChangeEvent<typeof selectedValueTypeSelector>) => {
        const {
            target: { value },
        } = event;
        setSelectedValueTypeSelector(value);
        updateSelectedEarningCodesFilter(value, valueTypeSelectors, earningTotalCodesFilter, earningAmountCodesFilter);
    };

    const handleGroupBySelectorChange = (event: SelectChangeEvent<typeof selectedGroupBySelector>) => {
        const {
            target: { value },
        } = event;
        setSelectedGroupBySelector(value);
    };

    const handleEntitiesFilterChange = (event: SelectChangeEvent<typeof selectedEntitiesFilter>) => {
        const {
            target: { value },
        } = event;
        setSelectedEntitiesFilter(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleEarningCodesFilterChange = (event: SelectChangeEvent<typeof selectedEarningCodesFilter>) => {
        const {
            target: { value },
        } = event;
        setSelectedEarningCodesFilter(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDateChange = (event: any) => {
        setSelectedDateFilter(event.target.value);
    };

    const updateProgressTimeout = (progressNum: number) => {
        setTimeout(() => {
            if(progressNum >= 92)
                return;

            progressNum = progressNum + 2;

            updateProgress(progressNum + 2);
            updateProgressTimeout(progressNum + 2);
        }, 1000);
    };

    const updateProgress = (progressNum: number) => {
        if(progressNum >= 92)
            return;

        setProgress(progressNum);
    };

    const runAnalysis = () =>
    {
        setProgress(0);
        setDisplayLoader(true);

        const dataRequest = {
            start_date: selectedDateFilter,
            group_by_selector: getGroupBySelectorKey(),
            value_type_selector: getValueTypeSelectorKey(),
            entities_filters: getEntitiesFilterKeys(),
            earning_codes_filters: getEarningCodesFilterKeys()
        };
        const config = {
            headers:{'Content-Type': 'application/json'},
            onUploadProgress: (progressEvent: any) => {
                updateProgressTimeout(80);
            }
        };

        axios.post(`/api/account/analysis/execute_report`, dataRequest, config).then((res) => {
            const data = res.data
            setProgress(100);
            setAnalysisTableColumns(data.headers);
            setAnalysisData(data.data);
            setDisplayLoader(false);
        }).catch((err) => {
            setDisplayLoader(false);
            console.log(err)
        });
    };

    const downloadAllData = () => {
        axios.get(`/api/account/analysis/raw_data/${selectedDateFilter}`).then((res) => {
            const raw_data_res = res.data
            Download(raw_data_res.data,null,"CeleryAllDataExport.csv", null, null, null, raw_data_res.headers);
        }).catch((err) => {
            setDisplayLoader(false);
            console.log(err)
        });
    };


    const getGroupBySelectorKey = () => {
        for (const key in groupBySelectors)
        {
            if(groupBySelectors[key] === selectedGroupBySelector)
                return key
        }
        return null
    };

    const getValueTypeSelectorKey = () => {
        for (const key in valueTypeSelectors)
        {
            if(valueTypeSelectors[key] === selectedValueTypeSelector)
                return key
        }
        return null
    };

    const getEntitiesFilterKeys = () => {
        const entitiesFilterKeys : string[] = [];
        for (const index in selectedEntitiesFilter)
        {
            const selectedValue = selectedEntitiesFilter[index]
            for (const entitiesFilterKey in entitiesFilter) {
                if(entitiesFilter[entitiesFilterKey] === selectedValue) {
                    entitiesFilterKeys.push(entitiesFilterKey)
                    break;
                }
            }
        }

        return entitiesFilterKeys
    };

    const getEarningCodesFilterKeys = () => {
        const earningCodesFilterKeys : string[] = [];
        for (const index in selectedEarningCodesFilter)
        {
            const selectedValue = selectedEarningCodesFilter[index]
            for (const earningCodeFilterKey in earningCodesFilter) {
                if(earningCodesFilter[earningCodeFilterKey] === selectedValue) {
                    earningCodesFilterKeys.push(earningCodeFilterKey)
                    break;
                }
            }
        }

        return earningCodesFilterKeys
    };

    const setAnalysisTableColumns = (headers: string[]) => {
        const columns: any[] = [];
        const newPropertyMapping: Dictionary<string> = {};

        for (const index in headers)
        {
            const header = headers[index];

            newPropertyMapping[header] = header;
            if (index === '0')
            {
                columns.push({
                    name: header,
                    selector: (row: any) => row[header],
                    sortable: true
                })
            }
            else {
                columns.push({
                    name: header,
                    selector: (row: any) => parseFloat(row[header] ?? 0),
                    cell: (row: any) => (row[header] ?? 0).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}),
                    sortable: true
                })
            }
        }

        setAnalysisColumns(columns);
        setPropertyMapping(newPropertyMapping);
    };

    const conditionalRowStyles = [
        {
            when: (row: any) => true,
            style: {
                fontWeight: 700,
                color: 'var(--warm-black)',
                fontSize: '14px',
                letterSpacing: '-0.35px',
                lineHeight: '20px',
                whiteSpace: 'nowrap',
                backgroundColor: 'var(--cultured)',
                borderRadius: '8px',
                height: '40px',
                marginBottom: '12px',
            }
        },
    ];

    return (
            entitiesFilter == null ?
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
            :
                <div className="analysis">
                    <div className="analysis-header">
                        <TbTable className='analysis-header-icon'/>
                        <span className='analysis-header-txt'>Analysis</span>
                    </div>
                    <div className="analysis-filters">
                        <label id="group-by-filters-text" htmlFor="group-by-filters">Rows by</label>
                        <Select id="group-by-filters"
                                value={selectedGroupBySelector}
                                onChange={handleGroupBySelectorChange}
                                className="earning-codes-group-by-filters analysis-filter-selection">
                            {Object.entries(groupBySelectors).map(([key, value], i) => (
                                <MenuItem selected={selectedGroupBySelector === value} className='option-design'
                                          key={"f_" + i}
                                          value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                        <label id="value-type-filters-text" htmlFor="value-type-filters">Values</label>
                        <Select id="value-type-filters"
                                value={selectedValueTypeSelector}
                                onChange={handleValueTypeSelectorChange}
                                className="result-value-type-filters analysis-filter-selection">
                            {Object.entries(valueTypeSelectors).map(([key, value], i) => (
                                <MenuItem selected={selectedValueTypeSelector === value} className='option-design'
                                          key={"f_" + i} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                        <img src='/filter.svg' alt='filter' className='analysis-filter-icon'/>
                        <Select
                            multiple
                            value={selectedEarningCodesFilter}
                            onChange={handleEarningCodesFilterChange}
                            renderValue={(selected) => selected.join(', ')}
                            className="earnings-selection-filters analysis-filter-selection"
                        >
                            {Object.entries(earningCodesFilter).map(([key, value], i) => (
                                <MenuItem key={key} value={value}>
                                    <Checkbox checked={selectedEarningCodesFilter.indexOf(value) !== -1}/>
                                    <ListItemText primary={value}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <img src='/filter.svg' alt='filter' className='entities-selection-filter-icon'/>
                        <Select
                            multiple
                            value={selectedEntitiesFilter}
                            onChange={handleEntitiesFilterChange}
                            renderValue={(selected: string[]) => selected.join(', ')}
                            className="entities-selection-filters analysis-filter-selection"
                        >
                            {Object.entries(entitiesFilter).map(([key, value], i) => (
                                <MenuItem key={key} value={value}>
                                    <Checkbox checked={selectedEntitiesFilter.indexOf(value) !== -1}/>
                                    <ListItemText primary={value}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <label id="date-filters-text" htmlFor="datePicker">Cycles since</label>
                        <input
                            type="date"
                            id="datePicker"
                            value={selectedDateFilter}
                            onChange={handleDateChange}
                            min={formattedThreeMonthsAgo}
                            max={formattedCurrentDate}
                            className='date-filter analysis-filter-selection'
                        />
                        <button className="execute-report-btn" onClick={() => runAnalysis()}>Run</button>
                        <Tooltip title="Download all payslips from the selected date range">
                            <button className="export-raw-data-btn" onClick={() => downloadAllData()}>Download All Payslips </button>
                        </Tooltip>
                        <ExportBtn data={analysisData} filename={"CeleryAnalysisExport.csv"}
                                   propertyMapping={propertyMapping}></ExportBtn>
                    </div>
                    {!displayLoader &&
                        <div className="analysis-table">
                            <DataTable
                                conditionalRowStyles={conditionalRowStyles}
                                columns={analysisColumns}
                                data={analysisData}
                                defaultSortFieldId={2}
                                defaultSortAsc={false}
                                theme="default"
                                responsive
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50]}
                            />
                        </div>
                    }
                    {displayLoader &&
                        <div className="analysis-table-loader">
                            <Box sx={{width: '50%', marginTop: 1}}>
                                <Typography variant="body2" color="textSecondary">
                                    Processing request... {progress}%
                                </Typography>
                                <LinearProgress variant="determinate" value={progress}/>
                            </Box>
                        </div>
                    }

                </div>
    );
}


export default Analysis;
