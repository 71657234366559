import './ExportBtn.css';
import {DownloadJSONAsCSV, GetAndDownloadJSONAsCSV} from "../../utils/DownloadCSV";
import axios from "axios";

const ExportBtn = (props) => (
  <div data-track='Export all employees' className="ExportBtn" data-testid="ExportBtn"  onClick={() => Download(props.data, props.url, props.filename, props.entity, props.cycle, props.filter, props?.propertyMapping)}>{props?.text ?? "Export"}</div>
);

export function Download(data , url, filename, entity, cycle, filter, propertyMapping = null) {
    if ((data === null || data === undefined || data.length === 0) &&
        (url === null || url === undefined || url === ''))
        return;

    if ((filter === 'All' || filter === '') && entity && cycle) {
        handleDownloadAll(entity, cycle)
    }
    else if (data) {
        DownloadJSONAsCSV(data, filename, propertyMapping)
    } else {
        GetAndDownloadJSONAsCSV(url, filename)
    }
}

const handleDownloadAll = (entity, cycle) => {
    axios({
        url: `/api/payslips/${entity}/${cycle}/export/all?filter=All`,
        method: 'GET',
        responseType: 'blob',
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const headerNamePart = response.headers['content-disposition'];
        let startIndex = headerNamePart.indexOf("filename=") + 9; // 9 is the length of "filename="
        let filename = headerNamePart.substring(startIndex).replaceAll("\"", "");

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    })
    .catch((error) => {
        console.error('Error downloading the file:', error);
    });
};

export default ExportBtn;