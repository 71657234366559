export const toNameCase = (fullName: string):string => {
    return fullName ? fullName
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      : 
      "";
};

export interface Dictionary<T> {
    [Key: string]: T;
}