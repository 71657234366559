const PayslipBubble = (props: {severity: number, shortTxt?: boolean}) => {

    const configure = () => {
      switch (props?.severity) {
        case 1: 
          return {color: '#E1565E', text: `High ${props?.shortTxt ? '' : 'Risk'}`};
        case 2:
          return {color: '#EFBA52', text: `Medium ${props?.shortTxt ? '' : 'Risk'}`};
        case 3:
          return {color: '#1ACEA3', text: `Low ${props?.shortTxt ? '' : 'Risk'}`};
    }};

    const params = configure();

    return (
      <div className={`payslipBubble ${props?.shortTxt ? 'payslipBubble-small' : ""}`} style={{backgroundColor: params?.color}}>
        {params?.text}
      </div>
    );
  };
  
  export default PayslipBubble;