import './Welcome.css';
import React from "react";
import {FaArrowAltCircleLeft, FaArrowAltCircleUp} from "react-icons/fa";

function Welcome (props: any){
    return (
        <div className="WelcomeWrapper">
            <div className="HomeNewInsightItem">
                <div className="HomeNewInsightItemIcon">{<FaArrowAltCircleUp/>}</div>
                <div className="HomeNewInsightItemText">
                    <div className="HomeNewInsightItemTextTop">
                            Welcome to Celery!
                    </div>
                    <div className="HomeNewInsightItemTextBottom">
                        Start by uploading your payroll register.
                    </div>
                </div>
                <div className="entity-portal-select-btn">
                    Upload Payroll Register
                </div>
            </div>
        </div>
    );
}


export default Welcome;
