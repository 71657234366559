import './EmployeeTurnover.css';
import {useEffect, useState} from "react";
import useCustomParams from '../../hooks/useCustomParams';
import EmployeeChanges from "./EmployeeChanges/EmployeeChanges";
import {EmployeesDashboard} from "./EmployeesDashboard"
import axios from "axios";
import {useSelector} from "react-redux";
import { MdOutlineGroupRemove } from "react-icons/md";
import {formatDate} from "../../utils/PresentationUtils";

function EmployeeTurnover (props: any){
    const { entity, cycle } = useCustomParams();
    const [employeeData, setEmployeeData] = useState<EmployeesDashboard>(new EmployeesDashboard());
    const last_cycle = useSelector((state: any) => state?.entityReducer?.selectedEntityObj?.cycles[0]);

    useEffect(() => {
        getDashboardData();
    }, [entity, cycle]);


    const getDashboardData = () => {
        axios.get(`/api/employees/${entity}/${cycle}/dashboard`).then((res) => {
            if (res.data) {
                setEmployeeData(res.data)
            } else {
                setEmployeeData(new EmployeesDashboard())
            }
        }).catch((err) => {
            console.log(err);
        })
    }



    return (
        <div className="employee-turnover">
            <div className="Grid0">
                <div className="employee-turnover-header">
                    <MdOutlineGroupRemove className='employee-turnover-header-icon' />
                    <span className='employee-turnover-header-txt'>Terminations</span>
                </div>
            </div>
            <div className="Grid1">
                <EmployeeChanges type='terminated' id={"terminated-employees"} text={`Terminated Employees (Last Updated: ${formatDate(last_cycle, false, false, true)})`}
                                 data={employeeData.terminated}></EmployeeChanges>
            </div>
        </div>
    );
}


export default EmployeeTurnover;
