import './PolicyEdit.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {IoIosCheckmarkCircleOutline} from "react-icons/io";
import {Switch} from "@mui/material";
import {MdOutlinePolicy} from "react-icons/md";
import {BsTrash3} from "react-icons/bs";
import {GoPencil} from "react-icons/go";
import {FiPlus} from "react-icons/fi";
import Modal from '@mui/material/Modal';
import useCustomParams from '../../hooks/useCustomParams';

function PolicyEdit (props: any){
    const { entity, cycle } = useCustomParams();
    const [configData, setConfigData] = useState<any>([]);
    const [modalOepn, setModalOpen] = useState<any>(false);
    const [newPolicyTitle, setNewPolicyTitle] = useState<any>('');
    const [newPolicyDesc, setNewPolicyDesc] = useState<any>('');

    const userRole = localStorage.getItem('user_role');

    useEffect(() => {
        getConfigData(setConfigData);
    }, [entity, cycle])

    function addPolicy() {
        const currPolicy = [...configData]

        currPolicy.push({
            'title': newPolicyTitle,
            'checked': true,
            'sub_title': newPolicyDesc
        });

        setConfigData(currPolicy);


        closePopup()
    }

    function closePopup() {
        setNewPolicyTitle('')
        setNewPolicyDesc('')
        setModalOpen(false);
    }


    return (
        <div>
            <div className="home-header">
                <MdOutlinePolicy/>
                <span className='home-header-txt'>Your Policies</span>
            </div>
            <div className="PolicyEditWrapper">
                <div className="PolicyEditTitle">
                    <span className="home-header-txt">Policies List</span>
                    {userRole == 'Demo' &&
                        <div className="PolicyEditTitlePlus">
                            <FiPlus onClick={() => setModalOpen(true)} />
                        </div>
                    }

                </div>
                <div className="PolicyEditBody">

                    {
                        configData.map((policyData: any) => {
                            return renderPolicyLine(policyData);
                        })
                    }
                </div>
            </div>
            <Modal
                open={modalOepn}
                onClose={closePopup}>
                <div className={"PolicyEditModal"}>
                    <div className={"PolicyEditModalTitle"}>Add New Policy</div>
                    <div className={"PolicyEditModalSubTitle"}>Create your own policies, to get relevant alerts and insights</div>
                    <input className={"PolicyEditModalInput"} value={newPolicyTitle} onChange={e => setNewPolicyTitle(e.target.value)} placeholder={"Policy Title"}/>
                    <textarea className={"PolicyEditModalInputMultiline"} value={newPolicyDesc} onChange={e => setNewPolicyDesc(e.target.value)} placeholder={"Policy Description"}/>
                    <input value={"Add"} className={"PolicyEditModalAddButton"} type={"button"} onClick={addPolicy}/>
                </div>

            </Modal>
        </div>
    );

    function renderPolicyLine(policyData: any) {
        return (
            <div className="PolicyEditLine" key={policyData.title}>
                <div className="PolicyEditLineIcon"><IoIosCheckmarkCircleOutline/></div>
                <div className="PolicyEditLineText">
                    <div className="PolicyEditLineTitle">{policyData.title}</div>
                    <div className="PolicyEditLineSubTitle">{policyData.sub_title}</div>
                </div>
                <div className="PolicyEditLineCheckBox"><Switch defaultChecked={policyData.checked}/></div>
                <div className={userRole == 'Demo' ? "PolicyEditLineEdit" : "PolicyEditLineEditGray"}><GoPencil/></div>
                <div className={userRole == 'Demo' ? "PolicyEditLineTrash" : "PolicyEditLineTrashGray"}><BsTrash3/></div>
            </div>
        )
    }

    function getConfigData(setConfigData: Function) {
        axios.get(`/api/system/config/${entity}/dynamic_config/`).then((res) => {
            if (res.data) {
                for (let conf of res.data) {
                    if (conf.key === 'PolicyEdit') {
                        setConfigData(conf?.value?.PolicyData || []) ;
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}


export default PolicyEdit;
