import axios from "axios";
import { PayslipReviewHelper } from "./PayslipApprovalHelper";
import useCustomParams from "../../../hooks/useCustomParams";
import { useState } from "react";

const PayslipApproveItem = (props:{header: string, subHeader: string, emailAddress: string, isApproved: boolean}) => {
    const { entity, cycle } = useCustomParams();
    const [isApprovedLocally, setIsApprovedLocally] = useState(props?.isApproved);
    const imgSrc = PayslipReviewHelper.getPicturePath(isApprovedLocally, isApprovedLocally);

    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userEmail = userData?.email;
    const isApprovingUser = userEmail === props?.emailAddress;

    const btnText = isApprovedLocally ? "Approved": (isApprovingUser ? "Approve" : "Pending");

    async function postApproval() {
        if (isApprovingUser === false || isApprovedLocally) return;

        const apiCall = await axios.post(`/api/reports/${entity}/${cycle}/${props?.emailAddress}/manager_approval_home_page/`);
        const res = await apiCall?.data;
        setIsApprovedLocally(res);
    }

    return (
        <div className={`PayslipReviewItem-card-base ${isApprovedLocally ? "card-approved" : isApprovingUser ? "card" : "card-pending"}`}>
            <div className="PayslipReviewItem-card-data">
                <img src={imgSrc} alt="" />
                <div className="PayslipReviewItem-text-area">
                    <div className="PayslipReviewItem-header">{props?.header}</div>
                    <div className="PayslipReviewItem-sub-header">{props?.subHeader}</div>
                </div>
            </div>
            {isApprovedLocally || isApprovingUser === false ? <div className="PayslipReviewItem-btn-pending-and-approved">{btnText}</div> : <div className="PayslipReviewItem-btn" onClick={() => postApproval()}>{btnText}</div>}
        </div>
    );
}

export default PayslipApproveItem;