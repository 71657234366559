import DataTable from "react-data-table-component";
import {formatNumber} from "../../../utils/PresentationUtils";
import ExportBtn from "../../ExportBtn/ExportBtn";
import conditionalRowStyles from "../../Dashboard/DashboardUtils/greyTableStyle";
import './EmployeeByTotalHours.css';

const columns = [
    {
        name: 'Hours',
        selector: row => row['range'],
        sortable: true,
    },
    {
        name: 'Current Cycle',
        selector: row => row['employee_count'],
        format: row => formatNumber(row['employee_count'], false, true),
        sortable: true,
    },
    {
        name: 'Previous Cycle',
        selector: row => row['last_cycle_employee_count'],
        format: row => formatNumber(row['last_cycle_employee_count'], false, true),
        sortable: true,
    },
]

const EmployeeByTotalHours = (props) => (
  <div className="employee-by-total-hours">
    <div className='dashboard-component-header'> 
        <div id="employees-by-total-hours">Employees By Total Hours</div>
        <ExportBtn data={props.data} filename={"CeleryEmployeeExport.csv"}></ExportBtn>
    </div>
    <DataTable
        conditionalRowStyles={conditionalRowStyles}
        columns={columns}
        data={props.data}
        defaultSortFieldId={0}
        theme="default"
        responsive
        dense
    />
  </div>
);


export default EmployeeByTotalHours;