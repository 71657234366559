import './dynamicConfig.css';
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import { CiSquarePlus, CiSquareMinus, CiSaveUp2 } from "react-icons/ci";
import useCustomParams from '../../hooks/useCustomParams';


const DEFAULT_DYNAMIC_CONFIG_ID = -1;

class DynamicConfig {
    id: number = DEFAULT_DYNAMIC_CONFIG_ID;
    key: string = '';
    value: any;
}

const DynamicConfigPage = () => {
    const [dataInSaveProcess, setDataInSaveProcess] = useState(false);
    const [dynamicConfigList, setDynamicConfigList] = useState<DynamicConfig[]>([]);

    const { entity } = useCustomParams();
    
    useEffect(() => {
        loadData().then((result) => {
            setDynamicConfigList(result)
        });
    }, [entity]);

    const isSuperUser = localStorage.getItem('is_superuser') === "true";

    const loadData = async () => {
        const response = await axios.get(`/api/system/config/${entity}/dynamic_config`);
        return await response.data;
    };

    const addItem = () => {
        setDynamicConfigList([...dynamicConfigList, new DynamicConfig()]);
    };

    const removeItem = (index: number) => {
        const updatedList = [...dynamicConfigList];
        updatedList.splice(index, 1);
        setDynamicConfigList(updatedList);
    };

    const updateKey = (index: number, newKey: string) => {
        const updatedList = [...dynamicConfigList];

        updatedList[index].key = newKey;
        setDynamicConfigList(updatedList);
    };

    const updateValue = (index: number, newValue: string) => {
        const updatedList = [...dynamicConfigList];
        try
        {
            updatedList[index].value = JSON.parse(newValue);
        } catch (error) {
            console.log('Invalid JSON format')
        }
        setDynamicConfigList(updatedList);
    };

    const saveDynamicConfigList = async () => {
        const saveData = async(data: any) => {
            return await axios.patch(`/api/system/config/${entity}/dynamic_config`, data);
        };

        setDataInSaveProcess( true);
        saveData(dynamicConfigList).then((response)=> {
            if (response.status !== 200)
                console.log(response.statusText);
            else
                updateIDs(Object.assign(response.data))
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setDataInSaveProcess( false);
        })
    };

    const updateIDs = (keyIDsPair: any) => {
        const updatedList = [...dynamicConfigList];

        updatedList.forEach(dynamicConfig => {
            dynamicConfig.id = dynamicConfig.id === DEFAULT_DYNAMIC_CONFIG_ID && dynamicConfig.key !== undefined
                                ? keyIDsPair.find((item: any) => dynamicConfig.key in item)[dynamicConfig.key]
                                : dynamicConfig.id
        });

        setDynamicConfigList(updatedList);
    };

    return (
      isSuperUser &&
      <div className="dynamic-configs-container">
          <div className="dashboard-component-header">Dynamic Configs</div>
          {dynamicConfigList?.map((item, index) => (
              <div className="dynamic-config-item-container" key={index}>
                  <CiSquareMinus className='remove-btn' onClick={() => removeItem(index)} style={{
                      cursor: dataInSaveProcess ? 'not-allowed' : 'pointer',
                      opacity: dataInSaveProcess ? 0.5 : 1,
                  }} />
                  <input
                      className="dynamic-config-text-key"
                      type="text"
                      placeholder="Key"
                      value={item.key}
                      onChange={(e) => updateKey(index, e.target.value)}
                      disabled={dataInSaveProcess}
                  />
                  <textarea
                      rows={8}
                      cols={100}
                      className="dynamic-config-text-value"
                      placeholder="Value (JSON)"
                      value={JSON.stringify(item.value)}
                      onChange={(e) => updateValue(index, e.target.value)}
                      disabled={dataInSaveProcess}
                  />
              </div>
          ))}
          <div className='global-bth-container'>
              <CiSquarePlus className='add-new-btn' onClick={addItem} style={{
                  cursor: dataInSaveProcess ? 'not-allowed' : 'pointer',
                  opacity: dataInSaveProcess ? 0.5 : 1,
              }} />
              <CiSaveUp2 className='save-btn' onClick={saveDynamicConfigList}/>
          </div>
      </div>
    );
}

export default DynamicConfigPage;