import './HomePOC.css';
import {useNavigate} from "react-router-dom";
import {GoAlertFill, GoClockFill} from "react-icons/go";
import {AiFillDollarCircle} from "react-icons/ai";
import {useDispatch} from 'react-redux';
import {setReportSortState, setCategoryType, setItemsToOmit} from '../../store/reportSlice';
import { Bar, BarChart, LabelList, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {formatNumber} from "../../utils/PresentationUtils";
import mixpanel from "mixpanel-browser";
import {getConfigByTabName} from "../Report/filtersTabs/columnConfig";
import axios from "axios";
import {useEffect, useState} from "react";
import useCustomParams from '../../hooks/useCustomParams';


function HomePOC (props: any){
    const { entity, cycle } = useCustomParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [configData, setConfigData] = useState<any>({});

    useEffect(() => {
        getConfigData(setConfigData);
    }, [entity, cycle])

    function deeplinkBuilder(tab:any, column:any, direction:any, slug:any, eventName: any){
        mixpanel.track(eventName)
        dispatch(setCategoryType(tab));
        dispatch(setItemsToOmit(getConfigByTabName(tab)));
        dispatch(setReportSortState({sortId: column, sortDirection: direction}));
        navigate(`/${entity}/${cycle}/report?f=${slug}`)
    };

    function buildInsightItem(insightItem: any){
        return (
            <div className="HomePOCInsightItem" key={'ii_' + insightItem?.topBlack}>
                <div className="HomePOCInsightItemIcon">{getIcon(insightItem?.icon)}</div>
                <div className="HomePOCInsightItemText">
                    <div className="HomePOCInsightItemTextTop">
                        <span style={{color: "#EF525B"}}>{insightItem?.topRed}</span>
                        {insightItem?.topBlack}
                        { insightItem?.topAsterisk && <sup style={{fontSize: 12}}>*</sup>}
                    </div>
                    <div className="HomePOCInsightItemTextBottom">
                        {insightItem?.bot}
                    </div>
                    {
                        insightItem?.botSmall &&
                        <div className="HomePOCInsightItemTextBottomSmall">
                            {insightItem?.botSmall}
                        </div>
                    }
                </div>
                <div className="HomePOCInsightItemButton"
                     onClick={() => deeplinkBuilder(insightItem?.linkTab, insightItem?.linkColumn,
                         insightItem?.linkDirection, insightItem?.linkSlug, insightItem?.linkEvent)}>
                    <div className={`payslip-btn`}>See why<img className='next-icon'
                                                                src='/arrow-right.svg'
                                                                alt='See why'/></div>
                </div>
            </div>
        )
    }

    return (
          <div>
              <div className="home-header">
                  <img src='/home.svg' alt='home'/>
                  <span className='home-header-txt'>Overview</span>
              </div>
              <div className="HomePOCWrapper">
                  <div className="HomePOCInsightArea">
                      <div className="HomePOCAreaHeader">
                          <div className="HomePOCInsightAreaHeaderText">
                              Control Your Payroll
                              { configData?.alert && <sup className="HomePOCInsightAreaHeaderIcon"><GoAlertFill /> Insufficient Data </sup> }
                          </div>
                      </div>
                      <div>
                          {
                              configData?.Insight?.map((insightItem: any) => {
                                  return buildInsightItem(insightItem);
                              })
                          }
                      </div>
                  </div>
                  <div className="HomePOCDataArea">
                      <div className="HomePOCAreaHeader">
                          <div className="HomePOCInsightAreaHeaderText">
                              In Numbers
                          </div>
                      </div>
                      <div className="HomePOCAreaBody">
                          {
                              configData?.InNumbers?.map((InNumbersItem: any) => {
                                  return (
                                      <div className="HomePOCDataItem" key={'i_'+InNumbersItem?.header}>
                                          <div className="HomePOCDataItemLabel">
                                              {InNumbersItem?.header}
                                          </div>
                                          <div className="HomePOCDataItemValue">
                                              {InNumbersItem?.body}
                                          </div>
                                      </div>
                                  )
                              })
                          }
                      </div>
                  </div>
                  <div className="HomePOCChart">
                      <div className="HomePOCAreaHeader">
                          <div className="HomePOCInsightAreaHeaderText">
                              Payroll Overview
                          </div>
                      </div>
                      <div className="HomePOCChartLineWrapper">
                          <div className="HomePOCChartLineLabel"><p style={{marginLeft: '10px', marginBottom:'2px', marginTop:'30px'}}>{configData?.chartConfig?.avgRateName}</p></div>
                          <ResponsiveContainer height={120} className='gross-change-graph-container'>
                              <LineChart data={configData?.chartData} margin={{left:30, right:30, top:10}}>
                                  <XAxis dataKey="date" hide={true}/>
                                  <YAxis yAxisId={1} width={150}
                                         tickFormatter={(value) => {return formatMoney(value)}} hide={true} domain={getGraphDomain(configData?.chartData, 'avg_rate', 1.1, 0.9)}/>
                                  <Tooltip formatter={(value:any, name, props) => {return formatMoney(value)}}/>
                                  <Line yAxisId={1} dataKey="avg_rate" stroke="#02BBE3" strokeWidth={3} name={configData?.chartConfig?.avgRateName}>
                                      <LabelList dataKey="avg_rate" fontSize={16} position={'top'} offset={10} fill={'black'} formatter={(value:any) => {return formatMoney(value)}}></LabelList>
                                  </Line>
                              </LineChart>
                          </ResponsiveContainer>
                      </div>

                      <div style={{marginLeft:'100px', width:'90%'}}>
                          <ResponsiveContainer height={400} className='gross-change-graph-container'>
                              <BarChart data={configData?.chartData}>
                                  <XAxis dataKey="date"/>
                                  <YAxis yAxisId={1} width={100}
                                         tickFormatter={(value) => {return formatMoney(value)}} hide={true}
                                         domain={getGraphDomain(configData?.chartData, 'payroll', 1.2, 0)}/>
                                  <YAxis yAxisId={2} width={100} orientation="right"
                                         tickFormatter={(value) => {return formatNumber(value)}} hide={true}
                                         domain={getGraphDomain(configData?.chartData, 'hours', 1.5, 0)}/>
                                  <Legend />
                                  <Bar yAxisId={1} dataKey="payroll"  fill="#005A73" name={configData?.chartConfig?.payrollName}>
                                      <LabelList dataKey="payroll" fontSize={16} position={'top'} fill={'black'} formatter={(value:any) => {return formatMoney(value)}}></LabelList>
                                  </Bar>
                                  <Bar yAxisId={2} dataKey="hours" fill="#1ACEA3" name={configData?.chartConfig?.hoursName}>
                                      <LabelList dataKey="hours" fontSize={14} position={'top'} fill={'black'} formatter={(value:any) => {return formatMoney(value, false)}}></LabelList>
                                  </Bar>

                              </BarChart>
                          </ResponsiveContainer>
                      </div>
                  </div>
              </div>
          </div>
      );

    function formatMoney(money: number, showCurrency = true) {
        let baseString = ''

        if (showCurrency){
            baseString = '$'
        }

        if (money > 9999){
            return baseString + Math.round(money/1000).toLocaleString() +'K';
        } else {
            return baseString + money.toLocaleString();
        }

    }

    function getConfigData(setConfigData: Function) {
        axios.get(`/api/system/config/${entity}/dynamic_config/`).then((res) => {
            if (res.data){
                for (let conf of res.data) {
                    if (conf.key === 'HomePOC'){
                        setConfigData(conf.value)
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

function getGraphDomain(data: any, key: string, top_mult: number, bottom_mult: number) {
    if (!data) {
        return [0,0]
    }
    let maxValue = undefined
    let minValue = undefined

    for (let row of data){
        let value = row[key]
        if (maxValue === undefined || value > maxValue){
            maxValue = value
        }
        if (minValue === undefined || value < minValue){
            minValue = value
        }
    }

    return [minValue*bottom_mult, maxValue *top_mult]
}

function getIcon(iconID:string) {
    switch (iconID){
        case 'Dollar':
            return <AiFillDollarCircle/>
        case 'Clock':
            return <GoClockFill/>
        default:
            return <AiFillDollarCircle/>
    }

}

export default HomePOC;
