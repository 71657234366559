import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Textarea from '@mui/joy/Textarea';
import { useEffect } from 'react';
import axios from 'axios';
import useCustomParams from '../../hooks/useCustomParams';
import mixpanel from 'mixpanel-browser';


export default function EmployeeNotesInput(props: {open: boolean, employeeXid: string, setNotes: Function}) {
  const [text, setText] = React.useState('');
  const [textFromServer, setTextFromServer] = React.useState('');
  const addEmoji = (emoji: string) => () => setText(`${text}${emoji}`);
  const { entity } = useCustomParams();

  //TODO: take data and save it to employee table!
  props?.setNotes(text);

  useEffect(() => {
    const getNote = async () => {
      const response = await axios.get(`/api/employees/${entity}/${props?.employeeXid}/note`);
      setText(response?.data?.note ? response?.data?.note : '');
      setTextFromServer(response?.data?.note ? response?.data?.note : '');
    };

    getNote();
  }, [props?.employeeXid]);

  useEffect(() => {
    if (!props?.open) {
      setText(textFromServer);
    }
  }, [props?.open]);

  const saveNote = async () => {
    mixpanel.track('Employee Note Saved');
    await axios.post(`/api/employees/${entity}/${props?.employeeXid}/note`, {newNote: text});
    setTextFromServer(text);
  }
  
  return (
    <Textarea
      placeholder="Type notes for this employee…"
      value={text}
      onChange={(event) => setText(event.target.value)}
      style={{position: "absolute", zIndex: 10, marginLeft: "10px", marginTop: "10px", width: "30%", height: "200px"}}
      startDecorator={
        <Box sx={{ display: 'flex', gap: 0.5, flex: 1 }}>
          <IconButton variant="outlined" color="neutral" onClick={addEmoji('👍')}>
            👍
          </IconButton>
          <IconButton variant="outlined" color="neutral" onClick={addEmoji('🏖')}>
            🏖
          </IconButton>
          <IconButton variant="outlined" color="neutral" onClick={addEmoji('😀')}>
            😀
          </IconButton>
          <IconButton variant="outlined" color="neutral" onClick={addEmoji('💲')}>
            💲
          </IconButton>
          <IconButton variant="outlined" color="neutral" onClick={addEmoji('%')}>
            %
          </IconButton>
          <IconButton variant="outlined" color="neutral" onClick={addEmoji('🗓️')}>
            🗓️
          </IconButton>
          <Button variant="outlined" color="neutral" sx={{ ml: 'auto' }} onClick={() => saveNote()}>
            Save
          </Button>
        </Box>
      }
    />
  );
}