export default function getActivityLogsSectionIconPath(iconNumber: number){
    switch(iconNumber){
        case 0:
            return '/Danger-Circle.svg';
        case 1:
            return '/Check Circle.svg';
        case 3:
            return '/Close-Circle.svg'; 
        default:
            return '/Danger-Circle.svg';    
    }
}