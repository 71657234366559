import TimeCard from "./ITimeAndAttendanceInfo";

export default class TimeAndAttendanceHelper{
    static getBreakdownItemTotal = (arr: TimeCard[], breakdownItem: string): number => {
        const values = arr.map((timeCard: TimeCard) => timeCard?.hours_data_breakdown?.[breakdownItem] && timeCard?.hours_data_breakdown?.[breakdownItem] !== ' ' && timeCard?.hours_data_breakdown?.[breakdownItem] !== 'nan' ? parseFloat(timeCard?.hours_data_breakdown?.[breakdownItem]) : 0);
        const result = values.reduce((acc: number, itemValue: number) => acc + itemValue, 0);
        return result;
    }

    static getActionItemTotal = (arr: TimeCard[], actionType: string): number => {
        const values = arr.map((timeCard: TimeCard) => timeCard?.action === actionType ? parseFloat(timeCard?.hours) : 0);
        const result = values.reduce((acc: number, itemValue: number) => acc + itemValue, 0)
        return result;
    }
}