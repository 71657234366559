import DataTable from "react-data-table-component";
import './TimeAndAttendance.css';
import TimeCard, { TimeCardAlert } from "./ITimeAndAttendanceInfo";
import { useEffect, useState } from "react";
import axios from "axios";
import { Backdrop, CircularProgress, MenuItem, Select } from "@mui/material";
import { GrDocumentTime } from "react-icons/gr";
import useCustomParams from "../../hooks/useCustomParams";
import TimeAndAttendanceUpload from "./T&AUpload/T&AUpload";
import Chip from '@mui/material/Chip';
import TimeAndAttendanceSubTable from "./TimeAndAttendanceSubTable";
import TimeAndAttendanceRuleSummary from "./TimeAndAttendanceRuleSummary";
import TimeAndAttendanceHelper from "./TimeAndAttendanceHelper";


function TimeAndAttendance(){
    const { entity } = useCustomParams();
    const [timeCards, setTimeCards] = useState<Array<TimeCard> | null>(null);
    const [alerts, setAlerts] = useState<Array<TimeCardAlert> | null>(null);
    const [totalEmployeesRows, setTotalEmployeesRows] = useState(0);
    const [totalTotalHours, setTotalTotalHours] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(1);
    const [cycles, setCycles] = useState([]);
    const [cycle, setCycle] = useState('current');

    function OnCycleChanged(event: any) {
      setCycle(event?.target?.value);
    }

    const getTimeCards = async () => {
      if(cycle !== 'current'){
        try {
            const response = await axios.get(`/api/time_and_attendance/${entity}/${cycle}/summary?page=${page}&per_page=${perPage}`);
            setTimeCards(response?.data?.data);
            setTotalEmployeesRows(response?.data?.total_employees);
            setTotalTotalHours(response?.data?.total_hours);
            setAlerts(response?.data?.alerts);
        } catch (error) {
            console.error('Fetch error:', error);
        }    
      }
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
    };

    useEffect(() => {
      const uploadElement = document.getElementsByClassName('EntityPortalUploadCell')[0] as HTMLElement;
      uploadElement.style.visibility = 'hidden';
  
      const cycleElement = document.getElementsByClassName('cycle-select-area')[0] as HTMLElement;
      cycleElement.style.visibility = 'hidden';
    
      return () => {
        uploadElement.style.visibility = 'visible';
        cycleElement.style.visibility = 'visible';
      };
    }, []);

    useEffect(() => {
      const getCycles = async () => {
        try {
          const response = await axios.get(`/api/time_and_attendance/${entity}/cycles/`);
          setCycles(response?.data?.reverse());
          setCycle(response?.data[0]);
        } catch (error) {
          console.error('Fetch error:', error);
          setTimeCards([]);
        }
      };

      getCycles();
    }, [entity]);

    useEffect(() => {
        getTimeCards();
    }, [perPage, page, cycle]);

    const columns = [
      {
        name: "Name",
        cell: (arr: TimeCard[]) => {
            if (arr.length > 0) {
              const name = arr[0]?.first_name + " " + arr[0]?.last_name;
              return name?.length > 20 ?
                <span title={name} style={{cursor: "pointer"}}>{name?.substring(0, 18)}...</span> : name;
            } else {
              return "";
            }
          },
        sortable: false,
      },
      {
        name: "Employee ID",
        selector: (arr: TimeCard[]) => arr.length > 0 ? arr[0]?.employee_id : "",
        sortable: false,
      },
      {
        name: "Position Code",
        selector: (arr: TimeCard[]) => arr.length > 0 ? arr[0]?.role : "",
        sortable: false,
      },
      {
        name: "Regular",
        selector: (arr: TimeCard[]) => TimeAndAttendanceHelper.getBreakdownItemTotal(arr, "reg"),
        sortable: false,
      },
      {
        name: "Overtime",
        cell: (arr: TimeCard[]) => {
          const ot = TimeAndAttendanceHelper.getBreakdownItemTotal(arr, "ot1") + TimeAndAttendanceHelper.getBreakdownItemTotal(arr, "ot2");
          return <span style={{color: ot > 24 ? 'red' : ''}}>{ot}</span>;
        },
        sortable: false,
      },
      {
        name: "Vacation",
        selector: (arr: TimeCard[]) => TimeAndAttendanceHelper.getActionItemTotal(arr, "AVAC"),
        sortable: false,
      },
      {
        name: "Bonus",
        selector: (arr: TimeCard[]) => TimeAndAttendanceHelper.getActionItemTotal(arr, "Bonus Hours"),
        sortable: false,
      },
      {
        name: "Sick",
        selector: (arr: TimeCard[]) => TimeAndAttendanceHelper.getActionItemTotal(arr, "Sick"),
        sortable: false,
        style: {marginRight: "-5px"},
      },
      {
        name: "Alerts",
        cell: (arr: TimeCard[]) => alertsCell(arr),
        sortable: false,
      },
    ];

    function alertsCell(arr: TimeCard[]) {
        const employeeId = arr[0]?.employee_id;
        const employeeAlerts = alerts?.filter((alert: TimeCardAlert) => alert?.employee_id === employeeId);
        const alertsTypesSet = new Set(employeeAlerts?.map((alert: TimeCardAlert) => alert.testName));
        const alertsTypesArray = Array.from(alertsTypesSet).map((alert: string | null) => alert ? mapAlertType(alert) : '');
        return  employeeAlerts && employeeAlerts?.length > 0 ? <Chip style={{color: "red", borderColor:"red"}} title={`${alertsTypesArray?.join(", ")}`} label={`Has Alerts: ${alertsTypesArray?.join(", ")}`} variant="outlined"/> : '';
    }

    const aggregateData: Record<string, Array<TimeCard>> = {};
    timeCards?.forEach((timeCard: TimeCard) => {
        const priorData = aggregateData[timeCard?.employee_id] ?? [];
        aggregateData[timeCard?.employee_id] = priorData.length > 0 ? [...priorData, timeCard] : [timeCard];
    });

    const aggregateDataArray = Object.values(aggregateData);

    return (
        timeCards === null ? 
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop> 
        :
        <div className="TimeAndAttendance">
            <div className="TimeAndAttendance-header">
                <GrDocumentTime />
                <span className='TimeAndAttendance-header-txt'>Time and Attendance</span>
            </div>
            <div id='t-and-a-cycle-input'>
              <Select displayEmpty onChange={OnCycleChanged} value={cycle} >
                {cycles?.map((x, index) => <MenuItem data-track='Change cycle' data-track-props={'cycle selected: '+x} className='option-design' key={x} value={x}>{index === 0 ? x + ' (Current)' : x}</MenuItem> )}
              </Select>
            </div>
            <div className="TimeAndAttendanceUpperPanel">
                <span className='TimeAndAttendance-header-txt-total'>Cycle: {timeCards && timeCards?.length > 0 ? timeCards[0]?.cycle : ""}, Total Hours: <span>{totalTotalHours?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></span>
                <div className="t-and-a-Btn-board">
                  <TimeAndAttendanceUpload oldCyclesLength={cycles?.length} callback={setCycles}/>
                  <TimeAndAttendanceRuleSummary />
                </div>
            </div>
            <div className="TimeAndAttendance-table-wrapper">
                {
                    timeCards &&
                    <DataTable
                        columns={columns}
                        data={aggregateDataArray}
                        defaultSortFieldId={0}
                        theme="default"
                        responsive
                        dense
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        expandableRows={true}
                        expandableRowsComponent={timeCards => TimeAndAttendanceSubTable(timeCards?.data, alerts as TimeCardAlert[])}
                        expandOnRowClicked={false}
                        expandOnRowDoubleClicked={false}
                        expandableRowsHideExpander={false}
                        pagination
                        paginationServer
                        paginationTotalRows={totalEmployeesRows}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 200]}
                    />
                }
            </div>
        </div>
    )
}

export default TimeAndAttendance;

function mapAlertType(alertType: string) {
  switch (alertType) {
    case 'LunchHoursAlert':
      return 'Lunch';
    case 'RetroAlert':
      return 'Retro';
    case 'Over24HoursOtAlert':
      return 'OT';
    case 'NotOnlyAdmin':
    case 'moreThanOnePositionCodeAlert':
      return 'Multiple Position';
    case 'BonusHoursAlert':
      return 'Bonus';
    default: 
  }
}