import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {useState} from "react";


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow={true}  />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#0E3C48',
        color: '#ffffff',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(13),
        border: '1px solid #dadde9',
        paddingRight: '20px',
        paddingLeft: '0px'
    },
}));

function CustomizedTooltips(props: {loadContent: Function,
                                    loadTooltipData: (setItems: React.Dispatch<React.SetStateAction<string[]>>) => void})
{
    const [items, setItems] = useState<string[]>([]);

    const bindTooltipData = () => {
        props.loadTooltipData(setItems);
    }

    return (
        <div style={{cursor:"pointer"}}>
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <ul>
                            {items.map((item,i) => <li key={i}>{item}</li>)}
                        </ul>
                    </React.Fragment>
                }>
                {
                    <span onMouseEnter={bindTooltipData}>
                        {props.loadContent()}
                    </span>
                }
            </HtmlTooltip>
        </div>
    );
}

export default CustomizedTooltips;