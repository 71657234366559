import {useEffect, useState} from 'react';
import axios from "axios";
import Home from "../Home/Home";
import HomePOC from "../HomePOC/HomePOC";
import HomePOCCards from "../HomePOCCards/HomePOCCards";
import HomePOCPasion from "../HomePOCPasion/HomePOCPasion";
import HomeNew from "../HomeNew/HomeNew";
import HomeNewDemo from "../HomeNewDemo/HomeNewDemo";
import ManagerApprovalHomepage from "../ManagerApprovalHomepage/ManagerApprovalHomepage";
import useCustomParams from "../../hooks/useCustomParams";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {useRef} from "react";

function HomeSelector() {
    const { entity, cycle } = useCustomParams();
    const [configData, setConfigData] = useState(undefined);
    const latestController = useRef(null);

    useEffect(() => {
        if (latestController?.current) {
            latestController.current.abort();
        }
        const controller = new AbortController();
        //@ts-ignore
        latestController.current = controller;

        getConfigData(setConfigData, controller);

        return () => {
            controller.abort();
        };
    }, [entity, setConfigData])

    if (configData == undefined){
        return (        
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function selectHomePage() {
        switch(configData?.page) {
            case 'HomePOCPasion':
                return <HomePOCPasion/>;
            case 'HomePOCCards':
                return <HomePOCCards/>;
            case 'HomePOC':
                return <HomePOC/>;
            case 'HomeNew':
                return <HomeNew/>
            case 'HomeNewDemo':
                return <HomeNewDemo/>
            case 'ManagerApprovalHomepage':
                    return <ManagerApprovalHomepage/>    
            default:
                return <Home/>;
        }
    }

    return (
        <div>
            {selectHomePage()}
        </div>
    );


    function getConfigData(setConfigData, controller) {
        axios.get(`/api/system/config/${entity}/dynamic_config/`, {signal: controller.signal}).then((res) => {
            if (res.data){
                for (const conf of res.data) {
                    if (conf.key === 'Home'){
                        setConfigData(conf.value)
                        return
                    }
                }
            }
            setConfigData({})
        }).catch((err) => {
            console.log(err);
        })
    }
}

export default HomeSelector;