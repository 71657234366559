import './AutoCommentEdit.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {MdOutlinePolicy} from "react-icons/md";
import {FiPlus} from "react-icons/fi";
import Modal from '@mui/material/Modal';
import useCustomParams from '../../hooks/useCustomParams';
import AutoCommentLine from './AutoCommentLine';
import IAutoCommentData, { IAutoCommentDataServerSchema, IFormConfigData } from './IAutoCommentData';
import EmailAutoComplete from '../SimpleCommentModal/EmailAutoComplete';

function AutoCommentEdit (){
    const { entity, cycle } = useCustomParams();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [configData, setConfigData] = useState<IAutoCommentData[]>([]);

    const [alertName, setAlertName] = useState<string>('');
    const [commentBy, setCommentBy] = useState<string>('');
    const [commentBody, setCommentBody] = useState<string>('');
    const [sendTo, setSendTo] = useState<string[]>([]);
    const [enabled, seEnabled] = useState<boolean>(true);

    const [formConfigData, setFormConfigData] = useState<IFormConfigData | null>(null);

    function getConfigData(setConfigData: Function) {
        axios.get(`/api/auto_comment/${entity}/`).then((res) => {
            if (res?.data?.value) {
                const items = res?.data?.value?.map((x: IAutoCommentDataServerSchema) => {return {alertName: x?.alert_name, commentBy: x?.comment_by, commentBody: x?.comment_body, sendTo: x?.send_to, enabled: x?.enabled}});
                setConfigData(items?? []);
            }
        }).catch((err) => {
            console.log(err);
        });

        axios.get(`/api/auto_comment/${entity}/form_config`).then((res) => {
            if (res?.data) {
                setFormConfigData(res?.data);
            }
        });
    }

    useEffect(() => {
        getConfigData(setConfigData);
    }, [entity, cycle]);

    useEffect(() => {
        const alertParts = alertName?.split(' - ');
        const field_name = alertParts[1];
        const message = alertParts[2];
        const bodyTemplate = 
        `        {employee_full_name}, ID {employee_id}
        Department {department_id}
        ${message} Please verify
        ${field_name}: {field_value}

        Please add reasons for this exception.`;
        setCommentBody(bodyTemplate);
    }, [alertName]);

    async function addAutoComment() {
        const saveNewAutoComment = await axios.patch(`/api/auto_comment/${entity}/`, {
            key: 'AutoComment',
            value: {alert_name: alertName, comment_by: commentBy, comment_body: commentBody, send_to: sendTo, enabled: enabled}
        });

        if (saveNewAutoComment.status === 200) {
            const currAutoComment = [...configData];
            currAutoComment.push({alertName: "string", commentBy: "string", commentBody: "string", sendTo: [], enabled: true});
            setConfigData(currAutoComment);
        }else{
            alert('Auto Comment failed to save! Please try again');
            console.log('Auto Comment failed to save!', {
                key: 'AutoComment',
                value: {alert_name: alertName, comment_by: commentBy, comment_body: commentBody, send_to: sendTo, enabled: enabled}
            });
        }

        closePopup();
        getConfigData(setConfigData);
    }

    function closePopup() {
        setAlertName("");
        setCommentBy("");
        setCommentBody("");
        setSendTo([]);
        seEnabled(false);
        setModalOpen(false);
    }

    return (
        <div>
            <div className="home-header">
                <MdOutlinePolicy />
                <span className='home-header-txt'>Auto Comment</span>
            </div>
            <div className="AutoCommentEditWrapper">
                <div className="AutoCommentEditTitle">
                    <span className="home-header-txt">Auto Comment List</span>
                    <div className="AutoCommentEditTitlePlus">
                        <FiPlus onClick={() => setModalOpen(true)} />
                    </div>
                </div>
                <div className="AutoCommentEditBody">
                    {configData.map((AutoCommentData: IAutoCommentData) => AutoCommentLine(AutoCommentData))}
                </div>
            </div>
            <Modal
                open={modalOpen}
                onClose={closePopup}>
                <div className={"AutoCommentEditModal"}>
                    <div className={"AutoCommentEditModalTitle"}>Add New Auto Comment</div>
                    <div className={"AutoCommentEditModalSubTitle"}>Create your own Auto Comment, to get relevant Auto Comments and Comment emails</div>
                    <select name="commentType" id="commentType" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setAlertName(e?.target?.value)}>
                        <option value="">Select Alert</option>
                        {formConfigData?.findings_types?.map((x) => <option value={`${x?.alert_type} - ${x?.field_name} - ${x?.message}`}>{`${x?.alert_type} - ${x?.field_name} - ${x?.message}`}</option>)}
                    </select>
                    <select name="commentFrom" id="commentFrom" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCommentBy(e?.target?.value)}>
                        <option value="">Select Comment By</option>
                        {formConfigData?.emails?.map((email) => <option value={email}>{email}</option>)}
                    </select>
                    <textarea value={commentBody} onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => setCommentBody(e.currentTarget.value)} placeholder="will change when you select alert and you can add it too!" id="commentBody" name="commentBody" rows={4} cols={50} />
                    <EmailAutoComplete open={true} selectedEmails={sendTo} setSelectedEmails={setSendTo}/>
                    <input value={"Add"} className="AutoCommentEditModalAddButton" type={"button"} onClick={addAutoComment}/>
                </div>
            </Modal>
        </div>
    );
}

export default AutoCommentEdit;