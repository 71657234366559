import { Link } from "react-router-dom";
import useCustomParams from "../../../hooks/useCustomParams";
import { useDispatch } from "react-redux";
import {setAlertTypesFilter} from "../../../store/reportSlice";

const NewCategoryAlerts = (props: {payslips: any, type: string, text: string}) => {
    const { entity, cycle } = useCustomParams();
    const dispatch = useDispatch();
    const items = props.payslips?.filter((x:any) => x?.severity === 1)?.map((x:any) => x?.findings_summery)?.flat().filter((x:any) => x?.alert_type === props?.type);
    const closed: number = items?.reduce((accumulator: number ,item: any) => accumulator += item?.approved_quantity, 0);
    const open: number = items?.reduce((accumulator: number ,item: any) => accumulator += item?.non_approved_quantity, 0);
    const imgSrc = `/alert-icon-${props?.type}${open === 0 ? "-closed" : ""}.svg`;

    const onFilterClick = () => {
        dispatch(setAlertTypesFilter([props?.type]));
    }
 
    return (
        <Link to={`/${entity}/${cycle}/report`} className={`new-category-alerts ${open === 0 ? "alerts-completed" : ""} ${closed + open === 0 ? "category-alerts-unClickable" : ""}`} onClick={() => onFilterClick()}>
            <img alt={`${props?.text}`} src={imgSrc}/>
            <div>
                <div className="new-category-alerts-sub-header">{props?.text}</div>
                <div>
                    <span className="new-category-alerts-header-first-part">{closed}</span>             
                    <span className="new-category-alerts-header-second-part">/{closed + open}</span>
                    <span className="new-category-alerts-header-second-part"> resolved</span> 
                </div>
            </div>
        </Link>
    );
}

export default NewCategoryAlerts;