import { configureStore } from '@reduxjs/toolkit';
import PatientsViewReducer from './PatientSlice';
import PayslipsViewReducer from './PayslipSlice';
import reportStateReducer from './reportSlice';
import averageRateDashboardReducer from './AverageRateDashboardSlice';
import entityReducer from './entitySlice';
import CommentEnforcerReducer from './CommentEnforcerSlice';

export default configureStore({
  reducer: {
    PayslipsViewReducer,
    reportStateReducer,
    averageRateDashboardReducer,
    entityReducer,
    PatientsViewReducer,
    CommentEnforcerReducer,
  },
});