import './OvertimeReport.css';
import {useEffect, useRef, useState} from "react";
import useCustomParams from '../../hooks/useCustomParams';
import axios from "axios";
import { MdOutlineMoreTime } from "react-icons/md";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "react-data-table-component";
import {useNavigate, useSearchParams} from "react-router-dom";
import {EmployeeOvertimeReport} from "./EmployeeOvertimeReport"
import OvertimeHistoryModal from "./OvertimeHistory/OvertimeHistoryModal";
import { FaChartLine } from "react-icons/fa6";
import ExportBtn from "../ExportBtn/ExportBtn";

function OvertimeReport (props: any){
    const latestController = useRef<AbortController | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const { entity, cycle } = useCustomParams();
    const navigate = useNavigate();

    const [selectedEmployee, setSelectedEmployee] = useState<any>({});
    const [overtimeHistoryModalOpen, setOvertimeHistoryModalOpen] = useState<boolean>(false);
    const [allEmployees, setAllEmployeeData] = useState<EmployeeOvertimeReport[]>([]);
    const [employees, setEmployeeData] = useState<EmployeeOvertimeReport[]>([]);
    const [departmentsFilter, setDepartmentsFilter] = useState<string[]>([]);
    const [filter, setFilter] = useState('');
    const [textFilter, setTextFilter] = useState('');

    const allDept = 'ALL'

    let deptFilterQueryString = searchParams.get('department')
    deptFilterQueryString = deptFilterQueryString ? deptFilterQueryString : null

    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController.current) {
            latestController.current.abort();
        }

        // Create a new controller for the current request
        const controller = new AbortController();
        latestController.current = controller;

        getDashboardData(controller);

        return () => {
            // Clean up the controller on component unmount or date change
            controller.abort();
        };
    }, [entity, cycle]);


    const getDashboardData = (controller: AbortController) => {
        const callConfig = controller ? {signal: controller?.signal} : undefined;
        axios.get(`/api/employees/${entity}/${cycle}/overtime_report`, callConfig).then((res) => {
            if (res.data) {
                setEmployeeData(res.data);
                setAllEmployeeData(res.data);
                loadDepartmentsFilter(res.data);
            } else {
                setEmployeeData([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const loadDepartmentsFilter = (employee_list: EmployeeOvertimeReport[]) => {
        const departments: string[] = []

        for(const employee of employee_list)
        {
            if (employee.department &&
                departments.indexOf(employee.department) === -1)
            {
                departments.push(employee.department);
            }
        }

        setDepartmentsFilter(departments);

        if(deptFilterQueryString != null)
            departmentFilter(deptFilterQueryString, employee_list);
    }

    const departmentFilterChanged = (e: any) => {
        departmentFilter(e.target.value, allEmployees);
    }

    const departmentFilter = (department: string, employee_list: EmployeeOvertimeReport[]) => {
        setFilter(department);
        if (allDept === department)
        {
            setEmployeeData(employee_list);
            return;
        }

        const new_employees: EmployeeOvertimeReport[] = []
        for(const employee of employee_list)
        {
            if (employee.department === department)
                new_employees.push(employee);
        }

        setEmployeeData(new_employees);
    }

    const employeeFilterChanged = (e: any) => {
        const new_employees: EmployeeOvertimeReport[] = []
        const newFilter = e.target.value.toLowerCase();
        setTextFilter(newFilter);

        if (newFilter === "")
        {
            setEmployeeData(allEmployees);
            return;
        }

        for(const employee of allEmployees)
        {
            if (employee.xid?.toLowerCase().indexOf(newFilter) !== -1 ||
                employee.name?.toLowerCase().indexOf(newFilter) !== -1)
            {
                new_employees.push(employee);
            }
        }

        setEmployeeData(new_employees);
    }

    const getOvertimeHistory = (row: any) => {
        setSelectedEmployee({ id : row.xid, name: row.name });
        setOvertimeHistoryModalOpen(true);
    };

    const navigateToPayslip = (payslip_cycle: string, payslip_id: string) => {
        navigate(`/${entity}/${payslip_cycle}/payslips/?id=${payslip_id}`);
    };

    const columns = [
        {
            name: 'Employee Name',
            selector: (row: any) => row.name,
            sortable: true,
            style: {
                fontWeight: 'bold'
            },
        },
        {
            name: 'Employee ID',
            selector: (row: any) => row.xid,
            sortable: true,
        },
        {
            name: 'Department',
            selector: (row: any) => row.department,
            sortable: true,
        },
        {
            name: 'OT Hours (Current)',
            selector: (row: any) => parseFloat(row.curr_cycle_ot_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})),
            sortable: true,
            cell: (row: any) => (
                <div onClick={() => navigateToPayslip(cycle, row.curr_payslip_id)} className="ot-hours-column">
                    {parseFloat(row.curr_cycle_ot_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}))}
                </div>
            )
        },
        {
            name: 'Hours (Current)',
            selector: (row: any) => parseFloat(row.curr_cycle_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})),
            sortable: true,
        },
        {
            name: 'Earnings (Current)',
            selector: (row: any) => row.curr_cycle_earnings?.toLocaleString(undefined, {minimumFractionDigits: 0}),
            sortable: true,
        },
        {
            name: 'OT Hours (Previous)',
            selector: (row: any) => parseFloat(row.previous_cycle_ot_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})),
            sortable: true,
            cell: (row: any) => (
                <div onClick={() => navigateToPayslip(row.previous_payslip_cycle, row.previous_payslip_id)} className="ot-hours-column">
                    {parseFloat(row.previous_cycle_ot_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}))}
                </div>
            )
        },
        {
            name: 'Hours (Previous)',
            selector: (row: any) => parseFloat(row.previous_cycle_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})),
            sortable: true,
        },
        {
            name: 'Earnings (Previous)',
            selector: (row: any) => row.previous_earnings?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}),
            sortable: true,
        },
        {
            name: 'History',
            sortable: true,
            cell: (row: any) => (
                <div onClick={() => getOvertimeHistory(row)} className="employee-history-column">
                    <FaChartLine className="history-icon" />
                </div>
            )
        }
    ];

    const conditionalRowStyles = [
        {
            when: (row: any) => true,
            style: {
                fontWeight: 700,
                color: 'var(--warm-black)',
                fontSize: '14px',
                letterSpacing: '-0.35px',
                lineHeight: '20px',
                whiteSpace: 'nowrap',
                backgroundColor: 'var(--cultured)',
                borderRadius: '8px',
                height: '40px',
                marginBottom: '12px',
            }
        },
    ];

    const propertyMapping = {
        "name": "Employee Name",
        "xid": "Employee ID",
        "department": "Department",
        "curr_cycle_ot_hours": "OT Hours (Current)",
        "curr_cycle_hours": "Hours (Current)",
        "curr_cycle_earnings": "Earnings (Current)",
        "previous_cycle_ot_hours": "OT Hours (Previous)",
        "previous_cycle_hours": "Hours (Previous)",
        "previous_earnings": "Earnings (Previous)"
    };


    return (
        <div className="overtime-report">
            <div className="Grid0">
                <div className="overtime-report-header">
                    <MdOutlineMoreTime className='overtime-report-header-icon' />
                    <span className='overtime-report-header-txt'>Overtime</span>
                </div>
            </div>
            <div className="Grid1 overtime-report-data">
                <div className="overtime-report-filter-panel">
                    <img src='/filter.svg' alt='filter' className='overtime-report-option-default'/>
                    <Select displayEmpty value={filter} onChange={departmentFilterChanged}
                            className="overtime-report-filter">
                        <MenuItem value="" disabled selected hidden>Filter by department</MenuItem>
                        <MenuItem className='option-design' key="Filter By" value={allDept}>All</MenuItem>
                        {departmentsFilter.map((fc, i) => <MenuItem className='option-design' key={fc} value={fc}>{fc}</MenuItem>)}
                    </Select>
                    <div className="overtime-report-text-filter">
                    <img src='/filter.svg' alt='filter'/>
                        <input className="overtime-report-text-filter-input" placeholder="Search Employee"
                               value={textFilter} onChange={employeeFilterChanged}
                        />
                    </div>
                    <ExportBtn data={employees} filename={"CeleryOvertimeEmployeeExport.csv"} propertyMapping={propertyMapping}></ExportBtn>
                </div>
                <div className="overtime-report-table">
                    <DataTable
                        conditionalRowStyles={conditionalRowStyles}
                        columns={columns}
                        data={employees}
                        defaultSortFieldId={4}
                        defaultSortAsc={false}
                        theme="default"
                        responsive
                        pagination
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50]}
                    />
                </div>
            </div>
            <OvertimeHistoryModal name={props.name} isOpen={overtimeHistoryModalOpen}
                                  setOpen={setOvertimeHistoryModalOpen} employee={selectedEmployee}></OvertimeHistoryModal>
        </div>
    );
}


export default OvertimeReport;
