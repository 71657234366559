import Avatar from '@mui/material/Avatar';

export default function BackgroundLetterAvatars(prop: {name: string}) {  
  function stringToColor(string: string): string {
    if (!string) return '';
  
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    hash = Math.abs(hash);
  
    // Define expanded color component ranges for dark to light blue/green
    const range = {
      r: {min: 0, max: 173}, // Min for dark shades, max for lighter shades
      g: {min: 100, max: 238}, // Starting from a darker green, up to a light green
      b: {min: 139, max: 230}, // Starting from a dark blue, up to a light blue
    };
  
    // Function to map hash to color range
    const hashToRange = (hash: any, min: any, max: any) => {
      const diff = max - min;
      return ((hash % diff) + diff) % diff + min; // Ensure we cycle through the entire range
    };
  
    // Extract parts of the hash for each color component
    const rHash = (hash & 0xFF0000) >> 16;
    const gHash = (hash & 0x00FF00);
    const bHash = hash & 0x0000FF;
  
    // Map hash values to expanded color ranges
    const r = hashToRange(rHash, range.r.min, range.r.max);
    const g = hashToRange(gHash, range.g.min, range.g.max);
    const b = hashToRange(bHash, range.b.min, range.b.max);
  
    // Convert to hexadecimal and return the color
    const color = `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g).toString(16).padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`;
    return color;
  }

  const name = prop?.name?.split(' ')?.length > 1 ? prop?.name?.split(' ')[0][0] + prop?.name?.split(' ')[1][0] : prop?.name?.split(' ')[0][0];

  return (
    <Avatar className='comments-avatar' style={{backgroundColor:stringToColor(name)}}>{name}</Avatar>
  );
}