import axios from "axios";
import { useState } from "react";
import useCustomParams from "../../hooks/useCustomParams";

const MultiPayslipApprove = () => {
    
    const { entity, cycle } = useCustomParams();
    const[value, setValue] = useState('');
    
    const handleClick = () => {
        const arrayOfEmployeeID = value.trim().split(' ');
        axios.patch(`/api/payslips/${entity}/${cycle}/approve/multi`, {employeeID: arrayOfEmployeeID}).then
        (response => {
            if(response.status === 200){
                alert("Payslips approved successfully");
            } else {
                alert("Payslips approve failed");
            }
        }).catch(error => {
            console.log(error);
            alert("Payslips approve failed");
        });
    }

    return (
        <div style={{padding: "12px"}}>
            <h1 style={{padding: "12px"}}>Multi Payslip Approve</h1>
            <input id="MultiPayslipApproveInput" value={value} placeholder="Employee ID for example: 123 345 654..." onChange={(e) => setValue(e.target.value)} style={{marginLeft: "12px", marginRight: "12px", width:"360px"}}/>
            <button data-track='Multi Approve all' onClick={handleClick}>approve all payslips except this list</button>
        </div>
    );
}

export default MultiPayslipApprove;