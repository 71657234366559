import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getEntities = createAsyncThunk(
    'entityState/getEntities',
    async () => {
      const response = await axios.get('/api/payslips/entities');
      const data = await response.data;
      return data;
    }
);

export interface Entity {
    id: number,
    name: string,
    last_cycle: string,
    cycles: string[],
    cycle_length: number,
    payslips_review: number,
    total_payslips: number
    disable_hr_approve_permission: boolean,
    support_ppo_plan: boolean,
    plan: string,
    latest_cycle_ready_ppo: boolean
    enable_ppo_file_upload: boolean
    ppo_files_uploded: boolean
    ppo_cycles: string[],
    last_ppo_cycle: string,
};

export interface entityState {
    entities: Entity[],
    selectedEntity: string,
    selectedEntityObj: Entity | null,
    selectedCycle: string | null,
    selectedEntityName: string,
    filesUploadState: string | null,
};

const initialState: entityState = {
    entities: [],
    selectedEntity: "",
    selectedEntityObj: null,
    selectedCycle: null,
    selectedEntityName: '',
    filesUploadState: null
};

export const EntityStateSlice = createSlice({
  name: "entityState",
  initialState,
  reducers: {
    setEntities: (state, action: PayloadAction<Entity[]>) => {
      state.entities = action.payload;
    },
    setSelectedEntity: (state, action: PayloadAction<string>) => {
      state.selectedEntity = action.payload;
      state.selectedEntityObj = state?.entities?.find((x:any) => x?.id.toString() === action?.payload.toString()) || null;
      const dates = state?.selectedEntityObj?.cycles?.map(date => new Date(date));
      dates?.sort((a: Date, b: Date) => a?.getTime() - b?.getTime());
      const sortedDateStrings = dates?.map(date => date?.toISOString()?.slice(0, 10))?.pop();
      state.selectedCycle = sortedDateStrings || state?.selectedEntityObj?.last_ppo_cycle || null;
      state.selectedEntityName = state?.selectedEntityObj?.name || '';
    },
    setSelectedEntityObj: (state, action: PayloadAction<Entity>) => {
      state.selectedEntityObj = action.payload;
    },
    setSelectedCycle: (state, action: PayloadAction<string>) => {
      state.selectedCycle = action.payload;
    },
    setSelectedEntityName: (state, action: PayloadAction<string>) => {
      state.selectedEntityName = action.payload;
    },
    setFilesUploadState: (state, action: PayloadAction<string>) => {
      state.filesUploadState = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntities.pending, (state) => {
        state.entities = [];
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.entities = action.payload;
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.entities = [];
      });
  },
});

export const { setEntities, setSelectedEntity, setSelectedEntityName,
                setSelectedEntityObj, setSelectedCycle,
                setFilesUploadState } = EntityStateSlice.actions;

export default EntityStateSlice.reducer;