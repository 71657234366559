import './HomeNewCard.css';


function HomeNewCard(props: {icon: string, header: string, value:number, change: number, isDollar:boolean, isPercent:boolean, hideArrows?: boolean}) {
    const change = props?.change?.toFixed(2) === 'NaN' ? '' : props?.change?.toFixed(2);

    if(props?.change?.toFixed(2) === 'NaN') return ( <></> )
    
    return (
        <div className='HomeNewCard'>
            <div className='HomeNewCardHeaderRow'><img src={props.icon} alt={props.header} /><span className='HomeNewCardHeaderText' title={props.header}>{props.header}</span></div>
            <div className='HomeNewCardValueRow'>
                <span className='HomeNewCardValueText'>{props.isDollar && '$'}{formatNumber(props.value)}{props.isPercent && '%'}</span>
                {
                    !props.hideArrows &&
                    <span
                        className={props.change >= 0 ? "HomeNewCardPercentageAreaGreen" : "HomeNewCardPercentageAreaRed"}>
                        <img src={props.change >= 0 ? "/arrow-up-small.svg" : "/arrow-down-small.svg"} alt="arrow"/>
                        <span>{change}%</span>
                    </span>
                }
            </div>
        </div>
    )
}

function formatNumber(number: number) {
    if (isNaN(number)) {
        return ''
    }

    if (number >= 1000) {
        return Math.floor(number).toLocaleString()
    } else {
        return number.toFixed(2)
    }
}

export default HomeNewCard