import { Stack, Chip } from "@mui/material";
import DataTable from "react-data-table-component";
import TimeCard, { TimeCardAlert } from "./ITimeAndAttendanceInfo";


const TimeAndAttendanceSubTable = (timeCards: TimeCard[], alerts: TimeCardAlert[]) => {
    const datesMap = timeCards.map((timeCard: TimeCard) => timeCard?.date);
    const uniqueDates = Array.from(new Set(datesMap));

    const conditionalRowStyles = [
        {
            when: (row: TimeCard) => true,
            style: {
                fontFamily: 'DM Sans;',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '20px',
                letterSpacing: '-0.34699028730392456px',
            }
        },
        {
            when: (row: TimeCard) => uniqueDates.indexOf(row?.date) % 2 === 0,
            style: {
              backgroundColor: '#ececed',
            },
        }
    ];

    const customStyles = {
        rows: {
          style: {
            borderRight: '1px solid #ddd',
          },
        },
        cells: {
          style: {
            borderRight: '1px solid #ddd',
          },
        },
        headCells: {
          style: {
            borderRight: '1px solid #ddd',
          },
        },
      };
    
    const subColumns: any = [
        {
            name: 'Date',
            selector: (timeCard: TimeCard) => timeCard?.date,
            sortable: false,
        },
        {
            name: 'Position Code',
            selector: (timeCard: TimeCard) => timeCard?.role,
            sortable: false,
        },
        {
            name: 'Action',
            selector: (timeCard: TimeCard) => timeCard?.action,
            sortable: false,
        },
        {
            name: 'In',
            selector: (timeCard: TimeCard) => timeCard?.in_punch_time,
            sortable: false,
        },
        {
            name: 'Out',
            selector: (timeCard: TimeCard) => timeCard?.out_punch_time,
            sortable: false,
        },
        {
            name: 'Hours',
            selector: (timeCard: TimeCard) => timeCard?.in_punch_time,
            sortable: false,
        }
    ];

    for (const key of Object.keys(timeCards[0]?.hours_data_breakdown)) {
        const newColumnItem = {
            name: key?.charAt(0)?.toUpperCase() + key?.slice(1),
            selector: (timeCard: TimeCard) => timeCard?.hours_data_breakdown[key],
            sortable: false,
            
        };
        subColumns.push(newColumnItem);
    }

    function anotherAlertsCell(timeCard: TimeCard) {
        const itemAlerts = alerts?.filter((alert: TimeCardAlert) => alert?.timecard === timeCard?.id);
        return  <Stack direction="row" spacing={1}>
                    {itemAlerts?.map((alert: TimeCardAlert) => <Chip style={{color: "red", borderColor:"red", height:"20px"}} label={alert?.message} variant="outlined"/>)}
                </Stack>
    }

    subColumns.push({
        name: 'Alerts',
        cell: (timeCard: TimeCard) => anotherAlertsCell(timeCard),
        sortable: false,
    });

    return (
        <DataTable
            className="TimeAndAttendanceSubTable"
            columns={subColumns}
            data={timeCards}
            theme="default"
            responsive
            dense
            conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
        />
    );
};

export default TimeAndAttendanceSubTable;