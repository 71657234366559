import {useState} from 'react';
import './Login.css';
import {login} from "../../providers/login/login";
import logo from './Logo.svg';
import { Grid } from '@mui/material';

function Login(props: any){

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showErrorLogin, setShowErrorLogin] = useState(false);

    function handleSubmit(event: any) {
        event.preventDefault();
        login(username, password, props.setLoggedIn, setShowErrorLogin);
    }

    return (
        <Grid data-testid="Login" container spacing={0}>
            <Grid item xs={5} className="">
                <img src={logo as unknown as string}  className="logo" alt="logo"/>            
                <form onSubmit={handleSubmit}>
                    <div className="LoginSquare">
                        <div className='login-title'>Welcome to Celery</div>
                        <div className="LoginInputs">
                            <input className="LoginInput" placeholder="Email Address" onChange={(evt) => { setUsername(evt.target.value); }} />
                            <input className="LoginInput" type="password" placeholder="Password" onChange={(evt) => { setPassword(evt.target.value); }} />
                            <div className="login-error-message" style={{visibility: `${showErrorLogin ? "visible": "hidden"}`}}>
                                <div className='warning-symbol'></div>
                                Wrong username or password
                            </div>
                        </div>
                        <button className="LoginBtn" type="submit">Log in</button>
                    </div>
                </form>
            </Grid>
            <Grid item xs={7}>
                <div className="main-image"></div>
            </Grid>
        </Grid>
    );
}

export default Login;