import './ManagerApprovalHomepage.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {ChartData, ReviewData, PayslipApproveConfig} from './IManagerApprovalHomepage';
import HomeNewChartsSegment from "../HomeNewChartsSegment/HomeNewChartsSegment";
import useCustomParams from '../../hooks/useCustomParams';
import NewAlertSummary from './NewAlertsSummary/newAlertsSummary';
import PayslipReviewItem from './PayslipApprovalItem/PayslipReviewItem';
import PayslipApproveItem from './PayslipApprovalItem/PayslipApproveItem';


function ManagerApprovalHomepage(){
    const { entity, cycle } = useCustomParams();
    const [chartData, setChartData] = useState<ChartData[]>([] as ChartData[]);
    const [reviewData, setReviewData] = useState<ReviewData | null>(null);
    const [configs, setConfigs] = useState<PayslipApproveConfig[]>([] as PayslipApproveConfig[]);
    const [approvals, setApprovals] = useState<string[]>([] as string[]);

    async function getPageData() {
        const apiCall = await axios.get(`/api/reports/${entity}/${cycle}/manager_approval_home_page/`);
        const res = await apiCall?.data;
        setChartData(res?.chartData);
        setReviewData(res?.reviewData);
        setConfigs(res?.configs);
        setApprovals(res?.approvals);
    }

    useEffect(() => {
        getPageData();
    }, [entity, cycle]);
    
    if(reviewData){
        return (
            <div>
                <div className="home-header">
                    <img src='/home.svg' alt='home'/>
                    <span className='home-header-txt'>Overview</span>
                </div>
                <div className="ManagerApprovalHomepageInsightWrapper">
                    <div className="ManagerApprovalHomepageApproveArea">
                        <div className="ManagerApprovalHomepageAreaHeader">
                            <div className="ManagerApprovalHomepageAreaHeaderText">
                            Control your Payroll 
                            </div>
                        </div>
                        <div className="PayslipApproveItem-container">
                        <PayslipReviewItem {...{employeesToReview: reviewData?.employees, alertsToReview: reviewData?.alerts}}/> 
                        {configs?.map((config, index) => {
                            return <PayslipApproveItem {...{header: `${config?.position} approval`,
                                                            subHeader: `Assignee: ${config?.position} - ${config?.name}`, emailAddress: config.email,
                                                            isApproved: approvals?.includes(config?.email)}} key={index}/>
                        })}
                        </div>
                    </div>
                    <div className="ManagerApprovalHomepageAlertsArea">
                        <div className="ManagerApprovalHomepageAreaHeader">
                            <div className="ManagerApprovalHomepageAreaHeaderText">
                            Pending Payslip Alerts 
                            </div>
                        </div>
                        { 
                        //@ts-ignore 
                        <NewAlertSummary />
                        }
                    </div>
                    <div className="ManagerApprovalHomepageChartArea">
                        <div className="ManagerApprovalHomepageAreaHeader">
                            <div className="ManagerApprovalHomepageAreaHeaderText">
                                Payroll Overview
                            </div>
                        </div>
                        <HomeNewChartsSegment chartData={chartData}></HomeNewChartsSegment>
                    </div>
                </div>
            </div>
        );
    }else{
        return (<div></div>);
    }
}

export default ManagerApprovalHomepage;