import { PayloadAction, createSlice } from '@reduxjs/toolkit';


export interface CommentEnforcerState {
    enforcerEnabled?: boolean;
    open: boolean;
    payslipID?: number;
    findingID?: number;
};

const initialState: CommentEnforcerState = {
    enforcerEnabled: false,
    open: false
};

export const CommentEnforcerStateSlice = createSlice({
  name: "CommentEnforcerState",
  initialState,
  reducers: {
    setCommentEnforcerState: (state, action: PayloadAction<CommentEnforcerState>) => {
        state.open = action.payload?.open;
        state.payslipID = action.payload?.payslipID;
        state.findingID = action.payload?.findingID;
    },
    setEnforcerEnabledState: (state, action: PayloadAction<boolean>) => {
      state.enforcerEnabled = action.payload;
    }
  }
});

export const { setCommentEnforcerState, setEnforcerEnabledState } = CommentEnforcerStateSlice.actions;

export default CommentEnforcerStateSlice.reducer;