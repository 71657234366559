import ReactModal from "react-modal";
import './PPOUploadModal.css';
import {useDropzone} from 'react-dropzone';
import { useState } from "react";
import axios from "axios";
import FileRows from "./PPOFileRows";
import { LinearProgress } from "@mui/material";
import useCustomParams from '../../../hooks/useCustomParams';

const PPOUploadModal = (props: {modalIsOpen: boolean, setOpenPPOConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUploadStatus: React.Dispatch<React.SetStateAction<string>>}) => {
  const [progressIsOpen, setProgressIsOpen] = useState(false);


  const { entity } = useCustomParams();

    const onPayrollDrop = (newPayrollFiles: any) => {
        setAcceptedPayrollFiles([...acceptedPayrollFiles, ...newPayrollFiles]);
    };

    const onBillingDrop = (newBillingFiles: any) => {
        setAcceptedBillingFiles([...acceptedBillingFiles, ...newBillingFiles]);
    };

  const onSend = () => {
    setProgressIsOpen(true);
    const formData = new FormData();
    for (let file of acceptedPayrollFiles) {
      formData.append('payroll_files', file)
    }

    for (let file of acceptedBillingFiles) {
        formData.append('billing_files', file)
    }

    axios.post(`/api/ppo/upload/${entity}`, formData, {
      headers: {
          'content-type': 'multipart/form-data'
      },
      onUploadProgress: data => {
        // @ts-ignore
        setProgress(Math.round((100 * data.loaded) / data.total));
      }
    }).then(response => {
        console.log("loaded files to server");
        props.setModalIsOpen(false);
        setAcceptedPayrollFiles([]);
        setAcceptedBillingFiles([]);
        setProgress(0);
        setProgressIsOpen(false);
        props.setOpenPPOConfirmationDialog(true)
        props.setUploadStatus('Done');
    }).catch(error => {
        console.log("error in uploading files: ", error);
        props.setModalIsOpen(false);
        setAcceptedPayrollFiles([]);
        setAcceptedBillingFiles([]);
        setProgress(0);
        setProgressIsOpen(false);
        props.setUploadStatus('Fail');
    });
  };

  const payrollDropzoneManager = useDropzone({onDrop:onPayrollDrop});
  const billingDropzoneManager = useDropzone({onDrop:onBillingDrop});
  const [progress, setProgress] = useState(0);
    const [acceptedPayrollFiles, setAcceptedPayrollFiles] = useState<File[]>([] as File[]);
    const [acceptedBillingFiles, setAcceptedBillingFiles] = useState<File[]>([] as File[]);


    const getFileTypes = (files: File[]) => {
        return files.map((file: File) => {
            return file?.type?.split('/')[1];
        });
    }

    const payrollFileTypes = getFileTypes(acceptedPayrollFiles);
    const billingFileTypes = getFileTypes(acceptedBillingFiles);

    const containUnsupportedFiles = (fileTypes: string[]) => {
        return fileTypes.some((fileType: string) => {
            return fileType !== 'csv' && fileType !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'vnd.ms-excel' &&
                fileType !== 'pdf';
        });
    }

    let containUnsupportedPayrollFiles = containUnsupportedFiles(payrollFileTypes);
    let containUnsupportedBillingFiles = containUnsupportedFiles(billingFileTypes);

  if (payrollFileTypes?.length === 0) {
      containUnsupportedPayrollFiles = true;
  }

    if (billingFileTypes?.length === 0) {
        containUnsupportedBillingFiles = true;
    }

  const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "900px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
        overlay: {
          backgroundColor: "#102E442E",
          backdropFilter: "blur(4px) brightness(100%)",
        }
  };
    
  function closeHandler() {
    props.setModalIsOpen(false);
    setAcceptedPayrollFiles([]);
    setAcceptedBillingFiles([]);
    setProgress(0);
    setProgressIsOpen(false);
  }

  return (
    <div>
      <ReactModal
        ariaHideApp={false}
        isOpen={props.modalIsOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
          <div className="upload-ppo-popup">
              <div  className='upload-ppo-popup-close-header'>
                  <div className='upload-ppo-popup-x-btn' onClick={closeHandler}></div>
              </div>
              <div className="upload-ppo-popup-wrapper-section">
                  <div className="upload-ppo-popup-payroll-section">
                      <div className="upload-ppo-popup-header-section">
                          <div className='upload-ppo-popup-header'>Upload <b>PAYROLL</b> Files</div>
                      </div>
                      <div className="upload-ppo-popup-body-section">
                          <div {...payrollDropzoneManager.getRootProps({className: 'upload-ppo-popup-drop-zone'})}
                               style={{backgroundColor: `${payrollDropzoneManager.isDragActive ? '#91c3ff' : "#e5f0ff"}`}}>
                              <div className="upload-ppo-popup-img"></div>
                              <div className='upload-ppo-popup-explanation'>Drag your file here or click to select</div>
                              <input {...payrollDropzoneManager.getInputProps()} id="upload-ppo-popup-input"/>
                          </div>
                          {payrollFileTypes?.length > 0 && containUnsupportedPayrollFiles &&
                              <div className="wrong-file-type-message" style={{color: "red", padding: "5px"}}>Please
                                  upload
                                  a CSV or Excel format</div>}
                          <div className="upload-ppo-popup-files-section">
                              <FileRows files={acceptedPayrollFiles} setAcceptedFiles={setAcceptedPayrollFiles}/>
                          </div>
                          <div className="upload-ppo-popup-file-loading"
                               style={{visibility: `${progressIsOpen ? 'visible' : 'hidden'}`}}>
                              <div className="upload-ppo-popup-file-loading-bar">
                                  <LinearProgress variant="determinate" value={progress}/>
                              </div>
                              <div className="upload-ppo-popup-file-loading-text">Uploading...</div>
                          </div>
                      </div>
                  </div>
                  <div className="upload-ppo-popup-billing-section">
                      <div className="upload-ppo-popup-header-section">
                          <div className='upload-ppo-popup-header'>Upload <b>BILLING</b> Files</div>
                      </div>
                      <div className="upload-ppo-popup-body-section">
                          <div {...billingDropzoneManager.getRootProps({className: 'upload-ppo-popup-drop-zone'})}
                               style={{backgroundColor: `${billingDropzoneManager.isDragActive ? '#adff8d' : "#e8ffe1"}`}}>
                              <div className="upload-ppo-popup-img"></div>
                              <div className='upload-ppo-popup-explanation'>Drag your file here or click to select</div>
                              <input {...billingDropzoneManager.getInputProps()} id="upload-ppo-popup-input"/>
                          </div>
                          {billingFileTypes?.length > 0 && containUnsupportedBillingFiles &&
                              <div className="wrong-file-type-message" style={{color: "red", padding: "5px"}}>Please
                                  upload
                                  a CSV or Excel format</div>}
                          <div className="upload-ppo-popup-files-section">
                              <FileRows files={acceptedBillingFiles} setAcceptedFiles={setAcceptedBillingFiles}/>
                          </div>
                          <div className="upload-ppo-popup-file-loading"
                               style={{visibility: `${progressIsOpen ? 'visible' : 'hidden'}`}}>
                              <div className="upload-ppo-popup-file-loading-bar">
                                  <LinearProgress variant="determinate" value={progress}/>
                              </div>
                              <div className="upload-ppo-popup-file-loading-text">Uploading...</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="upload-ppo-popup-btn-panel">
                  <button className='upload-ppo-popup-discard-btn' onClick={closeHandler}>Discard</button>
                  <button className='upload-ppo-popup-save-btn' onClick={onSend}
                          style={{backgroundColor: `${containUnsupportedPayrollFiles || containUnsupportedBillingFiles ? 'grey' : ''}`,
                                    cursor: `${containUnsupportedPayrollFiles || containUnsupportedBillingFiles ? 'not-allowed' : 'pointer'}`}}
                          disabled={containUnsupportedPayrollFiles || containUnsupportedBillingFiles}>Save
                  </button>
              </div>
          </div>
      </ReactModal>
    </div>
  );
};

export default PPOUploadModal;