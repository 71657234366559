import './HomeNewInsight.css';
import {Insight} from "../HomeNew/HomeNewObjects";
import mixpanel from "mixpanel-browser";
import {setCategoryType, setItemsToOmit, setReportSortState} from "../../store/reportSlice";
import {getConfigByTabName} from "../Report/filtersTabs/columnConfig";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AiFillDollarCircle} from "react-icons/ai";
import {GoClockFill} from "react-icons/go";
import DeepLink from "../DeepLink/DeepLink";
import {CiBellOn} from "react-icons/ci";
import {HiOutlineMagnifyingGlass} from "react-icons/hi2";
import {IoCheckmarkOutline} from "react-icons/io5";


function HomeNewInsight(props: {insightItem: Insight}) {
    return (
        <div className="HomeNewInsightItem">
            <div className="HomeNewInsightItemIcon">{getIcon(props.insightItem?.icon)}</div>
            <div className="HomeNewInsightItemText">
                <div className="HomeNewInsightItemTextTop">
                    <span style={{color: "#EF525B"}}>{props.insightItem?.topRed}</span>
                    {props.insightItem?.topBlack}
                    {props.insightItem?.topAsterisk && <sup style={{fontSize: 12}}>*</sup>}
                </div>
                <div className="HomeNewInsightItemTextBottom">
                    {props.insightItem?.bot}
                </div>
                {
                    props.insightItem?.botSmall &&
                    <div className="HomeNewInsightItemTextBottomSmall">
                        {props.insightItem?.botSmall}
                    </div>
                }
            </div>
            <DeepLink {...props.insightItem.link}/>
        </div>
    )
}

export default HomeNewInsight

function getIcon(iconID:string) {
    switch (iconID){
        case 'Dollar':
            return <AiFillDollarCircle/>
        case 'Clock':
            return <GoClockFill/>
        case 'Bell':
            return <CiBellOn />
        case 'Glass':
            return <HiOutlineMagnifyingGlass />
        case 'Check':
            return <IoCheckmarkOutline />
        default:
            return <AiFillDollarCircle/>
    }

}