export function formatNumber(number, percent=false, floor=false,
                             naNAN = true, roundUp = true, decimals=0) {
    if (isNaN(number)) {
        if(naNAN) {
            return ''
        } else {
            return number
        }

    }
    if (floor) {
        let exp = Math.pow(10, decimals)
        number = Math.floor(number * exp) / exp;
    }
    else if (roundUp) {
        let exp = Math.pow(10, decimals)
        number = Math.round(number * exp) / exp;
        number = number === 0 ? 0 : number; // resolve Math.round(-0.5) to -0
    }

    if (number >= 0) {
        if (percent) {
            return number.toLocaleString() + '%';
        } else {
            return number.toLocaleString();
        }
    } else {
        if (percent) {
            return `(${Math.abs(number).toLocaleString()}%)`
        } else {
            return `(${Math.abs(number).toLocaleString()})`
        }
    }
}

export function RoundToTwoUsingMathRound(num) {
    return Math.round(num * 100) / 100;
}

export function GetDomain(minVal, maxVal) {
    if (!isFinite(minVal) || !isFinite(maxVal)){
        return [minVal, maxVal]
    }
    let fMin = Math.floor(minVal)
    let minLength = fMin.toString().length
    let minDiv = Math.pow(10, minLength -2)

    let newMin = parseInt(fMin / minDiv, 10) * minDiv;

    let cMax = Math.ceil(maxVal)
    let maxLength = cMax.toString().length
    let maxDiv = Math.pow(10, maxLength -2)

    let newMax = (parseInt(cMax / maxDiv, 10) + 1) * maxDiv;

    return [newMin, newMax]
}

export function getNumberFormatCell(field) {
    return function (row, index, column, id) {
        return formatNumber(row[field])
    }
}
const shortDateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' })
const dateFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short',
    day: 'numeric' })
const datetimeFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day:
        'numeric', hour: 'numeric', minute: 'numeric' })
const utcDateFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short',
    day: 'numeric', timeZone: 'UTC' })
const utcDatetimeFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day:
        'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' })

export function formatDate(date, dateTime=false, shortDate=false, basedUTC=false){
    let dateObject;
    if (date instanceof Date) {
        dateObject = date;
    } else {
        dateObject = new Date(date);
    }
    if (dateObject.toString() !== 'Invalid Date') {
        if (dateTime) {
            return basedUTC ? utcDatetimeFormatter.format(dateObject) : datetimeFormatter.format(dateObject)
        } else {
            if (shortDate) {
                return shortDateFormatter.format(dateObject)
            } else {
                return basedUTC ? utcDateFormatter.format(dateObject) : dateFormatter.format(dateObject)
            }
        }
    } else {
        return ''
    }
}

const baseDate = new Date(100000000000)
const maxDate = new Date(10000000000000)
export function isStringDate(date) {
    let newDate = new Date(date)
    return (newDate !== "Invalid Date") && !isNaN(newDate) && newDate > baseDate && newDate < maxDate;
}

export function ParseToFixed2(num){
    if (num){
        return Number(parseFloat(num).toFixed(2));
    } else {
        return 0;
    }
}


export const superDenseStyle = {
    headCells: {
        style: {
            paddingLeft: '1px', // override the cell padding for head cells
            paddingRight: '1px',
        },
    },
    cells: {
        style: {
            paddingLeft: '1px', // override the cell padding for data cells
            paddingRight: '1px',
        },
    },
};
