import './AverageRateGraph.css';
import {Bar, ComposedChart, Label, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatNumber} from "../../utils/PresentationUtils";
import {formatDate} from "../../utils/PresentationUtils";

const AverageRateGraph = (props) => {
    const {data} = props;
    const formatedData = data.map((item) => {
        const formatedDate = formatDate(item.cycle);
        item.cycle = formatedDate;
        return item;
    });

  return(
    <div className="average-rate-graph">
        <div id="gross-change">Average Rate</div>
        <ResponsiveContainer height={props.height ? props.height : 250}>
            <ComposedChart  data={formatedData}>
                <XAxis dataKey="cycle" />
                <YAxis yAxisId={1}  tick={{ fontSize: 14, }} tickCount={6}
                        domain={([minValue, maxValue]) => { return [0, Math.ceil(maxValue)]}}>
                    <Label
                        value='Average Hours'
                        angle={-90}
                        position='outside'
                        fontSize={14}
                        dx={-12}
                    />
                </YAxis>
                <YAxis yAxisId={2} orientation="right" tick={{ fontSize: 14, }} tickCount={6}
                        domain={([minValue, maxValue]) => { return [0, Math.ceil(maxValue)]}}>
                    <Label
                        value='Average Rate'
                        angle={-90}
                        position='outside'
                        fontSize={14}
                        dx={12}
                    />
                </YAxis>
                <Tooltip formatter={(value, name, props) => {return formatNumber(value)}}/>
                <Legend />
                <Bar yAxisId={1} dataKey="average_hours" barSize={20} fill="#02BBE3" name="Average Hours"/>
                <Bar yAxisId={1} dataKey="employee_hours" barSize={20} fill="#B6FF7D" name="Employee Average Hours"/>
                <Line yAxisId={2} type="monotone" dataKey="average_rate" stroke="#F9C1C4" strokeWidth={3} name="Average Rate"/>
                <Line yAxisId={2} type="monotone" dataKey="employee_rate" stroke="#EF525B" strokeWidth={3} name="Employee Average Rate"/>
            </ComposedChart>
        </ResponsiveContainer>
    </div>
  );
};

export default AverageRateGraph;