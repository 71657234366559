
class Employee {
    xid: string | undefined;
    name: string | undefined;
    department: string | undefined;
    unpaid_cycles: number | undefined;
    last_cycle: Date | undefined;
    terminated_approved: boolean = false;
}

export class EmployeesDashboard {
    terminated: Employee[] = [];
}
