import "./Insights.css"
import HomeNewInsight from "../HomeNewInsight/HomeNewInsight";
import {Insight} from "../HomeNew/HomeNewObjects";
import {useEffect, useState} from "react";
import axios from "axios";
import { LuClipboardCopy } from "react-icons/lu";
import useCustomParams from "../../hooks/useCustomParams";

function Insights() {
    const { entity, cycle } = useCustomParams();
    const [insights, setInsights] = useState<Insight[]>([] as Insight[]);

    useEffect(() => {
        getInsights();
        gety();
    }, [entity, cycle])

    const getInsights = () => {
        axios.get(`/api/insights/${entity}/${cycle}?length=100&ignore_manual=true`).then((res) => {
            if (res.data)
                setInsights(res.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    const gety = () => {
        axios.get(`/api/reports/${entity}/${cycle}/deep_insights`).then((res) => {
            if (res.data)
            {}
                // setInsights(res.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    const copyToClipboard = (insightItem: Insight) => {
        const text = JSON.stringify(insightItem)
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard:', text);
            })
            .catch(error => {
                console.error('Error copying text to clipboard:', error);
            });
    };

    return (
        insights.map((insightItem: Insight) =>
            {return <div className="insight-item">
                        <LuClipboardCopy className="insight-item-copy-btn" onClick={(e) => copyToClipboard(insightItem)} />
                        <HomeNewInsight insightItem={insightItem}  key={'ii_' + insightItem.topBlack}></HomeNewInsight>
                    </div>
            }
        )
    );
}

export default Insights;