import { Link } from "react-router-dom";
import useCustomParams from "../../../hooks/useCustomParams";
import "./PayslipApproveItem.css";

const PayslipReviewItem = (props:{employeesToReview: number, alertsToReview: number}) => {
    const { entity, cycle } = useCustomParams();
    const isApproved = props?.employeesToReview === 0 && props?.alertsToReview === 0;
    
    return (
        <div className={`PayslipReviewItem-card-base ${isApproved ? "card-approved" : "card"} PayslipReviewItem-card-first`}>
            <div className="PayslipReviewItem-card-data">
                <img src={`/PayslipReviewItem-imag-DOLLAR${isApproved ? "-finished" : ""}.svg`} alt="dollar" />
                <div className="PayslipReviewItem-text-area">
                    <div className="PayslipReviewItem-header">{`Review ${props?.employeesToReview} employees`}</div>
                    <div className="PayslipReviewItem-sub-header">{`${props?.alertsToReview} Alerts to Review`}</div>
                </div>
            </div>
            {isApproved ? <div className="PayslipReviewItem-btn-pending-and-approved">Approved</div>: <Link className="payslipReviewItem-link PayslipReviewItem-btn" to={`/${entity}/${cycle}/report`}>Review</Link>}
        </div>
    );
}

export default PayslipReviewItem;