import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './PayslipSummary.css';
import mixpanel from 'mixpanel-browser';
import PayslipBubble from '../PayslipBubble';
import useCustomParams from "../../../hooks/useCustomParams";
import {useNavigate} from "react-router-dom";

const PayslipSummary = (props: {title: any, fieldsData: any, currentFindings: any, setHighlight: any,
                                highlightID: any, setClicked?: any, btn: any, payslip_department: any}) => {

    const { entity, cycle } = useCustomParams();
    const navigate = useNavigate();

    const findings = props?.currentFindings?.filter((x: any) => x?.severity < 3 && x?.approved !== 1)?.sort((a: any, b: any) => {
        const aSeverity = a?.severity;
        const bSeverity = b?.severity;
        if(aSeverity < bSeverity) return -1;
        if(aSeverity > bSeverity) return 1;
        return 0;
    });

    function onMouseClicked(e: any) {
        mixpanel.track('shw finding row from payslip summary');
        const id = e.currentTarget.getAttribute('kid');
        if (id){
            const field = props?.fieldsData?.find((x: any) => {
                const result = x?.field_display_name === id;
                return result;
            });
            const fieldRow = field?.field_display_name;
            props?.setClicked(fieldRow);
        }
    };

    function onMouseEnter(e: any) {
        const id = e.currentTarget.getAttribute('kid');
        if (id){
            const field = props?.fieldsData?.find((x: any) => {
                const result = x?.field_display_name === id;
                return result;
            });
            const fieldRow = field?.field_display_name;
            props?.setHighlight(fieldRow);
        }
    };

    function onMouseLeave(e: any) {
        props?.setHighlight("-1");
    }

    const getHighlightID = (id: any) => {
        let field = props?.fieldsData?.find((x: any) => x?.field_system_id === id);
        if(!field)field = field = props?.fieldsData?.find((x: any) => x?.field_source_id === id);
        const fieldRow = field?.field_display_name;
        return fieldRow;
    }

    const displayOvertimeReportLink = (field_name: string) => {
        const result = props?.fieldsData?.some((x: any) => {
            const match = x?.field_system_id === field_name || x?.field_source_id === field_name;
            if (!match)
                return false;
            return x?.field_sub_section === 'EARNINGS_OVERTIME';
        });

        return result;
    }

    const navigateToOvertimeReport = () => {
        navigate(`/${entity}/${cycle}/overtime?department=${props?.payslip_department}`);
    }

    const isHover = (x: any) => props.highlightID === getHighlightID(x) ? "hover" : "";

    return (
        <>
        {findings?.length === 0 ?
            <Accordion defaultExpanded={true} className='payslip-summary-empty-state'>
                <AccordionSummary expandIcon={<img className="sheet-summary-icon" src='/expandIcon.svg' alt='open section'/>} aria-controls="panel1-content" id="panel1-header" >
                    <span className='header'>{props?.title}</span>
                </AccordionSummary>
                <AccordionDetails>
                        <img className='icon' alt='no alerts icon' src='/green-ok-circle.svg'/>
                        <span className='sub-header'>All clear!</span>
                </AccordionDetails>
            </Accordion>
            :
            <Accordion defaultExpanded={true} className='payslip-summary-filled-state'>
                <AccordionSummary expandIcon={<img className="sheet-summary-icon" src='/expandIcon.svg' alt='open section'/>} aria-controls="panel1-content" id="panel1-header" >
                    <span className='header'>{props.title}</span>
                    <span className='PayslipSummary-approve-all'>{props?.btn}</span>
                </AccordionSummary>
                <AccordionDetails>
                    {findings?.length > 0 && findings.map((x: any, i: number) =>
                        //@ts-ignore
                        <div className={`finding-row ${isHover(x?.field_name)}`} kid={getHighlightID(x?.field_name)}
                             onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={(e) => onMouseClicked(e)}>
                            <PayslipBubble severity={x?.approved === 1 ? 3 : x?.severity} shortTxt={true}/>
                            <div key={i} className='finding-row-txt'>
                                {x?.message}
                                {
                                    (displayOvertimeReportLink(x?.field_name) && x?.severity === 1) ?
                                    <span className="overtime-report-link" key={i} onClick={navigateToOvertimeReport}>See department overtime report</span> : ""
                                }
                            </div>
                            <img className='btn-icon' src="/finding-button.svg" alt="button"/>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        }
        </>
    );
}

export default PayslipSummary;