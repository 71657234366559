import { AccordionDetails } from "@mui/material";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import './TechnicalDetails.css';
import Tooltip from '@mui/material/Tooltip';
import { toNameCase } from '../../../utils/string.utils';

const TechnicalDetails = (props: any) => {
    function childClicked(event: any) {
        event?.stopPropagation(); // This stops the click event from propagating to the parent and so not closing the category!
    }

    return (
        <AccordionDetails className="technical-accordion-details" style={{display:'flex', flexWrap:"wrap", padding:0}} onClick={childClicked}>
            {props?.field_groups &&
                Object?.keys(props?.field_groups)?.sort(function(a,b){return props?.field_groups[a]?.field_display_order -props?.field_groups[b]?.field_display_order})
                ?.map(field_name => {
                    let field_total = props?.field_groups[field_name]['Total'];
                    const type = props?.field_groups[field_name]['field_value_type'];
                    if (field_total) {
                        return renderLine(field_name, field_total,
                            props?.data, props.findings_translated_id, props?.warnings_translated_id, type)
                    }
                })
            }
            {props?.employee_info && 
                Object?.keys(props?.employee_info)?.map(field_name => {
                    let field_value = props?.employee_info[field_name];
                    if (field_value !== null) {
                        return renderEmployeeInfoLine(field_name, field_value)
                    }
                })
            }
        </AccordionDetails>
    );

    function renderEmployeeInfoLine(field_name: any, field_value: any) {
        const display_name = toNameCase(field_name?.replace('_', ' '));
        const color = display_name === props?.highlightID ? "#DEEAEC" : "";

        return (
            //@ts-ignore
            <div className="payslip-sheet-technical-row" style={{backgroundColor: color}} key={display_name + '_row'}
                 onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <span className="payslip-sheet-technical-row-key">
                    {display_name}{display_name?.length > 14 ? "..." : ""}
                </span>
                <span className={getClasses('payslip-sheet-technical-row-value', null, null,
                    `${props?.findings_translated_id !== null ? props?.highlightID : ""}`)}>
                        {field_value}
                </span>
            </div>
        )
    }

    function renderLine(display_name: any, total_field: any, fields: any,
                        findings_translated_id: any, warnings_translated_id: any, type: string) {
        let total_value = total_field ? (fields[total_field] || '') : '';
        const formatedData = total_value && type ? formatData(total_value, type) : total_value;
        let total_finding = findings_translated_id[total_field];
        let total_warning = warnings_translated_id[total_field];
        const largeFieldName = display_name?.length > 14;

        const color = display_name === props?.highlightID ? "#DEEAEC" : "";

        return (
            //@ts-ignore
            <div className="payslip-sheet-technical-row" style={{backgroundColor: color}} key={display_name + '_row'} category={props?.category} kid={props?.findings_translated_id === null ? "" : display_name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <span className="payslip-sheet-technical-row-key" title={largeFieldName ? display_name : null} style={largeFieldName ? {display:"inline-flex", textOverflow:"ellipsis", overflow: 'hidden'} : {}}>{largeFieldName ? display_name?.slice(0, 12) : display_name}{display_name?.length > 14 ? "..." : ""}
                    {
                        display_name === 'Average Rate' &&
                        <span className="payslip-sheet-info-astrix" title={"Average is based on working hours"}>*</span>
                    }
                </span>
                <Tooltip title={`${total_finding?.message ? total_finding?.message : ""}`}>                    
                    <span className={getClasses('payslip-sheet-technical-row-value', total_finding, total_warning,
                                                `${props?.findings_translated_id !== null ? props?.highlightID : ""}`)}>
                        {formatedData}
                    </span>
                </Tooltip>
                <span data-track='Approve' className={getClasses('payslip-sheet-technical-row-comment', total_finding, total_warning,
                                            `${props?.findings_translated_id !== null ? props?.highlightID : ""}`)}
                    onClick={() => approveAlert(total_finding?.payslip_id, total_finding?.id, true)}>
                    {total_finding && total_finding?.approved === 0 && props?.enableApproveBtn && <span className="btn-approve"><img className='btn-approve-icon' src='/tick-circle.svg' alt='approve'/>Approve</span>}
                </span>
            </div>
        )
    }

    function onMouseEnter(e: any) {
        let id = e?.currentTarget.getAttribute('kid');
        const field = props?.fields?.find((x: any) => x?.field_display_name === id);
        const fieldRow = field?.field_display_name;
        props?.setHighlight(fieldRow);
    };

    function onMouseLeave(e: any) {
        props?.setHighlight("-1");
    }

    function getClasses(baseClass: any, finding: any,
                        total_warning: any, highlightID: any) {
        let newClasses = baseClass
        if (finding) {
            if (finding?.approved == 0){
                if(finding?.severity > 1){
                    newClasses += ' warning';
                }else{
                     newClasses += ' error';
                }
            } else {
                newClasses += ' info'
            }

            if (finding?.id == highlightID){
                newClasses += ' MarkingHighlight'
            }
        }
        else if(total_warning)
        {
            newClasses += ' info'
        }
        else{
            newClasses += ' disabled-cell'
        }

        return newClasses
    }

    function formatData(total_value: any, type: string){
        let result = '';

        switch (type) {
            case 'Date':
                const date = new Date(total_value);
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
                result = date?.toLocaleDateString('en-US', options);
                break;
            case 'Amount':
                result = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(total_value);
                break;
            default:
                result = total_value;
                break;
        }

        return result;
    }

    function approveAlert(payslip: any, finding: any, approved: any) {
        mixpanel.track('approve Alert', {'payslip_id': payslip, 'finding_id': finding, 'approved': approved})
        axios.post(`/api/payslips/${payslip}/comment`,
            {finding: finding, content: "", approved: approved, recipients: []},
            //@ts-ignore
            {'Content-Type': 'application/json'}).then((res) => {
        })
        .catch((err) => {
            console.log(err)
        });
    }
};

export default TechnicalDetails;