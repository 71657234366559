import { MdTrendingDown, MdOutlineTrendingUp } from "react-icons/md";
import {evaluate} from 'mathjs';
import { Card, PocModel } from '../HomePOCCards/PocObjects';
import './InsightCard.css';

function InsightCard(props: {cardItem: Card, configData: PocModel, avrHourlyBillingRate: number}){
    const iconsArray = ['', 'gross-margin.svg', 'gross-margin-per-hour.svg', 'total-hours.svg', 'hourly-rate.svg'];
    const a = props?.configData?.contextInsights?.cards?.card1?.value;
    const b = props?.configData?.contextInsights?.cards?.card2?.value;
    const c = props?.configData?.contextInsights?.cards?.card3?.value;
    const d = props?.configData?.contextInsights?.cards?.card4?.value;
    const x = props?.avrHourlyBillingRate;

    const cardValue = props?.cardItem?.value ? props?.cardItem?.value : evaluateFormula(props?.cardItem?.template, {a, b, c, d}, x);

    return (
        <div className='InsightCard'>
            <div className='InsightCardHeaderRow'><img src={`/${iconsArray[props?.cardItem?.logo]}`} alt={props?.cardItem?.header} /><span className='InsightCardHeaderText'>{props?.cardItem?.header}</span></div>
            <div className='InsightCardValueRow'>
                <span className='InsightCardValueText'>{props?.cardItem?.symbolBeforVal}{cardValue}{props?.cardItem?.symbolAfterVal}</span>
                <span style={{backgroundColor: `${props?.cardItem?.arrowDirection === "up" ? "#a3ebda59" : "#f9c1c459"}`, color: `${props?.cardItem?.arrowDirection === "up" ? "#1ACEA3" : "#EF525B"}`}}
                 className='InsightCardPercentageArea'>
                    <img src={props?.cardItem?.arrowDirection === "up" ? "/arrow-up-small.svg" : "/arrow-down-small.svg"} alt="arrow"/>
                    <span></span>
                    <span>{props?.cardItem?.arrowVal}%</span>
                </span>
            </div>
            {props?.cardItem?.benchmark &&
                <div className='InsightCardBenchmarkRow' title="Calculated based on similar companies in your industry">
                    {props?.cardItem?.benchmarkReverseFormula ? 
                        props?.cardItem?.benchmark < cardValue ? <MdTrendingDown className="CardBenchmarkIcon" style={{color: "#EF525B"}}/> : <MdOutlineTrendingUp className="CardBenchmarkIcon" style={{color: "#1ACEA3"}}/> :
                        props?.cardItem?.benchmark > cardValue ? <MdTrendingDown className="CardBenchmarkIcon" style={{color: "#EF525B"}}/> : <MdOutlineTrendingUp className="CardBenchmarkIcon" style={{color: "#1ACEA3"}}/>
                    }
                    <span>Industry Benchmark:</span>
                    <span>{props?.cardItem?.benchmark}%</span>
                </div>
            }
        </div>
    )
}

export default InsightCard;

function evaluateFormula(template: string, values: any, x: any){
    if(!template) return 0;

    const formula = template
    .replace(/a/g, values?.a ? values?.a : "")
    .replace(/b/g, values?.b ? values?.b : "")
    .replace(/c/g, values?.c ? values?.c : "")
    .replace(/d/g, values?.d ? values?.d : "")
    .replace(/x/g, x ? x : "")
    .replace(/,/g, "");

    try {
        console.log(formula);
        const result = evaluate(formula).toFixed(1);
        return result;
    } catch (error) {
        console.log(error);
        return "";
    }
}