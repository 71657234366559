import { RiStickyNoteFill } from "react-icons/ri";
import { FaNotesMedical } from "react-icons/fa6";
import EmployeeNotesInput from "./EmployeeNotesInput";
import { useEffect, useRef, useState } from "react";
import './EmployeeNotes.css';


const EmployeeNotes = (props: {employeeXid: string}) => {
    const [open, setOpen] = useState(false);
    const EmployeeNotesRef = useRef(null);
    const [notes, setNotes] = useState('');

    const icon = notes ? <RiStickyNoteFill/> : <FaNotesMedical/>; 

    const handleClick = (event: React.MouseEvent) => {     
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setOpen(!open);
    }

    const handleClickOutside = (event: any) => {
        const iconElement = document.querySelector('.EmployeeNotes-info-icon');
        if (iconElement && iconElement.contains(event.target)) {
            return;
        }

        //@ts-ignore
        if (EmployeeNotesRef.current && !EmployeeNotesRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        
        return () => {
          document.removeEventListener('mousedown', handleClickOutside, true);
        };
      }, []);

    return (
        <>
            <div title={`${notes?.length > 0 ? notes : "Add employee notes"}`} onClick={(event: React.MouseEvent) => handleClick(event)} className='EmployeeNotes-info-icon'>{icon}</div>
            <div style={{display: `${open ? '' : "none"}`}} ref={EmployeeNotesRef}><EmployeeNotesInput open={open} employeeXid={props?.employeeXid} setNotes={setNotes}/></div>
        </>
    );
}

export default EmployeeNotes;