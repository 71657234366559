import {useEffect, useState} from 'react';
import './AverageRateDashboard.css';
import axios from "axios";
import DataTable from "react-data-table-component";
import AverageRateGraph from "../AverageRateGraph/AverageRateGraph";
import conditionalRowStyles from "../Dashboard/DashboardUtils/greyTableStyle";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedField, setSelectedFieldValue, setSelectedEmployee} from "../../store/AverageRateDashboardSlice";
import useCustomParams from "../../hooks/useCustomParams";

function AverageRateDashboard() {
    const [fields, setFields] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const [data, setData] = useState([]);
    const [employees, setEmployees] = useState([]);

    const dispatch = useDispatch();
    const dispatchSelectedField = (value) => dispatch(setSelectedField(value));
    const dispatchSelectedFieldValue = (value) => dispatch(setSelectedFieldValue(value));
    const dispatchSelectedEmployee = (value) => dispatch(setSelectedEmployee(value));

    const selectedField = useSelector((state) => state?.averageRateDashboardReducer?.selectedField);
    const selectedFieldValue = useSelector((state) => state?.averageRateDashboardReducer?.selectedFieldValue);
    const selectedEmployee = useSelector((state) => state?.averageRateDashboardReducer?.selectedEmployee);
    const { entity, cycle } = useCustomParams();

    useEffect(() => {
        getFields(setFields);

        if (selectedField && selectedFieldValue && selectedEmployee) {
            getFieldValues(selectedField, setFieldValues);
            getData(selectedField, selectedFieldValue, selectedEmployee, setData, setEmployees);
        }
    }, [entity, cycle]);

    useEffect(() => {
        getFields(setFields);

        if (selectedField && selectedFieldValue && selectedEmployee) {
            getFieldValues(selectedField, setFieldValues);
            getData(selectedField, selectedFieldValue, selectedEmployee, setData, setEmployees);
        }
    }, [selectedField, selectedFieldValue, selectedEmployee, entity, cycle]);

    useEffect(() => {
        getFieldValues(selectedField, setFieldValues);
    }, [selectedField]);

    useEffect(() => {
        getData(selectedField, selectedFieldValue, selectedEmployee, setData, setEmployees);
    }, [selectedFieldValue, selectedEmployee, entity, cycle]);

    return (
        <div className="average-rate-dashboard">
            <div className="employee-dashboard-header">
                <img src='/Employee Dashboard.svg' alt='employee dashboard'/>
                <span className='employee-dashboard-header-txt'>Employee Dashboard</span>
            </div>
            <div className="AverageRateDashboardTop">
                <div className="average-rateDashboard-controls">
                    <Select displayEmpty value ={selectedField} onChange={(e) => dispatchSelectedField(e.target.value)} className="average-rateDashboard-control">
                        <MenuItem className='option-design' key="f_" value="">Select Category</MenuItem>
                        {fields.map((sf, i) => <MenuItem className='option-design' key={"f_"+ i} value={sf['field_system_id']}>{sf['field_display_name']}</MenuItem> )}
                    </Select>
                    <Select displayEmpty value ={selectedFieldValue} onChange={(e) => dispatchSelectedFieldValue(e.target.value)} className="average-rateDashboard-control">
                        <MenuItem className='option-design' key="fv_" value="">Select Category Value</MenuItem>
                        {fieldValues && fieldValues.map((sfv, i) => <MenuItem className='option-design' key={"fv_"+ i} value={sfv}>{sfv}</MenuItem> )}
                    </Select>
                    <Select displayEmpty value ={selectedEmployee} onChange={(e) => dispatchSelectedEmployee(e.target.value)} className="average-rateDashboard-control">
                        <MenuItem className='option-design' key="se_" value="">Select Employee To Compare</MenuItem>
                        {employees && employees.map((se, i) => <MenuItem className='option-design' key={"se_"+ i} value={se['employee_xid']}>{se['name']} ({se['employee_xid']})</MenuItem> )}
                    </Select>
                </div>
                <AverageRateGraph data={data}/>
            </div>
            <div className="average-rate-dashboard-list">
                <div>Average Rate - Top Outliers</div>
                <DataTable
                    key={selectedEmployee}
                    conditionalRowStyles={conditionalRowStyles}
                    columns={columns}
                    data={employees}
                    defaultSortFieldId={3}
                    defaultSortAsc={false}
                    theme="default"
                    responsive
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 50, 500]}
                    onRowClicked={(row) => dispatchSelectedEmployee(row.employee_xid)}
                    customStyles={{rows:{ style: { cursor: 'pointer'}}}}
                />
            </div>
        </div>
    )

    function getData(field, fieldValue) {
        axios.get(`/api/reports/${entity}/${cycle}/rate_data/?field=${field}&field_value=${fieldValue}&employee_id=${selectedEmployee}`)
            .then((response) => {
                setData(response.data.data);
                setEmployees(response.data.employees);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getFields(setFields) {
        axios.get(`/api/payslips/${entity}/fields`)
            .then((response) => {
                setFields(response.data.filter((x) => x['field_section'] == 'Technical Information'));
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

const columns = [
    {
        name: 'Employee Name',
        selector: row => row.name,
        sortable: true,
        style: {
            fontWeight: 'bold'
        },
    },
    {
        name: 'Employee ID',
        selector: row => row.employee_xid,
        sortable: true,
    },
    {
        name: 'Average Rate',
        selector: row => "$" + row?.average_rate?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1}),
        sortable: true,
    },
    {
        name: 'Hours',
        selector: row => row.hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1}),
        sortable: true,
    },
    {
        name: `Category Average Rate`,
        selector: row => "$" + row.category_average_rate?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1}),
        sortable: true,
    },
    {
        name: 'Hours',
        selector: row => row.category_hours?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1}),
        sortable: true,
    },
    {
        name: 'Rate Delta',
        selector: row => "$" + row.average_rate_delta?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1}),
        sortable: true,
    },
    {
        name: 'Hours Delta',
        selector: row => row.hours_delta?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1}),
        sortable: true,
    },
];

function getFieldValues(field, setFieldValues) {
    if (!field) return;

    axios.get(`/api/payslips/fields/${field}/values`)
        .then((response) => {
            setFieldValues(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
}



export default AverageRateDashboard;