import {useEffect, useState} from 'react';
import './Dashboard.css';
import axios from "axios";
import HoursBreakdown from "../DashboardComponents/HoursBreakdown/HoursBreakdown";
import EmployeeByTotalHours from "../DashboardComponents/EmployeeByTotalHours/EmployeeByTotalHours";
import OneOffPayments from "../DashboardComponents/OneOffPayments/OneOffPayments";
import TotalEarningsBreakdown from "../DashboardComponents/TotalEarningsBreakdown/TotalEarningsBreakdown";
import FieldAnalysis from "../DashboardComponents/FieldAnalysis/FieldAnalysis";
import GrossChangeGraph from "../DashboardComponents/GrossChangeGraph/GrossChangeGraph";
import {useSearchParams} from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useCustomParams from "../../hooks/useCustomParams";


function Dashboard(){
    const [data, setData] = useState({});
    const [reportData, setReportData] = useState({});
    const [analysisFields, setAnalysisFields] = useState([]);
    const [selectedField, setSelectedField] = useState('');
    const { entity, cycle } = useCustomParams();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getDashboardsData(setData);
        getDashboardData(setReportData);
        getFields(setAnalysisFields);

        const costAnalysis = searchParams.get('costAnalysis');
        if (costAnalysis) {
            setSelectedField(costAnalysis);
        }
    }, [entity, cycle]);

    function selectedFieldChanged(e) {
        setSelectedField(e.target.value);
    }

    function cleanReportDataHours(currReportData) {
        for (const index in currReportData.hours) {
            const obj = currReportData.hours[index];
            if (obj.name !== "Overtime")
                continue

            const cleanReportDepartments = [];
            for (const index_department in obj.departments)
            {
                const department = obj.departments[index_department];
                if (department.hours > 0 || department.last_cycle_hours > 0)
                    cleanReportDepartments.push(department);
            }

            obj.departments = cleanReportDepartments;
        }

        setReportData(currReportData);
    }

    return (   
        <div className="dashboard">
            <div className="Grid0">
                <div className="dashboard-header">
                    <img src='/dashboard-header.svg' alt='dashboard'/>
                    <span className='dashboard-header-txt'>Dashboard</span>
                </div>
            </div>
            <div className="Grid1">
                <GrossChangeGraph data={data['gross_over_time']}/>
            </div>
            <div className="Grid2">
                <HoursBreakdown data={reportData.hours}></HoursBreakdown>
            </div>
            <div className="Grid3">
                <EmployeeByTotalHours data={reportData.hours2}></EmployeeByTotalHours>
            </div>
            <div className="Grid4">
                <OneOffPayments data={reportData.one_time_payments}></OneOffPayments>
            </div>
            <div className="Grid5">
                <TotalEarningsBreakdown data={reportData.total_earnings}></TotalEarningsBreakdown>
            </div>
            <div className="Grid6">
                {
                    Object.keys(reportData).length > 0 &&
                    <>
                        <div className='dashboard-component-header'>
                            <div id="cost-analysis">Cost Analysis</div>
                            <Select displayEmpty value={selectedField} onChange={selectedFieldChanged}
                                    className="cost-analysis-filter">
                                <MenuItem className='option-design' key="o_" value="">Select field to group
                                    by</MenuItem>
                                {analysisFields.map((af, i) => <MenuItem className='option-design' key={"o_" + i}
                                                                         value={af.field_system_id}>{af.field_display_name}</MenuItem>)}
                            </Select>
                        </div>
                        <FieldAnalysis field={selectedField}></FieldAnalysis>
                    </>
                }
            </div>
        </div>
    );

    function getDashboardsData(setData) {
        axios.get(`/api/reports/${entity}/${cycle}/historic_data/`).then((res) => {
            if (res.data) {
                setData(res.data)
            } else {
                setData([])
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    function getDashboardData(setReportData) {
        axios.get(`/api/reports/${entity}/${cycle}/new_dashboard_data`).then((res) => {
            if (res.data) {
                setReportData(res.data);
                cleanReportDataHours(res.data);
            } else {
                setReportData([])
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    function getFields(setAnalysisFields) {
        axios.get(`/api/payslips/${entity}/fields`).then((res) => {
            if (res.data) {
                const analysisFields = res.data.filter(x => x.field_section == 'Technical Information')
                setAnalysisFields(analysisFields)
            } else {
                setAnalysisFields([])
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

export default Dashboard;