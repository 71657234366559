import './confirmationDialog.css';
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { FaCircleExclamation } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";


export interface ConfirmationDialogRawProps {
    open: boolean;
    hideCancelBtn?: boolean;
    content: string;
    titleTxt?: string;
    confirmTextBtn?: string;
    cancelTextBtn?: string;
    onClose: (value: string) => void;
    onBackdropClick?: (value: string) => void;
    showCheckmark?: boolean;
    highlightApprove?: boolean;
    iconColor?: string;
}

export const CANCEL_CONFIRMATION = "CANCEL";

export function ConfirmationDialog(props: ConfirmationDialogRawProps) {
    const { onClose, content, open, onBackdropClick, highlightApprove, iconColor, ...other } = props;
    let { titleTxt, confirmTextBtn, cancelTextBtn } = props;

    if(titleTxt === undefined)
        titleTxt = 'Confirmation';
    if(confirmTextBtn === undefined)
        confirmTextBtn = 'Ok';
    if(cancelTextBtn === undefined)
        cancelTextBtn = 'Cancel';

    const handleCancel = () => {
        onClose(CANCEL_CONFIRMATION);
    };

    const handleOk = () => {
        onClose('OK');
    };

    return (
        <Dialog
            sx={{"& .MuiDialog-paper": {width: "80%",
                                        maxHeight: '435px',
                                        minHeight: '232px',
                                        minWidth: '820px',
                                        paddingTop: '12px',
                                        paddingBottom: '12px',
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        borderLeft:'7px solid #02BBE3'
                                        }
            }}
            maxWidth="xs"
            open={open}
            onClose={onBackdropClick}
            {...other}
        >
            <div className="dialog-title">{titleTxt}</div>
            <div className="dialog-content">
                {
                    props?.showCheckmark && props?.showCheckmark === true ?
                    <FaCircleCheck className="dialog-content-icon"></FaCircleCheck>
                    :
                    <FaCircleExclamation style={{color: iconColor}} className="dialog-content-icon"></FaCircleExclamation>
                }
                <p className="dialog-content-text">{content}</p>
            </div>
            <div className="dialog-action-btns">
                <div className={`cancel-btn ${highlightApprove ? "non-highlight" : "cta-highlight"}`}  autoFocus
                     style={{display: `${props.hideCancelBtn === true? 'none': 'flex'}`}}
                     onClick={handleCancel}>{cancelTextBtn}</div>
                <div className={`confirmation-btn ${highlightApprove ? "cta-highlight" : "non-highlight"}`}  onClick={handleOk}>{confirmTextBtn}</div>
            </div>
        </Dialog>
    );
}