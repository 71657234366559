import CircularProgress from '@mui/joy/CircularProgress';

export default function NewTotalSolvedAlerts(props: {payslips: any}) {
    const items = props.payslips?.filter((x:any) => x?.severity === 1)?.map((x:any) => x?.findings_summery).flat();
    const open = items?.reduce((accumulator: number ,item: any) => accumulator += item?.non_approved_quantity, 0);
    const closed = items?.reduce((accumulator: number ,item: any) => accumulator += item?.approved_quantity, 0);
    const total = closed + open;
    const percentage: number =  (100 * closed) / total;

    const color = "#1ACEA3";

    return (
        <div className="new-totalSolvedAlerts">
        <CircularProgress
            sx={{
            "--CircularProgress-size": "200px",
            "--CircularProgress-progressColor": color,
            "--CircularProgress-trackColor": "#DEEAEC",
            }}
            determinate
            variant="soft"
            value={percentage}
        />
        <div className="in-gauge">
            <div>
            <span className="in-gauge-header-first-part">{closed}</span>
            &nbsp;/&nbsp;
            <span className="in-gauge-header-second-part">{total}</span>
            </div>
            <div className='in-gauge-sub-title'>Resolved Alerts</div>
        </div>
        </div>
    );
}