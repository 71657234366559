export default function getCommentsSectionIconPath(iconNumber: number){
    switch(iconNumber){
        case 0:
            return '/Danger-Circle.svg';
        case 1:
            return '/Check Circle.svg';
        case 3:
            return '/Close-Circle.svg'; 
        case 4:
            return '/email-sent.svg';
        default:
            return '/side-comment.svg';    
    }
}