import axios from "axios";
import mixpanel from 'mixpanel-browser';

export function login(user, password, setLoggedIn, setShowErrorLogin) {
    axios.post('/api/auth/jwt/login',
        `username=${encodeURIComponent(user)}&password=${encodeURIComponent(password)}`,
        {'Content-Type': 'application/x-www-form-urlencoded'}).then((res) => {
        if (res.status === 200 || res.status === 201) {
            localStorage.setItem('celeryBearer', 'Bearer ' + res.data['access_token']);
            localStorage.removeItem('celeryCycle');
            mixpanel.identify(user)
            mixpanel.track('Login')
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
            setShowErrorLogin(true);
        }
    }).catch((err) => {
        console.log(err)
        setLoggedIn(false);
    })
}

export function logout() {
    localStorage.removeItem('celeryBearer');
    localStorage.removeItem('is_superuser');
    localStorage.removeItem('user_role')
}

export function registerAuth(auth_failed_callback) {
    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem('celeryBearer')
        config.headers.Authorization =  token;

        return config;
    });

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error?.response?.status === 401) {
            auth_failed_callback()
        }
        return error;
    });
}