import './Patients.css';
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {DataGrid, GridSortItem} from "@mui/x-data-grid";
import {formatNumber} from "../../../utils/PresentationUtils";
import {
    PatientProfitabilityOverview,
    PatientProfitabilityOverviewData
} from "../PPOPage/PPOOverview";
import {
    PatientProfitabilitySummary
} from "./PatientProfitabilitySummary";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { setCycle, setEntityID, setFilterModel, setSortModel,
        setOverview, setPatients, setPatientKPIFilterKey} from "../../../store/PatientSlice";
import { TbUserHeart } from "react-icons/tb";
import useCustomParams from "../../../hooks/useCustomParams";


function PPOPage (props: any){
    const latestController = useRef<AbortController | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cachedFilterModel: any = useSelector((state: any) => state?.PatientsViewReducer?.filterModel);
    const cachedSortModel: GridSortItem[] = useSelector((state: any) => state?.PatientsViewReducer?.sortModel);
    const cachedCycle: string = useSelector((state: any) => state?.PatientsViewReducer?.cycle);
    const cachedEntityID: number = useSelector((state: any) => state?.PatientsViewReducer?.entityID);
    const cachedOverviewData: PatientProfitabilityOverviewData = useSelector((state: any) => state?.PatientsViewReducer?.overview);
    const cachedPatients: PatientProfitabilitySummary[] = useSelector((state: any) => state?.PatientsViewReducer?.patients);
    const cachedKPIFilterKey = useSelector((state: any) => state?.PatientsViewReducer?.patientKPIFilterKey);

    const { entity, cycle } = useCustomParams();

    const [filterModel, setFilterModelGrid] = useState({ items: [] });
    const [sortModel, setSortModelGrid] = useState<GridSortItem[]>([
        { field: 'actual_profit', sort: 'asc' }
    ]);
    const [kpiFilterKey, setKPIFilterKey] = useState("");
    const [patientsData, setPatientsData] = useState<PatientProfitabilitySummary[]>([]);
    const [profitablePatientsOverview, setProfitablePatientsOverview] = useState(new PatientProfitabilityOverview());
    const [unprofitablePatientsOverview, setUnprofitablePatientsOverview] = useState(new PatientProfitabilityOverview());
    const [losePatientsOverview, setLosePatientsOverview] = useState(new PatientProfitabilityOverview());

    const PROFIT_KPI_FILTER: string = "All";
    const PROFITABLE_KPI_FILTER: string = "Profitable";
    const LOW_PROFITABILITY_KPI_FILTER: string = "LowProfitability";
    const LOSS_KPI_FILTER: string = "Loss";

    useEffect(() => {
        // Abort the previous request if there's any
        if (latestController.current) {
            latestController.current.abort();
        }

        // Create a new controller for the current request
        const controller = new AbortController();
        latestController.current = controller;

        initializePageStatus();

        return () => {
            // Clean up the controller on component unmount or date change
            controller.abort();
        };
    }, [entity, cycle]);

    const initializePageStatus = () => {
        loadPatientsData();
    };

    const loadPatientsData = () => {
        if (cachedPatients.length === 0 ||
            cycle !== cachedCycle ||
            entity !== cachedEntityID.toString())
        {
            loadPPO().then((res: any) => {
                dispatch(setPatients(res));
                dispatch(setCycle(cycle));
                dispatch(setEntityID(parseInt(entity)));

                if(cachedKPIFilterKey === ""){
                    setPatientsData(res);
                    return;
                }

                filterPatients(cachedKPIFilterKey, res);
                setKPIFilterKey(cachedKPIFilterKey);
            }).catch((e) => {
            });
        }
        else {
            loadCachedData();
        }

        if(cachedOverviewData.total_patients > 0)
        {
            setOverviewData(cachedOverviewData);
        }
        else {
            loadOverviewData().then((res) => {
                setOverviewData(res);
                dispatch(setOverview(res));
            }).catch((e) => {
                setOverviewData();
            });
        }
    };

    const loadCachedData = () => {
        if(cachedKPIFilterKey !== "")
        {
            filterPatients(cachedKPIFilterKey);
            setKPIFilterKey(cachedKPIFilterKey);
        }
        else
        {
            setPatientsData(cachedPatients);
        }

        setFilterModelGrid(cachedFilterModel);
        setSortModelGrid(cachedSortModel);
    };

    const loadPPO = async () => {
        const response = await axios.get(`/api/ppo/patients/${entity}/${cycle}`);
        return await response?.data;
    };

    const loadOverviewData = async () => {
        const response = await axios.get(`/api/ppo/${entity}/${cycle}/overview`);
        return await response?.data;
    };

    const setOverviewData = (data: any = null) => {
        if (data == null)
            return

        loadProfitablePatientsOverview(data);
        loadUnprofitablePatientsOverview(data);
        loadLosePatientsOverview(data);
    };

    const loadProfitablePatientsOverview = (data: any) => {
        const profitableOverview = new PatientProfitabilityOverview()

        profitableOverview.profit = data.profitability;
        profitableOverview.total_patients = data.patients_profitability_count;
        profitableOverview.profit_per_patient = data.patients_profitability_avg;

        setProfitablePatientsOverview(profitableOverview)
    };

    const loadUnprofitablePatientsOverview = (data: any) => {
        const unprofitableOverview = new PatientProfitabilityOverview()

        unprofitableOverview.profit = data.low_profitability;
        unprofitableOverview.total_patients = data.patients_low_profitability_count;
        unprofitableOverview.profit_per_patient = data.patients_low_profitability_avg;

        setUnprofitablePatientsOverview(unprofitableOverview)
    };

    const loadLosePatientsOverview = (data: any) => {
        const loseOverview = new PatientProfitabilityOverview()

        loseOverview.profit = data.loss;
        loseOverview.total_patients = data.patients_loss_count;
        loseOverview.profit_per_patient = data.patients_loss_avg;

        setLosePatientsOverview(loseOverview)
    };

    function renderNumberCell(params: any) {
        return renderNumber(params);
    }

    function renderNumberPercentageCell(params: any) {
        return renderNumber(params, true);
    }

    function renderNumber(params: any, include_percentage: boolean = false) {
        const is_loss_profitability = params?.row?.is_loss_profitability
        const is_low_profitability = params?.row?.is_low_profitability
        const value = params?.value

        let colorClass = 'PPOPageTableCellGreen'

        if (is_loss_profitability) {
            colorClass = 'PPOPageTableCellRed'
        } else if (is_low_profitability) {
            colorClass = 'PPOPageTableCellOrange'
        }

        let s_value = Math.round(value * 100) / 100;
        let fixedNumber = parseFloat(s_value.toFixed(2));
        if (s_value < 0)
        {
            fixedNumber = Math.abs(fixedNumber);
            return <div className={colorClass}>({fixedNumber.toLocaleString()}{include_percentage ? '%': '' })</div>
        }
        return <div className={colorClass}> {fixedNumber.toLocaleString()}{include_percentage ? '%': '' }</div>
    }

    function renderHoursCell(value: any) {
        let s_value = Math.round(value * 100) / 100;
        let fixedNumber = parseFloat(s_value.toFixed(2));

        return <div>{fixedNumber.toLocaleString()}</div>
    }

    const handleRowClicked = (params: any, event: any) => {
        navigate(`/${entity}/${cycle}/patient/?id=${params.row['xid']}`);
    };

    const columns_definitions = [
        { field: 'name', headerName: 'Patient Name',  width: 150, hideable: false },
        { field: 'billing_hours', headerName: 'Billing',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.billing_hours), hideable: false },
        { field: 'actual_payroll_hours', headerName: 'Payroll',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.actual_payroll_hours), hideable: false },
        { field: 'billing_total_amount', headerName: 'Billing ($)',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.billing_total_amount), hideable: false },
        { field: 'actual_payroll_total_amount', headerName: 'Payroll ($)',  width: 100, renderCell: (params: any) => renderHoursCell(params?.row.actual_payroll_total_amount), hideable: false },
        { field: 'actual_profit', headerName: 'Act. ($)',  width: 100 , renderCell: renderNumberCell, hideable: false },
        { field: 'profit_percentage', headerName: 'Act. (%)',  width: 100 , renderCell: renderNumberPercentageCell, hideable: false },
        { field: 'estimation_profit', headerName: 'Est. ($)',  width: 100, renderCell: renderNumberCell, hideable: false },
        { field: 'estimation_percentage', headerName: 'Est. (%)',  width: 100 , renderCell: renderNumberPercentageCell, hideable: false },
        { field: 'recommendations', headerName: 'Recommendations',  flex:1, hideable: false }
    ];

    const filterPatientsByKPI = (filterKey: string) => {
        if (filterKey ===  kpiFilterKey)
        {
            const string_empty = "";
            setKPIFilterKey(string_empty);
            dispatch(setPatientKPIFilterKey(string_empty));
            filterPatients(string_empty);
        }
        else {
            setKPIFilterKey(filterKey);
            dispatch(setPatientKPIFilterKey(filterKey));
            filterPatients(filterKey);
        }
    }

    const filterPatients = (filterKey: string, data: PatientProfitabilitySummary[] | null = null) => {
        switch (filterKey){
            case PROFIT_KPI_FILTER || "":
                filterAllPatients(data);
                break;
            case PROFITABLE_KPI_FILTER:
                filterProfitabilityPatients(data);
                break;
            case LOW_PROFITABILITY_KPI_FILTER:
                filterLowProfitabilityPatients(data);
                break;
            case LOSS_KPI_FILTER:
                filterLosPatients(data);
                break;
            default:
                filterAllPatients(data);
                break;
        }
    };

    const filterAllPatients = (data: PatientProfitabilitySummary[] | null = null) => {
        if(data !== null)
            setPatientsData(cachedPatients);
        else
            setPatientsData(cachedPatients);
    };

    const filterProfitabilityPatients = (data: PatientProfitabilitySummary[] | null = null) => {
        const newData: PatientProfitabilitySummary[] = [];
        const patients = data === null ? cachedPatients : data;

        for(let i = 0; i < patients.length; i++) {
            const patient: PatientProfitabilitySummary = patients[i];
            if (!patient.is_low_profitability && !patient.is_loss_profitability)
                newData.push(patient);
        }
        setPatientsData(newData);
    };

    const filterLowProfitabilityPatients = (data: PatientProfitabilitySummary[] | null = null) => {
        const newData: PatientProfitabilitySummary[] = [];
        const patients = data === null ? cachedPatients : data;

        for(let i = 0; i < patients.length; i++) {
            const patient: PatientProfitabilitySummary = patients[i];
            if (patient.is_low_profitability)
                newData.push(patient);
        }
        setPatientsData(newData);
    };

    const filterLosPatients = (data: PatientProfitabilitySummary[] | null = null) => {
        const newData: PatientProfitabilitySummary[] = [];
        const patients = data === null ? cachedPatients : data;

        for(let i = 0; i < patients.length; i++) {
            const patient: PatientProfitabilitySummary = patients[i];
            if (patient.is_loss_profitability)
                newData.push(patient);
        }
        setPatientsData(newData);
    };

    const handleSortModelChange = (newSortModel: GridSortItem[]) => {
        setSortModelGrid(newSortModel);
        dispatch(setSortModel(newSortModel));
    };

    const handleFilterModelChange = (newFilterModel: any) => {
        setFilterModelGrid(newFilterModel);
        dispatch(setFilterModel(newFilterModel));
    };

            return (
                <div className="PPOPageWrapper">
                    <div className="PPOPageTitle">
                        <TbUserHeart className="PatientsPageTitleIcon" />
                        <span className="PPOPageTitleTxt">Patients</span>
                    </div>
                    <div className="PPOPageHeaders">
                        <div className="PPOPageHeaderWrapper">
                            <div className="PPOPageHeaderTitle">
                                Profit Breakdown
                            </div>
                            <div className="PPOPageHeaderCards">
                                <div
                                    className={kpiFilterKey === LOSS_KPI_FILTER ? "PPOPageHeaderCard selected-kpi-as-filter" : "PPOPageHeaderCard"}
                                    onClick={() => filterPatientsByKPI(LOSS_KPI_FILTER)}>
                                    <div className="PPOPageHeaderCardTitle">Losing Patients</div>
                                    <div
                                        className="PPOPageHeaderCardBody PPOPageTableCellRed">${formatNumber(losePatientsOverview?.profit, false, true, false)}</div>
                                    <div className="PPOPageHeaderCardFooter">
                                        <div
                                            className="PPOPageHeaderCardFooterTotalPatients">{losePatientsOverview?.total_patients} Patients
                                        </div>
                                        <div className="PPOPageHeaderCardFooterAvg">
                                            {
                                                losePatientsOverview?.total_patients > 0 && (
                                                    "$" + formatNumber(losePatientsOverview?.profit_per_patient, false, true, false) + " per Patient"
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={kpiFilterKey === LOW_PROFITABILITY_KPI_FILTER ? "PPOPageHeaderCard selected-kpi-as-filter" : "PPOPageHeaderCard"}
                                    onClick={() => filterPatientsByKPI(LOW_PROFITABILITY_KPI_FILTER)}>
                                    <div className="PPOPageHeaderCardTitle">Low Profitability Patients</div>
                                    <div
                                        className="PPOPageHeaderCardBody PPOPageTableCellOrange">${formatNumber(unprofitablePatientsOverview?.profit, false, true, false)}</div>
                                    <div className="PPOPageHeaderCardFooter">
                                        <div
                                            className="PPOPageHeaderCardFooterTotalPatients">{unprofitablePatientsOverview?.total_patients} Patients
                                        </div>
                                        <div
                                            className="PPOPageHeaderCardFooterAvg">${formatNumber(unprofitablePatientsOverview?.profit_per_patient, false, true, false)} per
                                            Patient
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={kpiFilterKey === PROFITABLE_KPI_FILTER ? "PPOPageHeaderCard selected-kpi-as-filter" : "PPOPageHeaderCard"}
                                    onClick={() => filterPatientsByKPI(PROFITABLE_KPI_FILTER)}>
                                    <div className="PPOPageHeaderCardTitle">Profitable Patients</div>
                                    <div
                                        className="PPOPageHeaderCardBody PPOPageTableCellGreen">${formatNumber(profitablePatientsOverview?.profit, false, true, false)}</div>
                                    <div className="PPOPageHeaderCardFooter">
                                        <div
                                            className="PPOPageHeaderCardFooterTotalPatients">{profitablePatientsOverview?.total_patients} Patients
                                        </div>
                                        <div
                                            className="PPOPageHeaderCardFooterAvg">${formatNumber(profitablePatientsOverview?.profit_per_patient, false, true, false)} per
                                            Patient
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="PPOPageTableArea">
                        <div className="PPOPageTableTitle">Patients Breakdown</div>
                        <div className="PPOPageTableCategories">
                            <div className="PPOPageTableCategory InfoHeader">
                                Information
                            </div>
                            <div className="PPOPageTableCategory BillingHeader">
                                Hours
                            </div>
                            <div className="PPOPageTableCategory PayrollHeader">
                                Amount
                            </div>
                            <div className="PPOPageTableCategory ProfitHeader">
                                Profit
                            </div>
                            <div className="PPOPageTableCategory ActionHeader">
                                Actions
                            </div>
                        </div>
                        <div className="PPOPageTableBody">
                            <DataGrid rows={patientsData}
                                      columns={columns_definitions}
                                      onRowClick={handleRowClicked}
                                      getRowId={(row: any) => row.xid}
                                      hideFooterSelectedRowCount={true}
                                      onSortModelChange={handleSortModelChange}
                                      sortModel={sortModel}
                                      onFilterModelChange={handleFilterModelChange}
                                      filterModel={filterModel}
                                      initialState={{
                                          pagination: {
                                              paginationModel: {
                                                  pageSize: 10,
                                              },
                                          },
                                      }}
                            />
                        </div>
                    </div>
                </div>
            )
}


export default PPOPage;
